import {
  Container,
  makeStyles,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Apiconfigs from "src/ApiConfig/ApiConfig";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  mainQustionAns: {
    paddingTop: "50px",
    "& h3": {
      fontSize: "25px",
      lineHeight: "1.2",
      marginBottom: "15px",
      color: "#494a52",
    },
  },
  heading: {
    fontSize: "18px",
    fontWeight: 400,
    color: "#333333",
    // paddingLeft:"5px!important"
  },
  para: {
    fontSize: "15px",
    fontWeight: 300,
    color: "#333",
    paddingLeft: "10px",
  },
  AccordionBox: {
    margin: "20px 0px",
    "& .cardAccordianCard": {
      border: "1px solid #e7e7e7",
      marginTop: "10px",
    },
  },
}));

const AnsQusetion = () => {
  const classes = useStyles();
  const [_faqlist, setFaqList] = useState();
  const FaqFunction = async () => {
    try {
      const res = await axios({
        url: Apiconfigs.faqList,
        method: "GET",
      });
      if (res) {
        setFaqList(res?.data?.result);
      }
    } catch (error) {}
  };
  useEffect(() => {
    FaqFunction();
  }, []);
  return (
    <div className={classes.mainQustionAns}>
      <Container maxWidth={false} style={{ maxWidth: "1160px" }}>
        <Typography variant="h2">
          Frequently asked questions about buying marijuana products
        </Typography>
        <Box className={classes.AccordionBox}>
          {_faqlist &&
            _faqlist.map((data) => (
              <Box className={classes.AccordionBox}>
                <Accordion className="cardAccordianCard">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ paddingLeft: "10px" }}
                  >
                    <Typography className={classes.heading}>
                      {data?.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className={classes.para}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data?.answer,
                        }}
                      ></div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            ))}
        </Box>
      </Container>
    </div>
  );
};

export default AnsQusetion;
