import { Container, makeStyles, Typography } from "@material-ui/core";
import React from "react";
const useStyles = makeStyles((theme) => ({
  mainStrainSpotlight: {
    padding: "38px 66px 33px",
    [theme.breakpoints.down("sm")]: {
      padding: "initial",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "initial",
    },
    "& h3": {
      marginBottom: "10px",
    },
  },
}));

const StrainSpotlight = () => {
  const classes = useStyles();
  return (
    <div className={classes.mainStrainSpotlight}>
      <Container maxWidth={false} style={{ maxWidth: "1160px" }}>
        <Typography variant="h3">Strain spotlight</Typography>
        <iframe
          width="100%"
          height="550px"
          src="https://www.youtube.com/embed/Q05r6ts9m9s"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          pointer-events="all"
        ></iframe>
      </Container>
    </div>
  );
};

export default StrainSpotlight;
