import { makeStyles, Box, Typography, Grid, Paper } from "@material-ui/core";
import React from "react";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import LinearProgress from "@material-ui/core/LinearProgress";
import { TbBulb } from "react-icons/tb";

const useStyles = makeStyles((theme) => ({
  paper: {},
  SubBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    maxWidth: "360px",
  },
  mainIconBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& span": {
      paddingLeft: "10px",
    },
    "& .icon": {
      transform: "rotate(45deg)",
    },
    "& .iconLast": {
      background: "#00000 !important",
      padding: "6px 3px",
      borderRadius: "25px",
      width: "10px",
      Height: "10px",
    },
  },
  progressBarBox: {
    marginTop: "12px",
    "& .ProgressText": {
      margin: "10px 0px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& h6": {
        fontSize: "12px",
        fontWeight: 500,
        color: "#333333",
      },
    },
  },
  paper: {
    display: "flex",
    boxShadow: "0 4px 8px rgb(0 0 0 / 16%)",
    marginTop: "30px",
    padding: "12px",
    alignItems: "center",
    "& .leftpart": {
      width: "20px",
      Height: "20px",
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },
  },
  righpart: {
    paddingLeft: "16px",
    "& h6": {
      fontSize: "12px",
      fontWeight: 600,
      color: "#333",
    },
    "& p": {
      fontWeight: 700,
    },
  },
  textBoxDiscription: {
    marginTop: "20px",
    "& span": {
      color: "#333333",
      fontWeight: 700,
    },
    "& h6": {
      fontWeight: 400,
      fontSize: "15px",
    },
  },
  colorCode: {
    width: "10px",
    height: "20px",
    borderRadius: "10px",
  },
  imageSize: {
    width: "25px",
  },
}));

const BlueDreamRightPart = ({ _viewstrains }) => {
  const classes = useStyles();
  return (
    <div className={classes.BlueDreamRightPart}>
      <Box className={classes.SubBox}>
        <Box className={classes.mainIconBox}>
          <CheckBoxOutlineBlankIcon className="icon" />
          <span>THC {_viewstrains?.thc}%</span>
        </Box>
        <Box className={classes.mainIconBox}>
          <RadioButtonUncheckedIcon />
          <span>CBD {_viewstrains?.cbc}%</span>
        </Box>
        <Box className={classes.mainIconBox}>
          <Box
            className={classes.colorCode}
            style={{
              background: `${_viewstrains?.strainTopTerp?.icon}`,
            }}
          ></Box>
          <span> {_viewstrains?.strainTopTerp?.name}</span>
        </Box>
      </Box>
      <Box className={classes.progressBarBox}>
        <Box className="ProgressText">
          <Typography variant="h6">calming</Typography>
          <Typography variant="h6">energizing</Typography>
        </Box>
        <LinearProgress variant="determinate" value={_viewstrains?.cbc} />

        <Box className="ProgressText">
          <Typography variant="h6">low THC</Typography>
          <Typography variant="h6">high THC</Typography>
        </Box>
        <LinearProgress variant="determinate" value={_viewstrains?.thc} />
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Paper className={classes.paper}>
            <Box className="leftpart">
              <img
                className={classes.imageSize}
                src={_viewstrains?.topFlavor?.icon}
              ></img>
              {/* <TbBulb style={{ fontSize: "25px" }} /> */}
            </Box>

            <Box className={classes.righpart}>
              <Typography variant="h6">flavor & aroma</Typography>
              <Typography variant="body1">
                {_viewstrains?.topFlavor?.name}
              </Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Paper className={classes.paper}>
            <Box className="iconPart">
              <img
                className={classes.imageSize}
                src={_viewstrains?.topEffect?.icon}
              ></img>
            </Box>

            <Box className={classes.righpart}>
              <Typography variant="h6">top effect</Typography>
              <Typography variant="body1">
                {_viewstrains?.topEffect?.name}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Box className={classes.textBoxDiscription}>
        <Typography variant="h6">{_viewstrains?.description}</Typography>
      </Box>
    </div>
  );
};

export default BlueDreamRightPart;
