import {
  Container,
  Grid,
  makeStyles,
  Box,
  Typography,
  FormControl,
  OutlinedInput,
} from "@material-ui/core";
import React, { useState } from "react";

import FormSlider from "./FormSlider";
import LetsTalkForm from "./LetsTalkForm";

const useStyles = makeStyles((theme) => ({
  LetsTalkForm: {
    [theme.breakpoints.down("md")]: {
      paddingTop: "0px",
    },
  },
  mainGridBox: {
    // "& h3": {
    //   fontSize: "35px",
    //   lineHeight: "1.2",
    //   marginBottom: "15px",
    //   color: "#494a52"
    // },
  },
  rightImgForm: {
    "& img": {
      width: "100%",
      Height: "400px",
    },
  },
}));

const LetsTalk = () => {
  const classes = useStyles();
  const [getValues, setValues] = useState("");
  const handleChange = (e) => {
    setValues(e.target.value);
  };

  return (
    <div className={classes.LetsTalkForm}>
      <Container maxWidth={false} style={{ maxWidth: "1160px" }}>
        <Box className={classes.mainGridBox}>
          <Grid container spacing={3}>
            <Grid item sx={12} sm={8} md={8} lg={8}>
              <LetsTalkForm />
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4}>
              <FormSlider />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default LetsTalk;
