import {
  Container,
  makeStyles,
  Typography,
  Box,
  Grid,
  Card,
  Divider,
} from "@material-ui/core";
import React from "react";
import { BiCommentDetail } from "react-icons/bi";
import { RiErrorWarningLine } from "react-icons/ri";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import SentimentSatisfiedOutlinedIcon from "@material-ui/icons/SentimentSatisfiedOutlined";
import StrainEffect from "./StrainEffect";
const useStyles = makeStyles((theme) => ({
  MACsensations: {
    marginTop: "30px",
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "50px",
    },
  },
  twoSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .leftPArt": {
      display: "flex",
      alignItems: "center",
      marginTop: "20px",
      "& p": {
        paddingLeft: "10px",
        fontWeight: 600,
        color: "#333333",
      },
    },
  },
  mainSectionGrid: {
    marginTop: "25px",
    "& h3": {
      fontSize: "20px !important",
    },
    "& h4": {
      fontSize: "16px",
      fontWeight: 500,
      marginTop: "10px",
      color: "#333",
    },
  },

  mainRightPart: {
    paddingLeft: "45px",
    "& h3": {
      fontSize: "20px !important",
    },
  },
  TextContaint: {
    "& h4": {
      fontSize: "16px",
      fontWeight: 600,
      textDecoration: "underline",
      paddingBottom: "5px",
      color: "#333",
    },
    "& h6": {
      fontSize: "13px",
      fontWeight: 400,
      "& span": {
        color: "#333",
        fontWeight: 600,
      },
    },
  },
  BoxText: {
    border: "1px solid #e7e7e7",
    marginTop: "25px",
    padding: "14px",
    borderRadius: "5px",
    "& p": {
      lineHeight: "18px",
      color: "#707070",
      fontStyle: "italic",
      fontWeight: 400,
    },
  },
  borderLine: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  mainCard: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 4px 8px rgb(0 0 0 / 16%)",
    width: "100%",
    minHeight: "85px",
    maxHeight: "85px",
    padding: "15px 0px",
    "& img": {
      maxWidth: "55px",
    },
    "& h6": {
      fontSize: "16px",
      fontWeight: 600,
      color: "#333",
    },
  },
}));

const MACsensations = ({ _viewstrains }) => {
  const classes = useStyles();
  const EffectData = _viewstrains?.effects;
  const NagativeEffectData = _viewstrains?.effectsToAvoid;
  const Flavourdata = _viewstrains?.flavors;

  return (
    <div className={classes.MACsensations}>
      <Container maxWidth={false} style={{ maxWidth: "1160px" }}>
        <Typography variant="h3">
          {_viewstrains?.strainName} sensations
        </Typography>
        <Box className={classes.twoSection}>
          <Box className="leftPArt">
            <BiCommentDetail />
            <Typography variant="body1">
              Reported by 0 real people like you
            </Typography>
          </Box>
          <Box>
            <ErrorOutlineOutlinedIcon />
          </Box>
        </Box>

        <Box className={classes.mainSectionGrid}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Typography variant="h3">Strain effects</Typography>
              <Typography variant="h4">Feelings</Typography>
              <Grid container spacing={2}>
                {EffectData &&
                  EffectData.map((data) => {
                    return (
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <StrainEffect type={data} />
                      </Grid>
                    );
                  })}
              </Grid>
              <Typography variant="h4" style={{ marginTop: "20px" }}>
                Negatives
              </Typography>
              <Grid container spacing={2}>
                {NagativeEffectData &&
                  NagativeEffectData.map((data) => {
                    return (
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <div className={classes.StrainEffect}>
                          <Card className={classes.mainCard}>
                            <Box align="center">
                              <img src={data?.icon}></img>
                              <Typography variant="h6">{data?.name}</Typography>
                            </Box>
                          </Card>
                        </div>
                      </Grid>
                    );
                  })}
              </Grid>
              <Typography
                variant="h3"
                style={{ marginTop: "20px", fontSize: "20px" }}
              >
                Strain flavors
              </Typography>
              <Grid container spacing={2}>
                {Flavourdata &&
                  Flavourdata.map((data) => {
                    return (
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <div className={classes.StrainEffect}>
                          <Card className={classes.mainCard}>
                            <Box align="center">
                              <img src={data?.icon}></img>
                              <Typography variant="h6">{data?.name}</Typography>
                            </Box>
                          </Card>
                        </div>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Box
                className={classes.borderLine}
                style={{
                  borderLeft: "1px solid #e7e7e7",
                  marginLeft: "40px",
                  height: "100%",
                }}
              >
                <Box className={classes.mainRightPart}>
                  <Typography variant="h3">MAC helps with</Typography>
                  <Box className={classes.TextContaint}>
                    <Typography variant="h4">Stress</Typography>
                    <Typography variant="h6">
                      <span>21%</span> of people say it helps with stress
                    </Typography>
                  </Box>
                  <Box
                    className={classes.TextContaint}
                    style={{ marginTop: "30px" }}
                  >
                    <Typography variant="h4">Anxiety</Typography>
                    <Typography variant="h6">
                      <span>21%</span> of people say it helps with anxiety
                    </Typography>
                  </Box>
                  <Box
                    className={classes.TextContaint}
                    style={{ marginTop: "30px" }}
                  >
                    <Typography variant="h4">Depression</Typography>
                    <Typography variant="h6">
                      <span>21%</span> of people say it helps with depression
                    </Typography>
                  </Box>
                  <Box className={classes.BoxText}>
                    <Typography variant="body1">
                      This info is sourced from our readers and is not a
                      substitute for professional medical advice. Seek the
                      advice of a health professional before using cannabis for
                      a medical condition.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default MACsensations;
