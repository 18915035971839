import React from 'react'
import  Login from "./Login" 

const index = () => {
  return (
    <div>
        <Login/>
    </div>
  )
}

export default index