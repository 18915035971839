import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import StarIcon from "@material-ui/icons/Star";
import Button from "@material-ui/core/Button";
import AppleIcon from "@material-ui/icons/Apple";
import ShopIcon from "@material-ui/icons/Shop";
const useStyles = makeStyles((theme) => ({
  maininbox: {
    backgroundColor: "#fcf1db",
    marginTop: "20px",
    marginBottom: "20px",
    padding: "40px",
    borderRadius: "10px",
  },
  text: {
    "& h2": {
      marginTop: "30px",
      color: "#333",
      fontSize: "20px",
      fontWeight: "800",
    },
    "& h5": {
      marginTop: "20px",
      fontSize: " 16px",
      fontWeight: "400",
      lineHeight: "1.334",
      color: " #333",
    },
  },
  icon: {
    marginTop: "10px",
  },
  btntext: {
    fontSize: "25px",
    whiteSpace: "pre",
    lineHeight: "18px",
    fontWeight: "400",
  },
  targetBlankclass: {
    textDecoration: "none",
  },
  button: {
    padding: "4px 5px",
  },
  btnBox: {
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: "20px",
    marginRight: "50px",
    width: "100%",
    "&:hover": {
      cursor: "pointer",
    },
    "& .SecondImg": {
      width: "31%",
      marginLeft: "10px",
    },
    "& .FirstImg": {
      width: "27%",
    },
  },
}));

export default function FullWidthGrid() {
  const classes = useStyles();

  return (
    <Box>
      <Container maxWidth={false} style={{ maxWidth: "1160px" }}>
        <Box className={classes.maininbox}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={7}>
              <Box className={classes.text}>
                <Typography variant="h2">
                  Thousands of strains. In your pocket.
                </Typography>
                <Box className={classes.icon}>
                  <StarIcon /> <StarIcon /> <StarIcon /> <StarIcon />{" "}
                  <StarIcon />
                </Box>
                <Typography variant="body1">
                  Over 300,000 reviews on App Store and Google Play
                </Typography>
              </Box>
              <Box className={classes.btnBox}>
                <a href="https://www.apple.com/in/app-store/">
                  <img className="FirstImg" src="images/app-store.svg" />
                </a>
                <a href="https://play.google.com/store/games?utm_source=apac_med&utm_medium=hasem&utm_content=Oct0121&utm_campaign=Evergreen&pcampaignid=MKT-EDR-apac-in-1003227-med-hasem-py-Evergreen-Oct0121-Text_Search_BKWS-BKWS%7CONSEM_kwid_43700065205026376_creativeid_535350509654_device_c&gclid=CjwKCAiA9qKbBhAzEiwAS4yeDc_aHaHY_TektWpff_8tGoWou_who8sERMOiK_F-xrvFe6EHABMQ_xoCy0YQAvD_BwE&gclsrc=aw.ds">
                  <img className="SecondImg" src="images/google-play.svg" />
                </a>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <Box>
                <img src="images/phone.avif" width="100%" />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
