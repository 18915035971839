import React from "react";
import Everything from "./Everything";
import LetsTalk from "./LetsTalk";
import AnsQusetion from "./AnsQusetion";

const Kanabix = () => {
  return (
    <div>
      <Everything />
      <LetsTalk />
      <AnsQusetion />
    </div>
  );
};

export default Kanabix;
