import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import { Box, makeStyles } from "@material-ui/core";
import axios from "axios";
import Apiconfigs from "src/ApiConfig/ApiConfig";
import { useHistory, useLocation } from "react-router-dom";
import { CiShoppingTag } from "react-icons/ci";
import { GiAbstract023 } from "react-icons/gi";
import { GiLotus } from "react-icons/gi";
import { HiLocationMarker } from "react-icons/hi";

const useStyles = makeStyles((theme) => ({
  mainSearchiconBox: {
    display: "flex",
    alignItems: "center",
  },

  titleSubtilte: {
    flexDirection: "column",
    paddingLeft: "15px",
    "& span": {
      fontSize: "18px",
      color: "#017c6b",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
  },
  SearchBox: {
    position: "relative",
  },
  SearchIcon: {
    color: "#656565",
    position: "absolute",
    right: "9px",
    top: "8px",
    background: "rgba(255, 255, 255, 0.4)",
  },
}));

const filter = createFilterOptions();

export default function FreeSoloCreateOption() {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [seachData, setSearchData] = useState();
  const history = useHistory();
  const [searchValue, setSearchValue] = useState();

  const globalSearch = async () => {
    try {
      const res = await axios({
        method: "POST",
        url: Apiconfigs.globalSearch,
        data: {
          search: searchValue,
        },
      });

      if (res) {
        // window.location.reload();
        setSearchData(res?.data?.result);
        history.push({
          pathname: "/search",
          state: {
            data: res?.data?.result,
            ValuesSearch: searchValue,
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          setValue({
            title: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          setValue({
            title: newValue.inputValue,
          });
        } else {
          setValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (params.inputValue !== "") {
          filtered.push({
            inputValue: params.inputValue,
            title: `Add "${params.inputValue}"`,
          });
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={top100Films}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }

        if (option.inputValue) {
          return option.inputValue;
        }

        return option.title;
      }}
      renderOption={(option, pathname) => (
        <React.Fragment>
          <Box
            onClick={() => history.push(`${option.pathname}`)}
            className={classes.mainSearchiconBox}
            style={{ width: "100%" }}
          >
            {option.icon} &nbsp;&nbsp;
            <Box className={classes.titleSubtilte}>
              <span>{option.title}</span>
              <br />
              {option.subtitle}
            </Box>
          </Box>
        </React.Fragment>
      )}
      // style={{ fontSize: "30px" }}
      freeSolo
      renderInput={(params) => (
        <>
          <Box className={classes.SearchBox}>
            <TextField
              {...params}
              placeholder="Stores, strains, product....."
              variant="outlined"
              onChange={(e) => setSearchValue(e.target.value)}
              fullWidth
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  globalSearch();
                }
              }}
            />
            {searchValue === undefined && value === null && (
              <SearchIcon className={classes.SearchIcon} />
            )}
          </Box>
        </>
      )}
    />
  );
}

const top100Films = [
  {
    icon: <HiLocationMarker style={{ color: "#017c6b", fontSize: "22px" }} />,
    title: "Dispensaries map",
    subtitle: "Shop strains and products near you",
    pathname: "/despansaries",
  },
  {
    icon: <CiShoppingTag style={{ color: "#017c6b", fontSize: "22px" }} />,
    title: "Weed deals",
    subtitle: "Find store nearby",
    pathname: "/deals",
  },
  {
    icon: <GiAbstract023 style={{ color: "#017c6b", fontSize: "22px" }} />,
    title: "Strains, strains, strains,",
    subtitle: "Kanabix world-class database",
    pathname: "/strains",
  },
  // {
  //     icon: <GiTrophyCup style={{ color: "#017c6b", fontSize: "22px" }} />,
  //     title: 'Kanabix Strain of the Year',
  //     subtitle: "The 2021 crown goes to..."
  // },
  {
    icon: <GiLotus style={{ color: "#017c6b", fontSize: "20px" }} />,
    title: "Grow guide",
    subtitle: "A hub for home growers",
    pathname: "/news",
  },
  // {
  //     icon: <GiAbstract076 style={{ color: "#017c6b", fontSize: "20px" }} />,
  //     title: 'Strains for pain relief',
  //     subtitle: "Plus other feelings & effects"
  // },
];
