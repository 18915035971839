import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import Apiconfigs from "src/ApiConfig/ApiConfig";
import { FaWindowRestore } from "react-icons/fa";
import { calculateTimeLeft } from "src/utils/index";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    window.sessionStorage.setItem("AccessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    window.sessionStorage.removeItem("AccessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.sessionStorage.getItem("AccessToken");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  // const [isLogin, setIsLogin] = useState(false);
  const [endTime, setEndtime] = useState();
  const [timeLeft, setTimeLeft] = useState();
  const [isLoading, setIsLoading] = useState(true);
  // const [userData] = useState({});
  const [userData, setUserData] = useState();
  const token = window.sessionStorage.getItem("AccessToken");
  let data = {
    userLoggedIn: isLogin,
    userData,
    timeLeft,
    setTimeLeft,
    setEndtime,

    getProfileHandler: (token) => getProfileHandler(token),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };
  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime * 1000));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });
  const getProfileHandler = async (token) => {
    try {
      const res = await axios.get(Apiconfigs.myProfile, {
        headers: {
          token: token,
        },
      });
      setIsLoading(false);
      if (res.data.responseCode === 200) {
        setUserData(res.data.result);
        setIsLogin(true);
      } else {
        setIsLogin(false);
      }
    } catch (error) {
      setIsLogin(false);
      setIsLoading(false);
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("AccessToken")) {
      getProfileHandler(window.sessionStorage.getItem("AccessToken"));
    } else {
      setIsLogin(false);
      setUserData();
    }
  }, [token]);

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
