import { makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import TopBarAdvert from "./TopBarAdvert";
import Footer from "./Footer";
import Kanabix from "src/component/kanabixBit/Kanabix";
import { Link as RouterLink, useLocation, useHistory } from "react-router-dom";

import { AuthContext } from "src/context/Auth";

const useStyles = makeStyles((theme) => ({}));

const Index = () => {
  const auth = useContext(AuthContext);
  const history = useHistory();

  const classes = useStyles();
  return (
    <div className={classes.layoutSecWeb}>
      {/* {auth?.userData?.userType === "CUSTOMER" ? history.push("/") : " "} */}
      <TopBarAdvert />
      <Kanabix />
      <Footer />
    </div>
  );
};

export default Index;
