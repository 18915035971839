import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Grid,
  Container,
  Divider,
  CircularProgress,
} from "@material-ui/core";

import StarIcon from "@material-ui/icons/Star";
import { Link as RouterLink } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  mainsection: {
    "& h3": {
      margin: "30px 0px",
      // paddingLeft: "20px",
    },
  },
  text: {
    display: "flex",
    "& p": {
      paddingLeft: "20px",
    },
  },

  textbox: {
    // padding: "19px 0px 70px 0px",
    // margin: "32px 4px 0px 4px",
    // marginTop: "30px",
    marginBottom: "30px",
    borderRadius: "5px",
    height: "260px",
    boxShadow: "0 4px 8px rgb(0 0 0 / 16%)",
    // marginLeft: "20px",
    transition: "0.5s ease-in-out",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0px",
    },
    "& img": {
      borderRadius: "10px",
      width: "100%",
      height: "100%",
    },
    "&:hover": {
      transform: "scale(1.05)",
      cursor: "pointer",
    },
    "& h5": {
      paddingLeft: "20px",
      color: "#000",
    },
    "& h6": {
      paddingLeft: "20px",
      color: "#000",
    },
  },
  divider: {
    paddingTop: "40px",
  },
}));

export default function CenteredGrid({ _viewstrains }) {
  // const { strainImage } = _viewstrains;
  const ViewImage = _viewstrains?.strainImage;
  const classes = useStyles();

  return (
    <Box className={classes.mainsection}>
      {/* {ViewImage !== undefined || ViewImage?.length !== 0 ? ( */}
      <>
        <Divider />
        <Container maxWidth={false} style={{ maxWidth: "1160px" }}>
          <Typography variant="h3">
            Photos of {_viewstrains?.strainName}
          </Typography>
          {_viewstrains === undefined ? (
            <CircularProgress
              style={{ color: "rgb(65, 102, 84)" }}
              disableShrink
            />
          ) : (
            ""
          )}
          <Grid container spacing={2}>
            {ViewImage &&
              ViewImage.map((data) => {
                return (
                  <>
                    <Grid item xs={12} sm={4} md={3}>
                      <Box className={classes.textbox}>
                        <img src={data} alt="imgDeals" width="100%" />
                      </Box>
                    </Grid>
                  </>
                );
              })}
          </Grid>
        </Container>
        <Box className={classes.divider}>
          <Divider />
        </Box>
      </>
      {/* ) :""} */}
    </Box>
  );
}
