import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  makeStyles,
  Box,
  Button,
  Link,
  IconButton,
  ListItem,
  List,
  ListItemText,
  Drawer,
  Avatar,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import Hidden from "@material-ui/core/Hidden";
import { AuthContext } from "src/context/Auth";
import Apiconfigs from "src/ApiConfig/ApiConfig";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Link as RouterLink } from "react-router-dom";
import ButtonCircular from "src/component/ButtonCircular.js";
import CloseIcon from "@material-ui/icons/Close";
import TopDropdownMenu from "src/layouts/KanabixLayout/TopDropdownMenu.js";
import Typography from "src/theme/typography";

const useStyles = makeStyles((theme) => ({
  containerClass: {
    display: "flex",
    justifyContent: "center",
  },
  MainBox: {
    boxShadow: "rgb(0 0 0 / 16%) 0px 0px 8px",
    marginBottom: "5px",
  },
  Box1: {
    width: "100%",
    padding: "15px",
  },
  logoBox: {
    "& img": {
      height: "83%",
      width: "100px",
    },
  },
  logoBox1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& img": {
      height: "83%",
      width: "100px",
    },
  },

  topbarBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  topbarBox1: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    // alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  LoginButton: {
    background: "rgba(0,125,108,1.0)",
    color: "white",
    padding: "7px 25px ",
    "&:hover": {
      color: "#fff",
      background: "rgba(0,125,108,1.0)",
      textDecoration: "none",
    },
  },
  LinkClass: {
    color: "#017c6b",
    fontWeight: "bold",
    fontSize: "14px",
    textDecoration: "none",
  },
  coponentBox: {
    padding: "0 15px",
  },
  EverythingClass: {
    background: "#f6f6f6",
  },
  linkbtn: {
    color: "#04AA6D",
    textDecoration: "none",
    fontSize: "17px",
    fontWeight: 600,
    fontFamily: "'Poppins', sans-serif",
    "&:hover": {
      cursor: "pointer",
      color: "#04AA6D",
    },

    "& Button": {
      "&:hover": {
        cursor: "pointer",
        color: "#04AA6D",
      },
    },
  },
  drawerList: {
    marginTop: "50px",
  },
  logoutBtn: {
    marginTop: "20px",
  },
}));

const Index = () => {
  const classes = useStyles();
  const history = useHistory();
  const auth = useContext(AuthContext);

  const [Active, setActive] = useState("Products");
  const [ActiveMenu, setActiveMenu] = useState("home");
  const [checkUsr, setUsr] = useState("");
  const [isloading, setLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  // useEffect(() => {
  //   if (auth.userData) {
  //     setUsr(auth?.userData?.userType);
  //   }
  // }, [auth]);
  const LogOut = async () => {
    try {
      setLoading(true);

      const res = await axios({
        url: Apiconfigs.logout,
        method: "POST",
        headers: {
          token: window.sessionStorage.getItem("AccessToken"),
        },
      });
      if (res?.data?.responseCode === 200) {
        setLoading(false);

        // toast.success(res?.data?.responseMessage);
        window.sessionStorage.removeItem("AccessToken");
        history.push("/businessLogin");
        window.location.reload();
      }
    } catch (error) {
      setLoading(false);

      console.log("error", error);
    }
  };
  return (
    <Box>
      <Box className={classes.MainBox}>
        <Container maxWidth={false} style={{ maxWidth: "1160px" }}>
          <Hidden mdUp>
            <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
              <MenuIcon />
            </IconButton>
          </Hidden>

          <Hidden smDown>
            <Box className={classes.containerClass}>
              <Box className={classes.Box1}>
                <Box className={classes.topbarBox}>
                  <Box className={classes.logoBox}>
                    <Link
                      onClick={() =>
                        auth?.userData === undefined ||
                        auth?.userData?.userType === "CUSTOMER"
                          ? history.push("/")
                          : history.push("/dashboard")
                      }
                      className={classes.linkbtn}
                      style={{ textDecoration: "none" }}
                    >
                      <img
                        src=".\images\logo_kanabix.png"
                        className={classes.logoImg}
                      />
                    </Link>
                  </Box>

                  {auth?.userLoggedIn === true && (
                    <>
                      {auth?.userData?.userType === "DOCTOR" && (
                        <>
                          <Box>
                            <NavLink
                              activeStyle={{
                                fontWeight: "bold",
                                color: "#088f7d",
                                textDecoration: "none",
                              }}
                              className={
                                Active === "dashboard" ? "hoverText" : " "
                              }
                              onClick={() => setActive("dashboard")}
                              to="/dashboard"
                              style={{
                                textDecoration: "none",
                                color: "#253d2f",
                                fontWeight: "500",
                              }}
                            >
                              <ListItem
                                className={classes.listitem}
                                disableGutters
                              >
                                Dashboard
                              </ListItem>
                            </NavLink>
                          </Box>
                          <Box>
                            <NavLink
                              activeStyle={{
                                fontWeight: "bold",
                                color: "#088f7d",
                                textDecoration: "none",
                              }}
                              className={
                                Active === "dispensaries" ? "hoverText" : " "
                              }
                              onClick={() => setActive("dispensaries")}
                              to="/doctor-list"
                              style={{
                                textDecoration: "none",
                                color: "#253d2f",
                                fontWeight: "500",
                              }}
                            >
                              <ListItem
                                className={classes.listitem}
                                disableGutters
                              >
                                Dispensaries
                              </ListItem>
                            </NavLink>
                          </Box>
                          <Box>
                            <NavLink
                              activeStyle={{
                                fontWeight: "bold",
                                color: "#088f7d",
                                textDecoration: "none",
                              }}
                              className={
                                Active === "clinic" ? "hoverText" : " "
                              }
                              onClick={() => setActive("dashboard")}
                              to="/clinic"
                              style={{
                                textDecoration: "none",
                                color: "#253d2f",
                                fontWeight: "500",
                              }}
                            >
                              <ListItem
                                className={classes.listitem}
                                disableGutters
                              >
                                Clinic
                              </ListItem>
                            </NavLink>
                          </Box>
                        </>
                      )}
                      {auth?.userData?.userType === "LABS" && (
                        <>
                          <Box>
                            <NavLink
                              activeStyle={{
                                fontWeight: "bold",
                                color: "#088f7d",
                                textDecoration: "none",
                              }}
                              className={
                                Active === "dashboard" ? "hoverText" : " "
                              }
                              onClick={() => setActive("dashboard")}
                              to="/dashboard"
                              style={{
                                textDecoration: "none",
                                color: "#253d2f",
                                fontWeight: "500",
                              }}
                            >
                              <ListItem
                                className={classes.listitem}
                                disableGutters
                              >
                                Dashboard
                              </ListItem>
                            </NavLink>
                          </Box>
                          <NavLink
                            activeStyle={{
                              fontWeight: "bold",
                              color: "#088f7d",
                              textDecoration: "none",
                            }}
                            className={Active === "CBD" ? "hoverText" : " "}
                            onClick={() => setActive("CBD")}
                            to="/cbd-stores"
                            style={{
                              textDecoration: "none",
                              color: "#253d2f",
                              fontWeight: "500",
                            }}
                          >
                            <ListItem
                              className={classes.listitem}
                              disableGutters
                            >
                              CBD Stores
                            </ListItem>
                          </NavLink>
                          <NavLink
                            activeStyle={{
                              fontWeight: "bold",
                              color: "#088f7d",
                              textDecoration: "none",
                            }}
                            className={Active === "CBD" ? "hoverText" : " "}
                            onClick={() => setActive("CBD")}
                            to="/cbs-store-management"
                            style={{
                              textDecoration: "none",
                              color: "#253d2f",
                              fontWeight: "500",
                            }}
                          >
                            <ListItem
                              className={classes.listitem}
                              disableGutters
                            >
                              Product Management
                            </ListItem>
                          </NavLink>
                        </>
                      )}
                      {auth?.userData?.userType === "MERCHANT" && (
                        <>
                          <Box>
                            <NavLink
                              activeStyle={{
                                fontWeight: "bold",
                                color: "#088f7d",
                                textDecoration: "none",
                              }}
                              className={
                                Active === "dashboard" ? "hoverText" : " "
                              }
                              onClick={() => setActive("dashboard")}
                              to="/dashboard"
                              style={{
                                textDecoration: "none",
                                color: "#253d2f",
                                fontWeight: "500",
                              }}
                            >
                              <ListItem
                                className={classes.listitem}
                                disableGutters
                              >
                                Dashboard
                              </ListItem>
                            </NavLink>
                          </Box>
                          <NavLink
                            activeStyle={{
                              fontWeight: "bold",
                              color: "#088f7d",
                              textDecoration: "none",
                            }}
                            className={
                              Active === "Products" ? "hoverText" : " "
                            }
                            onClick={() => setActive("Products")}
                            to="/list-product"
                            style={{
                              textDecoration: "none",
                              color: "#253d2f",
                              fontWeight: "500",
                            }}
                          >
                            <ListItem
                              className={classes.listitem}
                              disableGutters
                            >
                              Products Management
                            </ListItem>
                          </NavLink>

                          <NavLink
                            activeStyle={{
                              fontWeight: "bold",
                              color: "#088f7d",
                              textDecoration: "none",
                            }}
                            className={
                              Active === "Products" ? "hoverText" : " "
                            }
                            onClick={() => setActive("Products")}
                            to="/store-user"
                            style={{
                              textDecoration: "none",
                              color: "#253d2f",
                              fontWeight: "500",
                            }}
                          >
                            <ListItem
                              className={classes.listitem}
                              disableGutters
                            >
                              Store Management
                            </ListItem>
                          </NavLink>

                          <NavLink
                            activeStyle={{
                              fontWeight: "bold",
                              color: "#088f7d",
                              textDecoration: "none",
                            }}
                            className={Active === "Deals" ? "hoverText" : " "}
                            onClick={() => setActive("Deals")}
                            to="/list-all-deals"
                            style={{
                              textDecoration: "none",
                              color: "#253d2f",
                              fontWeight: "500",
                            }}
                          >
                            <ListItem
                              className={classes.listitem}
                              disableGutters
                            >
                              Deals Management
                            </ListItem>
                          </NavLink>
                          <Box>
                            <NavLink
                              activeStyle={{
                                fontWeight: "bold",
                                color: "#088f7d",
                                textDecoration: "none",
                              }}
                              className={
                                Active === "Orders" ? "hoverText" : " "
                              }
                              onClick={() => setActive("Orders")}
                              to="/order-management"
                              style={{
                                textDecoration: "none",
                                color: "#253d2f",
                                fontWeight: "500",
                              }}
                            >
                              <ListItem
                                className={classes.listitem}
                                disableGutters
                              >
                                Orders Management
                              </ListItem>
                            </NavLink>
                          </Box>
                        </>
                      )}
                    </>
                  )}
                  {auth?.userLoggedIn !== true && (
                    <>
                      <Box
                        className={classes.MenuMobileList}
                        style={{ display: "flex" }}
                      >
                        <NavLink
                          to="./home"
                          activeStyle={{
                            fontWeight: "bold",
                            color: "#088f7d",
                            textDecoration: "none",
                          }}
                          className={Active === "home" ? "hoverText" : " "}
                          style={{
                            textDecoration: "none",
                            color: "#253d2f",
                            fontWeight: "500",
                          }}
                        >
                          <ListItem className={classes.listitem} disableGutters>
                            Home
                          </ListItem>
                        </NavLink>{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Box className="dropdown">
                          <NavLink
                            to={{
                              pathname: "/retails",
                              state: {
                                userType: "MERCHANT",
                              },
                            }}
                            activeStyle={{
                              fontWeight: "bold",
                              color: "#088f7d",
                              textDecoration: "none",
                            }}
                            className={Active === "Retail" ? "hoverText" : " "}
                            style={{
                              textDecoration: "none",
                              color: "#253d2f",
                              fontWeight: "500",
                            }}
                          >
                            <ListItem
                              className={classes.listitem}
                              disableGutters
                            >
                              Retail
                            </ListItem>
                          </NavLink>
                        </Box>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink
                          to={{
                            pathname: "/doctors",
                            state: {
                              userType: "DOCTOR",
                            },
                          }}
                          activeStyle={{
                            fontWeight: "bold",
                            color: "#088f7d",
                            textDecoration: "none",
                          }}
                          className={Active === "Doctors" ? "hoverText" : " "}
                          style={{
                            textDecoration: "none",
                            color: "#253d2f",
                            fontWeight: "500",
                          }}
                        >
                          {/* <Button onClick={() => setActiveMenu("home")}>
                            Home
                          </Button> */}
                          <ListItem className={classes.listitem} disableGutters>
                            Doctors
                          </ListItem>
                        </NavLink>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <NavLink
                          to={{
                            pathname: "/labs",
                            state: {
                              userType: "LABS",
                            },
                          }}
                          activeStyle={{
                            fontWeight: "bold",
                            color: "#088f7d",
                            textDecoration: "none",
                          }}
                          className={Active === "Labs" ? "hoverText" : " "}
                          style={{
                            textDecoration: "none",
                            color: "#253d2f",
                            fontWeight: "500",
                          }}
                        >
                          {/* <Button onClick={() => setActiveMenu("home")}>
                            Home
                          </Button> */}
                          <ListItem className={classes.listitem} disableGutters>
                            Labs
                          </ListItem>
                        </NavLink>
                      </Box>
                    </>
                  )}
                  <Box style={{ display: "flex" }}>
                    {auth?.userLoggedIn === true &&
                    auth?.userData?.userType != "CUSTOMER" ? (
                      <>
                        {/* {auth?.userData?.email} &nbsp; &nbsp;{" "} */}
                        <Box
                          variant="contained"
                          // className={classes.LoginButton}

                          // onClick={LogOut}
                        >
                          <TopDropdownMenu LogOut={LogOut} />{" "}
                          {isloading ? <ButtonCircular /> : ""}
                        </Box>
                        &nbsp; &nbsp;
                      </>
                    ) : (
                      <Link
                        href="/businessLogin"
                        // target={"_blank"}
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          variant="contained"
                          className={classes.LoginButton}
                        >
                          login
                        </Button>
                      </Link>
                    )}
                    <Box></Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Hidden>
        </Container>
      </Box>

      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        style={{ maxWidth: "200px" }}
      >
        <List>
          <Box className={classes.containerClass}>
            <Box className={classes.Box1}>
              <Box className={classes.topbarBox1}>
                <Box className={classes.logoBox1}>
                  <Link
                    onClick={() => history.push("./home")}
                    className={classes.linkbtn}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      src=".\images\logo_kanabix.png"
                      className={classes.logoImg}
                    />
                  </Link>
                  <IconButton onClick={() => setOpenDrawer(false)}>
                    <CloseIcon />
                  </IconButton>
                </Box>

                <Box className={classes.drawerList}>
                  {auth?.userLoggedIn === true && (
                    <>
                      <Box>
                        <NavLink
                          activeStyle={{
                            fontWeight: "bold",
                            color: "#088f7d",
                            textDecoration: "none",
                          }}
                          className={Active === "dashboard" ? "hoverText" : " "}
                          onClick={() => setActive("dashboard")}
                          to="/dashboard"
                          style={{
                            textDecoration: "none",
                            color: "#253d2f",
                            fontWeight: "500",
                          }}
                        >
                          <ListItem className={classes.listitem} disableGutters>
                            Dashboard
                          </ListItem>
                        </NavLink>
                      </Box>

                      {auth?.userData?.userType === "DOCTOR" && (
                        <>
                          <Box>
                            <NavLink
                              activeStyle={{
                                fontWeight: "bold",
                                color: "#088f7d",
                                textDecoration: "none",
                              }}
                              className={
                                Active === "dispensaries" ? "hoverText" : " "
                              }
                              onClick={() => setActive("dispensaries")}
                              to="/doctor-list"
                              style={{
                                textDecoration: "none",
                                color: "#253d2f",
                                fontWeight: "500",
                              }}
                            >
                              <ListItem
                                className={classes.listitem}
                                disableGutters
                              >
                                Dispensaries
                              </ListItem>
                            </NavLink>
                          </Box>
                          <Box>
                            <NavLink
                              activeStyle={{
                                fontWeight: "bold",
                                color: "#088f7d",
                                textDecoration: "none",
                              }}
                              className={
                                Active === "clinic" ? "hoverText" : " "
                              }
                              onClick={() => setActive("dashboard")}
                              to="/clinic"
                              style={{
                                textDecoration: "none",
                                color: "#253d2f",
                                fontWeight: "500",
                              }}
                            >
                              <ListItem
                                className={classes.listitem}
                                disableGutters
                              >
                                Clinic
                              </ListItem>
                            </NavLink>
                          </Box>
                        </>
                      )}
                      {auth?.userData?.userType === "LABS" && (
                        <>
                          <NavLink
                            activeStyle={{
                              fontWeight: "bold",
                              color: "#088f7d",
                              textDecoration: "none",
                            }}
                            className={Active === "CBD" ? "hoverText" : " "}
                            onClick={() => setActive("CBD")}
                            to="/cbd-stores"
                            style={{
                              textDecoration: "none",
                              color: "#253d2f",
                              fontWeight: "500",
                            }}
                          >
                            <ListItem
                              className={classes.listitem}
                              disableGutters
                            >
                              CBD Stores
                            </ListItem>
                          </NavLink>
                        </>
                      )}
                      {auth?.userData?.userType === "MERCHANT" && (
                        <>
                          <NavLink
                            activeStyle={{
                              fontWeight: "bold",
                              color: "#088f7d",
                              textDecoration: "none",
                            }}
                            className={
                              Active === "Products" ? "hoverText" : " "
                            }
                            onClick={() => setActive("Products")}
                            to="/list-product"
                            style={{
                              textDecoration: "none",
                              color: "#253d2f",
                              fontWeight: "500",
                            }}
                          >
                            <ListItem
                              className={classes.listitem}
                              disableGutters
                            >
                              Products Management
                            </ListItem>
                          </NavLink>

                          <NavLink
                            activeStyle={{
                              fontWeight: "bold",
                              color: "#088f7d",
                              textDecoration: "none",
                            }}
                            className={
                              Active === "Products" ? "hoverText" : " "
                            }
                            onClick={() => setActive("Products")}
                            to="/store-user"
                            style={{
                              textDecoration: "none",
                              color: "#253d2f",
                              fontWeight: "500",
                            }}
                          >
                            <ListItem
                              className={classes.listitem}
                              disableGutters
                            >
                              Store Management
                            </ListItem>
                          </NavLink>

                          <NavLink
                            activeStyle={{
                              fontWeight: "bold",
                              color: "#088f7d",
                              textDecoration: "none",
                            }}
                            className={Active === "Deals" ? "hoverText" : " "}
                            onClick={() => setActive("Deals")}
                            to="/list-all-deals"
                            style={{
                              textDecoration: "none",
                              color: "#253d2f",
                              fontWeight: "500",
                            }}
                          >
                            <ListItem
                              className={classes.listitem}
                              disableGutters
                            >
                              Deals Management
                            </ListItem>
                          </NavLink>
                          <Box>
                            <NavLink
                              activeStyle={{
                                fontWeight: "bold",
                                color: "#088f7d",
                                textDecoration: "none",
                              }}
                              className={
                                Active === "Orders" ? "hoverText" : " "
                              }
                              onClick={() => setActive("Orders")}
                              to="/order-management"
                              style={{
                                textDecoration: "none",
                                color: "#253d2f",
                                fontWeight: "500",
                              }}
                            >
                              <ListItem
                                className={classes.listitem}
                                disableGutters
                              >
                                Orders Management
                              </ListItem>
                            </NavLink>
                          </Box>
                        </>
                      )}
                    </>
                  )}
                  {auth?.userLoggedIn !== true && (
                    <>
                      <Box className={classes.MenuMobileList}>
                        <ListItem>
                          <RouterLink
                            onClick={() => history.push("./home")}
                            className={classes.linkbtn}
                          >
                            Home
                          </RouterLink>
                        </ListItem>

                        <ListItem>
                          <RouterLink
                            to={{
                              pathname: "/retails",
                              state: {
                                userType: "MERCHANT",
                              },
                            }}
                            className={classes.linkbtn}
                          >
                            Retail
                          </RouterLink>
                        </ListItem>

                        <ListItem>
                          <RouterLink
                            to={{
                              pathname: "/doctors",
                              state: {
                                userType: "DOCTOR",
                              },
                            }}
                            className={classes.linkbtn}
                            style={{ textDecoration: "none" }}
                          >
                            Doctors
                          </RouterLink>
                        </ListItem>

                        <ListItem>
                          <RouterLink
                            to={{
                              pathname: "/labs",
                              state: {
                                userType: "LABS",
                              },
                            }}
                            className={classes.linkbtn}
                            style={{ textDecoration: "none" }}
                          >
                            Labs
                          </RouterLink>
                        </ListItem>
                      </Box>
                    </>
                  )}

                  <Box style={{ display: "flex", marginTop: "20px" }}>
                    {auth?.userLoggedIn === true &&
                    auth?.userData?.userType != "CUSTOMER" ? (
                      <>
                        {/* {auth?.userData?.email} &nbsp; &nbsp;{" "} */}
                        <Box
                          variant="contained"
                          // className={classes.LoginButton}

                          // onClick={LogOut}
                        >
                          <TopDropdownMenu LogOut={LogOut} />{" "}
                          {isloading ? <ButtonCircular /> : ""}
                        </Box>
                        &nbsp; &nbsp;
                      </>
                    ) : (
                      <Link
                        href="/businessLogin"
                        // target={"_blank"}
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          variant="contained"
                          className={classes.LoginButton}
                        >
                          login
                        </Button>
                      </Link>
                    )}
                    <Box></Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </List>
      </Drawer>
    </Box>
  );
};

export default Index;
