import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import {
  Container,
  makeStyles,
  Box,
  Typography,
  Button,
  Divider,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { postAPIdata } from "src/utils/index";
import ModalBoxLogin from "src/component/ModalBoxLogin";
import StarIcon from "@material-ui/icons/Star";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import SentimentSatisfiedOutlinedIcon from "@material-ui/icons/SentimentSatisfiedOutlined";
import { MdStarBorder } from "react-icons/md";
import { GiStoneStack } from "react-icons/gi";
import useCollapse from "react-collapsed";
import { useHistory } from "react-router-dom";
import StarRatings from "react-star-ratings";
import axios from "axios";
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";

import Apiconfigs from "src/ApiConfig/ApiConfig";
import { convertDateTime, userReviewsString } from "src/utils/index";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  mainsection: {
    marginBottom: "50px",
  },
  Buy: {
    marginTop: "20px",
    height: "45px",
    textAlign: "center",
    width: "30%",
    fontSize: "32px",
    backgroundColor: "#253d2f",
    color: "#fff",
    borderRadius: "50px",
  },
  boxes: {
    boxShadow:
      "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)",
    padding: "10px",
    marginTop: "20px",
    "& h6": {
      fontSize: "17px",
      fontWeight: 400,
    },
  },
  box1: {
    display: "flex",
    textAlign: "center",
    justifyContent: "space-between",
    "& p": {},
  },
  text: {
    "& h6": {
      fontSize: "15px",
      color: "#333",
      fontWeight: 400,
    },
    "& p": {
      marginTop: "15px",
      fontSize: "13px",
      fontWeight: 300,
      color: "#707070",
    },
  },
  help: {
    alignItems: "center",
    display: "flex",
  },
  review: {
    display: "flex",
    marginTop: "25px",
  },
  lasttext: {
    display: "flex",
    justifyContent: "space-between",
    color: "#6da69e",
    alignItems: "center",
    padding: "14px 0px 3px 0px",
  },

  text1: {
    "& p": {
      marginTop: "15px",
      maxWidth: "874px !important",
      fontSize: "14px",
    },
    "& h6": {
      fontSize: "12px",
    },
  },

  submit: {
    maxWidth: "322px",
    width: " 100%",
    borderWidth: "2px",
    padding: "12px",
    fontSize: "15px",
    fontWeight: 700,
    color: "#333333",
    border: "2px solid #000",
    borderRadius: "9999px",
    marginTop: "20px",
    lineHeight: "1.2",
    textAlign: "center",
  },
  MainBox1: {
    // display:"flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-around",
  },

  mainBox: {
    marginTop: "25px",
  },
  mainBoxBtn: {
    display: "flex",
    marginTop: "10px",
    "& .iconBox": {
      display: "flex",
      alignItems: "center",
      "& h5": {
        color: "#333",
        fontWeight: 600,
        fontSize: "13px",
        marginLeft: "10px",
      },
    },
    // paddingLeft: "33px",
  },
  mainSubHeading: {
    display: "flex",
    alignItems: "center",
    "& span": {
      color: "#253d2f",
      fontWeight: 800,
      marginLeft: "10px",
      fontSize: "20px",
    },
  },
  btn: {
    maxWidth: "322px",
    width: " 100%",
    borderWidth: "2px",
    padding: "12px",
    fontSize: "15px",
    fontWeight: 700,
    color: "#333333",
    border: "2px solid #000",
    borderRadius: "9999px",
    marginTop: "20px",
    lineHeight: "1.2",
    textAlign: "center",
  },
  closeIcon: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modelbox: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    minWidth: "30%",
    // minHeight: "300px",
    padding: "15px",
    "& .mainSection": {
      padding: "15px",
      "& h6": {
        fontSize: "16px",
        padding: "20px 0px",
        fontWeight: 500,
        color: "#333333",
        maxWidth: "400px",
      },
      "& h5": {
        fontSize: "18px",
        fontWeight: 700,
        color: "#333333",
        maxWidth: "400px",
        // marginTop:'-40px',
      },
    },
  },
  closeIcon: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  BtnSection: {
    textAlign: "center",
    marginTop: "35px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  TopSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& h5": {
      fontWeight: 600,
      color: "#333",
    },
  },
  leftBtn: {
    width: "100%",
    border: "1px solid #333",
    borderRadius: "25px",
  },
}));

export default function MACReview({ _strainsId, _viewstrains }) {
  const classes = useStyles();
  const history = useHistory();
  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
  const [_userrating, setUserRating] = useState();
  const [_reviewlist, setRivewList] = useState([]);
  const [open1, setOpen1] = React.useState(false);
  const [_review_id, setReview_Id] = useState();
  const handleOpen1 = (_id) => {
    setOpen1(true);
    setReview_Id(_id);
  };
  const [_isloading, setIsLoadig] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const [_reviewId, setReviewID] = useState();

  const handleClickOpen = (id) => {
    setReviewID(id);
    setOpen1(true);
  };

  const ReviewListFunction = async (_id) => {
    try {
      let res;

      if (window.sessionStorage.getItem("AccessToken")) {
        res = await postAPIdata({
          data: {
            strainId: _strainsId,
            page: "1",
            limit: "10",
          },
          endPoint: Apiconfigs.reviewListproduct,
          headers: {
            token: window.sessionStorage.getItem("AccessToken"),
          },
        });
      } else {
        res = await postAPIdata({
          data: {
            strainId: _strainsId,
            page: "1",
            limit: "10",
          },
          endPoint: Apiconfigs.reviewListproduct,
        });
      }
      if (res) {
        setRivewList(res?.result?.docs);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const likeFunction = async (id) => {
    try {
      const res = await axios({
        url: Apiconfigs.likeDislike,
        method: "POST",
        data: {
          reviewId: id,
          favouriteType: "REVIEW",
        },
        headers: {
          token: window.sessionStorage.getItem("AccessToken"),
        },
      });
      if (res) {
        ReviewListFunction(_strainsId);
      }
    } catch (error) {
      console.log("error?.response", error?.response);
      if (error?.response?.data?.responseCode === 401) {
        handleOpen();
      }
    }
  };
  const ReportReview = async (review_id) => {
    try {
      const res = await axios({
        url: Apiconfigs.likeDislike,
        method: "POST",
        headers: {
          token: window.sessionStorage.getItem("AccessToken"),
        },
        data: {
          reviewId: _review_id,
        },
      });
      if (res) {
      }
    } catch (error) {}
  };
  const ReportFunction = async (id) => {
    try {
      setIsLoadig(true);
      const res = await axios({
        url: Apiconfigs.reportReview,
        method: "POST",
        headers: {
          token: window.sessionStorage.getItem("AccessToken"),
        },
        data: {
          reviewId: _reviewId,
        },
      });
      if (res) {
        setIsLoadig(false);

        ReviewListFunction();
        handleClose();
      }
    } catch (error) {
      if (error?.response?.data?.responseCode === 401) {
        handleOpen();

        setIsLoadig(false);
      } else if (error?.response?.data?.responseCode === 409) {
        toast.error("You are already reported.");
        setIsLoadig(false);
      }

      setIsLoadig(false);
    }
  };
  useEffect(() => {
    ReviewListFunction();
  }, []);
  return (
    <Box className={classes.mainsection}>
      <Container maxWidth={false} style={{ maxWidth: "1160px" }} elevation={3}>
        <Box className={classes.mainSubHeading}>
          <Typography variant="h3">
            {_viewstrains?.strainName} reviews
          </Typography>
          <span>({_reviewlist?.length})</span>
        </Box>
        <Button
          className={classes.btn}
          type="submit"
          onClick={() =>
            history.push({
              pathname: "/review",
              state: {
                _id: _strainsId,
                _viewstrains: _viewstrains,
              },
            })
          }
        >
          Write a review
        </Button>
        {/* </Box> */}
        {/*  */}
        {_reviewlist &&
          _reviewlist.map((data, index) => (
            <>
              <Box className={classes.boxes}>
                <Box className={classes.box1}>
                  <Box className={classes.rating}>
                    {/* <span>{data?.averageRating}</span> */}
                    <StarRatings
                      starRatedColor="rgb(8 143 125)"
                      style={{ marginTop: "10px" }}
                      rating={Number(data?.rating ? data?.rating : 0)}
                      starDimension="25px"
                      starSpacing="0px"
                    />
                    <span
                      style={{
                        color: "#416654",
                        textDecoration: "underline",
                      }}
                    >
                      {/* {data?.reviewCount} */}
                    </span>
                  </Box>
                  <Typography variant="body1">
                    {convertDateTime(data?.updatedAt)}
                  </Typography>
                </Box>
                {data?.isPrivate !== true ? (
                  <Typography
                    variant="h6"
                    style={{
                      fontSize: "15px",
                      fontWeight: "500",
                      marginTop: "10px",
                    }}
                  >
                    {/* {userReviewsString(data?.userId?.firstName)} */}
                  </Typography>
                ) : (
                  ""
                )}
                <Box className={classes.mainBoxBtn}>
                  {data?.effects &&
                    data?.effects.map((dataValue) => {
                      return (
                        <Box className="iconBox">
                          <img
                            src={dataValue?.icon}
                            style={{ width: "30px", height: "30px" }}
                          />
                          <Typography variant="h5">
                            {dataValue?.name}
                          </Typography>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </Box>
                      );
                    })}
                </Box>
                <Divider style={{ marginTop: "10px" }} />

                <Box className={classes.mainBox}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography
                        variant="h6"
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                          marginTop: "10px",
                        }}
                      >
                        {/* {data?.userId?.firstName !== undefined
                          ? userReviewsString(data?.userId?.firstName)
                          : ""} */}
                      </Typography>
                      <Box className={classes.text}>
                        <Typography variant="h6">
                          {data?.additionalThoughts}
                        </Typography>

                        {/* <Typography variant="body1">
                          {data?.rating} people found this helpful
                        </Typography> */}
                      </Box>
                      <Box className={classes.lasttext}>
                        <Box className={classes.help}>
                          <Box className={classes.help}>
                            {data?.isLiked === true ? (
                              <>
                                <ThumbUpIcon
                                  style={{
                                    cursor: "pointer",
                                    color: "rgb(8 143 125)",
                                  }}
                                  onClick={() => likeFunction(data?._id)}
                                />
                              </>
                            ) : (
                              <>
                                {" "}
                                <ThumbUpAltOutlinedIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => likeFunction(data?._id)}
                                />
                              </>
                            )}
                            &nbsp;
                            <Typography
                              variant="body1"
                              style={{
                                color: "rgb(8 143 125)",
                              }}
                            >
                              Helpful
                            </Typography>
                          </Box>
                        </Box>
                        <Box className={classes.report}>
                          <Button
                            onClick={() => handleClickOpen(data?._id)}
                            disabled={data?.isReported === true ? true : false}
                          >
                            {data?.isReported === true ? (
                              <Typography
                                variant="body1"
                                style={{ color: "red" }}
                              >
                                Reported
                              </Typography>
                            ) : (
                              <Typography variant="body1">Report</Typography>
                            )}
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </>
          ))}
        <ModalBoxLogin
          setOpen={setOpen}
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
        />
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open1}
          // onClose={handleClose}
          onClose={handleClose1}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open1}>
            <Box className={classes.modelbox}>
              <Box className={classes.TopSection} align="right">
                <Typography variant="h5">Report Review</Typography>

                <CloseIcon
                  onClick={() => setOpen1(false)}
                  className={classes.closeIcon}
                />
              </Box>

              <Box className="mainSection">
                <Box align="center">
                  <Typography variant="h6">
                    Are you sure you want to report this review?
                  </Typography>
                </Box>
                <Box className={classes.BtnSection}>
                  <Button
                    className={classes.leftBtn}
                    onClick={() => setOpen1(false)}
                  >
                    cancel
                  </Button>
                  &nbsp;&nbsp;{" "}
                  <Button
                    variant="containedPrimary"
                    onClick={() => ReportFunction()}
                  >
                    yes,report view
                    {_isloading ? (
                      <CircularProgress style={{ fontSize: "10px" }} />
                    ) : (
                      ""
                    )}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </Container>
    </Box>
  );
}
