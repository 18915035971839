import Apiconfigs from "src/ApiConfig/ApiConfig";
import {
  Divider,
  makeStyles,
  Container,
  Typography,
  Box,
  Grid,
  Card,
  Button,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { TbCircleSquare } from "react-icons/tb";
import StockProduct from "./StockProduct";
import DataNotFound from "src/component/BlueDream/DataNotFound";
const useStyles = makeStyles((theme) => ({
  mainSimilartoMAC: {
    margin: "55px 0px 20px 0px",
  },
  subSection: {
    marginTop: "40px",
    "& .topHeading": {
      display: "flex",
      alignItems: "center",
      marginTop: "10px",

      "& h5": {
        fontWeight: 400,
        [theme.breakpoints.down("sm")]: {
          fontSize: "13px",
        },
      },
      "& span": {
        fontWeight: 600,
        color: "#333",
        fontFamily: "'Poppins', sans-serif",
        fontSize: "18px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "13px",
        },
        [theme.breakpoints.down("xs")]: {
          paddingLeft: "10px",
        },
      },
    },
  },
  mainGridCard: {
    position: "relative",
    minHeight: "300px",
    "& h5": {
      fontSize: "13px",
      fontWeight: 400,
      marginTop: "30px",
      color: "#707070",
    },
    "& .cardContent": {
      "& h4": {
        fontSize: "18px",
        fontWeight: 600,
        color: "#333333",
      },
    },
  },
}));

const SimilartoMAC = ({
  _viewstrains,
  strainsID,
  EffectId,
  Terpensis,
  EffectToAvoid,
  Flavour,
  effectTOavoidKey,
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [_similerwedding, setSimilerWedding] = useState([]);
  const locationHold = window.localStorage.getItem("UserLocation");

  const SililarProductFunction = async (
    Flavour,
    Terpensis,
    EffectId,
    strainsID,
    effectTOavoidKey
  ) => {
    let EventId = Flavour;

    try {
      const res = await axios({
        url: Apiconfigs.listStrains,
        method: "POST",
        data: {
          strainType: strainsID,
          effects: EffectId,
          terps: Terpensis,
          effectsToAvoid: effectTOavoidKey,
          flavors: Flavour,
        },
      });
      if (res) {
        setSimilerWedding(res?.data?.result?.docs);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    if (Terpensis && Flavour && EffectId && strainsID && effectTOavoidKey) {
      SililarProductFunction(
        Terpensis,
        Flavour,
        EffectId,
        strainsID,
        effectTOavoidKey
      );
    }
  }, [Terpensis, Flavour, EffectId, strainsID, effectTOavoidKey]);
  return (
    <div className={classes.mainSimilartoMAC}>
      <Divider />
      <Container maxWidth={false} style={{ maxWidth: "1160px" }}>
        <Box className={classes.subSection}>
          <Typography variant="h3">
            Similar to {_viewstrains?.strainName} near {locationHold}
          </Typography>
          <Box className="topHeading">
            <TbCircleSquare />
            <span>Similar strains:</span>
            <Typography variant="h5">
              &nbsp;We used science to find strains with similar terpenes and
              effects
            </Typography>
          </Box>
        </Box>
        <Box className={classes.mainGridBox}>
          <Grid container spacing={2}>
            {_similerwedding &&
              _similerwedding.map((data) => {
                return (
                  <Grid item xs={12} sm={4} md={4} lg={2}>
                    <Card className={classes.mainGridCard}>
                      <Box className="imgBox">
                        <img src={data.img} alt={data.icon} />
                      </Box>
                      <Box className="cardContent">
                        <Typography variant="h4">{data.strainName}</Typography>
                        <Typography variant="body1">{data.aka}</Typography>
                      </Box>
                      <Box style={{ position: "absolute", bottom: 0 }}>
                        <Typography variant="h5">{data.btnText}</Typography>
                      </Box>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        </Box>
        <Box mt={2}>{_similerwedding.length === 0 && <DataNotFound />}</Box>
      </Container>
      <StockProduct />
    </div>
  );
};

export default SimilartoMAC;
