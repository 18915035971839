import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Apiconfigs from "src/ApiConfig/ApiConfig";
import { Link as RouterLink } from "react-router-dom";
import axios from "axios";
import ListItemText from "@material-ui/core/ListItemText";
import { BiCurrentLocation } from "react-icons/bi";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { InputBase } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { getAPIdata } from "src/utils";
import { GoPrimitiveDot } from "react-icons/go";
import LocationAllowModal from "src/component/LocationAllowModal";
import {
  Typography,
  Box,
  Drawer,
  List,
  ListItem,
  makeStyles,
  useTheme,
  Divider,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Button,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";

import CloseIcon from "@material-ui/icons/Close";
import { AuthContext } from "src/context/Auth";
import { UserContext } from "src/context/User";
import { toast } from "react-toastify";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { ContactSupportOutlined } from "@material-ui/icons";
import { map } from "src/utils";
const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  menu: {
    display: "flex",
    justifyContent: "space-between",
    width: "50%",
  },
  drawer: {
    width: "300px",
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    paddingTop: "20px",
    "& h3": {
      color: "#253d2f",
      fontSize: "24px",
    },
  },
  textColor: {
    "& span": {
      fontSize: "16px",
      color: "rgb(43, 124, 107)",
    },
  },
  textHeading: {
    "& span": {
      fontSize: "18px",
      fontWeight: 700,
      color: "rgb(43, 124, 107)",
      cursor: "pointer",
    },
  },
  AccordionList: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 10px 0px 0px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  heading: {
    fontSize: "18px",
    fontWeight: 700,
    color: "rgb(43, 124, 107)",
  },
  buttonLocation: {
    border: "1px solid #e7e7e7",
    width: "100%",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    borderRadius: "5px",
    maxWidth: "263px",
    marginTop: "10px",
    fontFamily: "'Poppins', sans-serif",
    color: "#707070",
    "&:hover": {
      cursor: "pointer",
    },
    "& .iconSize": {
      fontSize: "14px",
    },
    "& h5": {
      fontSize: "16px",
      color: "#2b7c6b",
    },
  },
  last: {
    "& h6": {
      fontSize: "14px",
      color: "#2b7c6b",
      paddingTop: "10px",
    },
  },
  SignOutBtn: {
    padding: "20px 0px",
    "& button": {
      color: "rgb(43, 124, 107)",
      fontSize: "16px",
      fontWeight: 400,
    },
  },
  mainGirgon: {
    display: "flex",
    alignItems: "center",
    paddingTop: "30px",
    "& h6": {
      fontSize: "13px",
      display: "flex",
      fontWeight: 400,
    },
  },
  topText: {
    paddingTop: "20px",
    "& h4": {
      fontSize: "16px",
      fontWeight: 400,
      color: "rgb(43, 124, 107)",
    },
  },
  btnBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "180px",
  },
  TwoButton: {
    border: "1px solid #e7e7e7",
    "& span": {
      fontSize: "12px",
      fontWeight: 500,
      color: "rgb(106, 165, 155)",
    },
  },
  search: {
    border: "1px solid #e7e7e7",
    color: "#fff",
    width: "100%",
    height: "40px",
    position: "relative",
    borderRadius: "8px",
    maxWidth: "278px",
    marginTop: "10px",
    background: "#fff",
    backdropFilter: "blur(15px)",
    overflow: "hidden",
  },
  searchIcon: {
    color: "#656565",
    height: "100%",
    top: "12px",
    // display: "flex",
    padding: "0px 8px",
    position: "absolute",
    fontSize: "16px",
    right: "15px",
    alignItems: "center",
    pointerEvents: "none",
    justifyContent: "center",
    background: "rgba(255, 255, 255, 0.4)",
    // backdropFilter: "blur(20px)",
  },
  inputRoot: {
    color: "inherit",
    width: "95%",
    fontSize: "16px",
    border: "2px solid #e7e7e7",
    borderRadius: "5px",
  },
  inputInput: {
    borderRadius: "5px",
    padding: "19px 0px 19px 0px",
    fontSize: "16px",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: "700px",
    color: "#333333",
    paddingLeft: `calc(1em + ${theme.spacing(0)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "50%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  lastsection: {
    "& h6": {
      fontSize: "12px",
      paddingTop: "20px",
    },
  },
  autocompleteClass: {
    border: "1px solid grey",
    width: "95%",
    borderRadius: "5px",
    textAlign: "center",
  },
  root: {},
}));

export default function DispenseriesLocation({
  type,
  changeLocation,
  handleDrawerOpen,
  setOpen,
  open,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const user = useContext(UserContext);
  const [_profile, setProfile] = useState("");
  const [_getSerchName, setSearchName] = useState("");
  const [_getLocation, setLocation] = useState(false);

  const [_Lat, setLat] = useState("");
  const [_Long, setLong] = useState("");
  const [address, setAddress] = useState("");
  const [_place_id, setPlaceId] = useState(
    window.localStorage.getItem("PLACE_Id")
  );
  const [_latcordinate, setLatCordinate] = useState();
  const [_longcordinate, setLongCordinate] = useState();
  const [_getUserSearchValue, setUserSearchValue] = useState(
    window.localStorage.getItem("UserLocation")
  );

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddressChange = (add) => {
    setAddress(add);
  };

  const handleSelect = (address) => {
    let add = address.split(","),
      addr;
    if (add.length <= 3) {
      addr = address;
      // setUserSearchValue(address);
    } else {
      // setUserSearchValue(
      addr = `${add[0] ? add[0] : ""},${
        add[add.length - 4] ? add[add.length - 4] : ""
      },${add[add.length - 1] ? add[add.length - 1] : ""}`;
      // );
    }
    window.localStorage.setItem("UserLocation", addr);
    geocodeByAddress(address)
      .then((results) => {
        // `${results[0].address_components[0].long_name}, ${results[0].address_components[2].long_name}, ${results[0].address_components[4].short_name}`)
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        setLat(latLng.lat);
        setLong(latLng.lng);
        window.localStorage.setItem("UserLatitude", latLng.lat);
        window.localStorage.setItem("UserLongitute", latLng.lng);
        window.location.reload();
      })
      .catch((error) => console.error("Error", error));
  };
  const showPosition = async (position) => {
    let res = await getAPIdata({
      endPoint: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${map.googleMapKey}`,
    });
    if (res) {
      let showadd = `${
        res?.results[0].address_components[
          res?.results[0].address_components.length - 6
        ]
          ? res?.results[0].address_components[
              res?.results[0].address_components.length - 6
            ].short_name
          : ""
      }, ${
        res?.results[0].address_components[
          res?.results[0].address_components.length - 5
        ]
          ? res?.results[0].address_components[
              res?.results[0].address_components.length - 5
            ].short_name
          : ""
      }, ${
        res?.results[0].address_components[
          res?.results[0].address_components.length - 3
        ]
          ? res?.results[0].address_components[
              res?.results[0].address_components.length - 3
            ].short_name
          : ""
      }`;
      window.localStorage.setItem("UserLocation", showadd);
      window.localStorage.setItem("UserLatitude", position.coords.latitude);
      window.localStorage.setItem("UserLongitute", position.coords.longitude);
      window.location.reload();
    }
  };

  const getData = async () => {
    const res = await axios.post(
      `https://www.googleapis.com/geolocation/v1/geolocate?key=${map.googleMapKey}`
    );
    console.log("res.data-----", res);
    let position = {
      coords: {
        latitude: res?.data?.location?.lat,
        longitude: res?.data?.location?.lng,
      },
    };
    await showPosition(position);
  };
  return (
    <div className={classes.root}>
      {/* {window.localStorage.getItem("UserLocation") === "null" ||
        (window.localStorage.getItem("UserLocation") === null && (
          <LocationAllowModal />
        ))} */}
      {type !== "CHANGE" ? (
        <>
          <Box
            display="flex"
            alignItems="center"
            style={{ padding: "0 0px", margin: "15px 0" }}
          >
            {changeLocation === "DISPENSARIES" && (
              <GoPrimitiveDot
                style={{ fontSize: "14px !important", color: "#416654" }}
              />
            )}

            <Typography
              variant="body1"
              onClick={handleDrawerOpen}
              // onClick={handleDrawerOpen}

              style={{
                color: "rgb(29 153 136)",
                fontWeight: 600,
                fontSize: "12px",
                cursor: "pointer",
                // padding: "0 15px",
              }}
            >
              {changeLocation === "DISPENSARIES"
                ? " Change your location"
                : "Change"}
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <Box style={{ padding: "20px 0px" }}>
            <Button onClick={handleDrawerOpen} variant="containedPrimary">
              change your location
            </Button>
          </Box>
        </>
      )}

      <Drawer
        className={classes.drawer}
        anchor="left"
        open={open}
        onClose={handleClose}
        style={{ maxWidth: "320px !important" }}
        // onClick={handleDrawerClose}
      >
        <div className={classes.drawerHeader}>
          <Typography variant="h3">Get Local results</Typography>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon style={{ color: "rgb(43, 124, 107)" }} />
          </IconButton>
        </div>
        <Divider />
        <Box className={classes.mainGirgon}>
          <LocationOnIcon style={{ fontSize: "14px", color: "green" }} />{" "}
          <Typography
            style={{
              fontSize: "14px",
              color: "rgb(43, 124, 107)",
              fontWeight: 500,
            }}
          >
            Current general location :{" "}
          </Typography>{" "}
          &nbsp;
        </Box>
        <Box>
          <Typography>
            <span style={{ fontWeight: 600, fontSize: "12px" }}>
              {window.localStorage.getItem("UserLocation")}
              {/* {userLocation != null && userLocation != "null"
            ? userLocation
            : userCurrentLocation} */}
            </span>
          </Typography>
        </Box>

        <Box className={classes.topText}>
          <Typography variant="h4">
            Enter your location to see results closest to you.
          </Typography>
        </Box>
        <Box className={classes.buttonLocation} onClick={getData}>
          <BiCurrentLocation
            className="iconSize"
            style={{ color: "#2b7c6b" }}
          />
          &nbsp;&nbsp;
          <Typography variant="h5"> use my exact locations</Typography>
        </Box>
        <Box className={classes.last} align="center">
          <Typography variant="h6">-or-</Typography>
        </Box>
        <PlacesAutocomplete
          className={classes.autocompleteClass}
          value={address}
          onChange={handleAddressChange}
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div style={{ position: "relative" }}>
              <div className={classes.searchIcon}>
                <SearchIcon style={{ color: "#2ea191" }} />
              </div>
              <InputBase
                placeholder="Enter my location manually..."
                // onClick={handleDrawerOpen}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                {...getInputProps({
                  placeholder: "Search Places ...",
                  className: "location-search-input",
                })}
                inputProps={{ "aria-label": "search" }}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? {
                        backgroundColor: "#fafafa",
                        cursor: "pointer",
                        color: "#2b7c6b",
                        borderBottom: "1px solid #e7e7e7",
                        marginTop: "9px",
                        fontSize: "186x",
                        fontWeight: "700",
                        padding: "4px 30px 8px 0px",
                      }
                    : {
                        backgroundColor: "#ffffff",
                        cursor: "pointer",
                        color: "#2b7c6b",
                        borderBottom: "1px solid #e7e7e7",
                        marginTop: "9px",
                        fontSize: "16px",
                        fontWeight: "700",
                        padding: "4px 30px 8px 0px",
                      };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>

        {/* <Box className={classes.lastsection}>
          {_getLocation &&
            _getLocation.map((data, i) => {
              return (
                <>

                  <Button
                    key={i}
                    style={{ width: "100%" }}
                    onClick={(e) =>
                      ChangeSearchValue(
                        data?.description,

                        data?.place_id
                        // data?.geometry?.location?.lng
                        // data?.lat,
                        // data?.lon
                      )
                    }
                  >
                    {" "}
                    {data?.description},
                  </Button>
                </>
              );
            })}

          {_getSerchName === "" ? (
            <Typography variant="h6">
              We do not share your location with anyone.
            </Typography>
          ) : (
            ""
          )}
        </Box> */}
      </Drawer>
    </div>
  );
}
