import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  makeStyles,
  Box,
  Typography,
  FormControl,
  TextareaAutosize,
  Button,
  TextField,
  FormHelperText,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import * as yep from "yup";
import CheckedBox from "./CheckedBox";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Apiconfigs from "src/ApiConfig/ApiConfig";
import axios from "axios";
import PageLoading from "src/component/PageLoading";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

const formInitialSchema = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  company: "",
  zipCode: "",
  phoneNo: "",
  businessType: "",
  partnerWith: "",
  termCondition: false,
  businessLicense: "",
  address: "",
};

const formValidationSchema = yep.object().shape({
  firstName: yep
    .string()
    .required("Please enter first name.")
    .min(2, "Minimum 2 charecter.")
    .max(50, "Maximum 50 charecter"),
  lastName: yep
    .string()
    .required("Please enter last name.")
    .min(2, "Minimum 2 charecter.")
    .max(50, "Maximum 50 charecter."),
  email: yep
    .string()
    .email("Please enter an valid email address.")
    .required("Email address is required.")
    .matches("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")
    .max(360, "Maximum 360 charecter."), // need to check again

  company: yep
    .string()
    .required("Please enter company name.")
    .max(110, "Maximum 100 charecter."), // need to check again

  zipCode: yep
    .string()
    .required("Please enter your zip.")
    .matches(/^\d{5,10}(?:[-\s]\d{4})?$/, "Zip code is invalid.")
    // .min(5, "Zip code needs to be between 5 and 10 digits")
    .max(6, "maximum 6 charecter."), // need to check again

  phoneNo: yep.string().required("Required"),
  businessType: yep
    .string()
    .required("Please select an option from the dropdown menu."),
  partnerWith: yep.string().required("Please complete this required field."),
  password: yep
    .string()
    .required("Please enter password.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
      "Please enter strong password."
    )
    .max(16, "Maximum 16 charecters"), // need to check again

  address: yep
    .string()
    .required("Please enter the address.")
    .max(250, "Maximum 250 charecters"), // need to check again
});

const useStyles = makeStyles((theme) => ({
  LetsTalkForm: {
    paddingTop: "50px",
    "& h3": {
      fontSize: "35px",
      lineHeight: "1.2",
      marginBottom: "15px",
      color: "#494a52",
      [theme.breakpoints.down("xs")]: {
        fontSize: "24px",
      },
    },
  },

  mainFormBox: {
    "& .repeatFomBox": {
      paddingTop: "30px",
      "& p": {
        fontSize: "15px",
        fontWeight: 500,
        color: "#333",
      },
    },
  },
  mainGridFormBox: {
    paddingTop: "30px",
  },
  twoField: {
    "& p": {
      fontSize: "15px",
      fontWeight: 500,
      color: "#333",
    },
  },
  btnButton: {
    background: "rgb(8 143 125)",
    borderRadius: "25px",
    padding: "12px 55px",
    color: "white",
    marginTop: "25px",
    "&:hover": {
      color: "rgb(8 143 125)",
    },
  },
  Selectbox: {
    padding: "7px 0px",
  },
  ImClass: {
    fontSize: "40px",
    fontWeight: "400",
  },
  RetailStore: {
    // border: "1px solid black",
    boxShadow:
      "rgb(0 0 0 / 20%) 0px 20px 13px -1px, rgb(0 0 0 / 18%) 0px 9px 14px 0px, rgb(0 0 0 / 12%) 0px 1px 17px 0px",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
    maxWidth: "170px",
    height: "135px",
    "& .active": {
      display: "flex",
      padding: "10px",
      alignItems: "center",
      justifyContent: "center",
      background: "honeydew",
      boxShadow:
        "rgb(0 0 0 / 20%) 0px 20px 13px -1px, rgb(0 0 0 / 18%) 0px 9px 14px 0px, rgb(0 0 0 / 12%) 0px 1px 17px 0px",
      borderRadius: "15px",

      border: "3px solid #e7e7e7",
    },
    "& img": {
      width: "84%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "5px",
      padding: "25px 41px",
    },
  },
  reatails_store: {
    marginTop: "5px",
    textAlign: "center",
    fontSize: "18px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  selectBox: {
    height: "50px",
  },
}));

const selectOptions = [
  {
    value: "Storefront Location (CBD/THC/Retail Dispensary)",
    name: "Storefront Location (CBD/THC/Retail Dispensary)",
  },
  { value: "Delivery Only Service", name: "Delivery Only Service" },
  {
    value: "Hemp Derived CBD / Delta 8 Brand",
    name: "Hemp Derived CBD / Delta 8 Brand",
  },
  {
    value: "Hardware (Glass, Vape, Accessories)",
    name: "Hardware (Glass, Vape, Accessories)",
  },
  {
    value: "THC Infused Brand (Producer/Processor)",
    name: "THC Infused Brand (Producer/Processor)",
  },
  {
    value: "Clinic / Doctor",
    name: "Clinic / Doctor",
  },
  {
    value: "Grow Supply",
    name: "Grow Supply",
  },
  {
    value: "B2B Service",
    name: "B2B Service",
  },
  {
    value: "Lab/Testing Facility",
    name: "Lab/Testing Facility",
  },
  {
    value: "Other",
    name: "Other",
  },
];
const selectLicense = [
  {
    value: "Yes, and I can submit it now.",
    name: "Yes, and I can submit it now.",
  },
  {
    value: "Yes, but not on me.",
    name: "Yes, but not on me.",
  },
  {
    value: "Not yet, but I will soon.",
    name: "Not yet, but I will soon.",
  },
  {
    value: "No, I don't have a business license.",
    name: "No, I don't have a business license.",
  },
  {
    value: "No license needed - Indigenous Sovereignty",
    name: "No license needed - Indigenous Sovereignty",
  },
];

const LetsTalkForm = () => {
  const classes = useStyles();
  const history = useHistory();

  const [getValues, setValues] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [fieldValue, setFieldValue] = useState();
  const [isloading, setLoader] = useState(false);
  const [latlogn, setLangLong] = useState("");
  const [_usertype, setUserTYPE] = useState();
  const [active, setActive] = useState("");
  // get the ip address
  const [ip, setIP] = useState("");
  const { latitude, longitude } = ip;
  let iphold = [longitude, latitude];
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    setIP(res.data);
    setLangLong(res.data.longitude);
    // holdValue(setIP);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleChange = (e) => {
    setValues(e.target.value);
  };

  const handleFormSubmit = async (values) => {
    window.localStorage.setItem("userType", _usertype);

    try {
      setLoader(true);
      const res = await axios({
        method: "POST",
        url: Apiconfigs.signUp,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          userType: _usertype,
          firstName: values.firstName,
          lastName: values.lastName,
          countryCode: countryCode,
          mobileNumber: values.phoneNo,
          email: values.email,
          company: values.company,
          businessType: values.businessType,
          doYouHaveActiveBusinessLicense: values.businessLicense,
          password: values.password,
          whyPartnerWithLeafly: values?.partnerWith,
          zipCode: values.zipCode,
          location: {
            type: "Point",
            coordinates: iphold,
          },
        },
      });
      if (res) {
        toast.success(
          "User verification request send successfully to the admin ."
        );
        setLoader(false);
        history.push({
          pathname: "/businessLogin",
          state: {
            // emailId: "",
            type: _usertype,
          },
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.responseMessage);

      setLoader(false);
    }
  };

  return (
    <div className={classes.LetsTalkForm}>
      {isloading ? <PageLoading /> : ""}
      <Typography className={classes.ImClass} variant="h4">
        I'm a...
      </Typography>
      <Box mt={3}>
        <Box>
          <Grid container spacing={3}>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Box className={classes.RetailStore}>
                <Box
                  className={active === "one" ? "active" : ""}
                  onClick={() => setActive("one")}
                >
                  <Button onClick={() => setUserTYPE("MERCHANT")}>
                    <img src="\images\Reails.png"></img>
                  </Button>
                </Box>
              </Box>

              <Typography className={classes.reatails_store}>
                Retail store / delivery service
              </Typography>
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Box className={classes.RetailStore}>
                <Box
                  className={active === "three" ? "active" : ""}
                  onClick={() => setActive("three")}
                >
                  <Button onClick={() => setUserTYPE("DOCTOR")}>
                    <img src="\images\doctor.svg"></img>
                  </Button>
                </Box>
              </Box>
              <Typography className={classes.reatails_store}>Doctor</Typography>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Box className={classes.RetailStore}>
                <Box
                  className={active === "forth" ? "active" : ""}
                  onClick={() => setActive("forth")}
                >
                  <Button onClick={() => setUserTYPE("LABS")}>
                    <img src="\images\lab.svg"></img>
                  </Button>
                </Box>
              </Box>
              <Typography className={classes.reatails_store}>Lab</Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box style={{ paddingTop: "50px" }}>
        <Typography variant="h3">
          Let’s talk about your business on Kanabix, today. Will you? Connect
          With Us!
        </Typography>
        <Formik
          initialValues={formInitialSchema}
          initialStatus={{
            success: false,
            successMsg: "",
          }}
          validationSchema={formValidationSchema}
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue,
          }) => (
            <Form>
              <Box className={classes.mainFormBox}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box className={classes.twoField}>
                      <Typography variant="body1">First name</Typography>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          name="firstName"
                          value={values.firstName}
                          error={Boolean(touched.firstName && errors.firstName)}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Please enter first name"
                        />
                        <FormHelperText
                          error
                          style={{
                            margin: "0px",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          {touched.firstName && errors.firstName}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box className={classes.twoField}>
                      <Typography variant="body1">Last name</Typography>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          name="lastName"
                          value={values.lastName}
                          error={Boolean(touched.lastName && errors.lastName)}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Please enter last name"
                        />
                        <FormHelperText
                          error
                          style={{
                            margin: "0px",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          {touched.lastName && errors.lastName}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box className="repeatFomBox">
                      <Typography variant="body1">Email</Typography>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          name="email"
                          value={values.email}
                          error={Boolean(touched.email && errors.email)}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Please enter email address"
                        />
                        <FormHelperText
                          error
                          style={{
                            margin: "0px",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          {touched.email && errors.email}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box className="repeatFomBox">
                      <Typography variant="body1">Password</Typography>
                      <FormControl variant="outlined" fullWidth>
                        <TextField
                          variant="outlined"
                          name="password"
                          value={values.password}
                          error={Boolean(touched.password && errors.password)}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder="Please enter password"
                        />
                        <FormHelperText
                          error
                          style={{
                            margin: "0px",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          {touched.password && errors.password}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>

                <Box className={classes.mainGridFormBox}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <Box className={classes.twoField}>
                        <Typography variant="body1">Company</Typography>
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            variant="outlined"
                            name="company"
                            value={values.company}
                            error={Boolean(touched.company && errors.company)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Please enter company name"
                          />
                          <FormHelperText
                            error
                            style={{
                              margin: "0px",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            {touched.company && errors.company}
                          </FormHelperText>
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <Box className={classes.twoField}>
                        <Typography variant="body1">Zipcode</Typography>
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            variant="outlined"
                            name="zipCode"
                            value={values.zipCode}
                            error={Boolean(touched.zipCode && errors.zipCode)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Please enter zip code"
                          />
                          <FormHelperText
                            error
                            style={{
                              margin: "0px",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            {touched.zipCode && errors.zipCode}
                          </FormHelperText>
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box className={classes.mainGridFormBox}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box className={classes.twoField}>
                        <Typography variant="body1">Address</Typography>
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            variant="outlined"
                            name="address"
                            value={values.address}
                            error={Boolean(touched.address && errors.address)}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder="Please enter address"
                          />
                          <FormHelperText
                            error
                            style={{
                              margin: "0px",
                              fontSize: "12px",
                              color: "red",
                            }}
                          >
                            {touched.address && errors.address}
                          </FormHelperText>
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Box className="repeatFomBox">
                  <FormControl variant="outlined" fullWidth>
                    <Typography variant="body1">Number</Typography>
                    <PhoneInput
                      country={"in"}
                      name="phoneNo"
                      inputStyle={{
                        background: "transparent",
                        width: "100%",
                        color: "#000",
                        height: "50px",
                        borderRadius: "5px",
                      }}
                      value={values.phoneNo}
                      error={Boolean(touched.phoneNo && errors.phoneNo)}
                      onBlur={handleBlur}
                      onChange={(phone, e) => {
                        setCountryCode(e.dialCode);
                        setFieldValue("phoneNo", phone);
                      }}
                    />
                    <FormHelperText
                      error
                      style={{ margin: "0px", fontSize: "12px", color: "red" }}
                    >
                      {touched.phoneNo && errors.phoneNo}
                    </FormHelperText>
                  </FormControl>
                </Box>

                <Box className="repeatFomBox">
                  <Typography variant="body1">Type of Business</Typography>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    fullWidth
                  >
                    <Select
                      value={values.businessType}
                      onChange={handleChange}
                      name="businessType"
                      error={Boolean(
                        touched.businessType && errors.businessType
                      )}
                      onBlur={handleBlur}
                      className={classes.selectBox}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem value="" disabled={true}>
                        Please Select
                      </MenuItem>
                      {selectOptions.map((data, index) => {
                        return (
                          <MenuItem value={data.value} key={index}>
                            {data.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText
                      error
                      style={{ margin: "0px", fontSize: "12px", color: "red" }}
                    >
                      {touched.businessType && errors.businessType}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box className="repeatFomBox">
                  <Typography variant="body1">
                    Do you have an active business license?
                  </Typography>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    fullWidth
                  >
                    <Select
                      value={values.businessLicense}
                      onChange={handleChange}
                      name="businessLicense"
                      error={Boolean(
                        touched.businessLicense && errors.businessLicense
                      )}
                      onBlur={handleBlur}
                      className={classes.selectBox}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem value="" disabled={true}>
                        Please Select
                      </MenuItem>
                      {selectLicense.map((data, index) => {
                        return (
                          <MenuItem value={data.value} key={index}>
                            {data.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText
                      error
                      style={{ margin: "0px", fontSize: "12px", color: "red" }}
                    >
                      {touched.businessType && errors.businessType}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Box className="repeatFomBox">
                  <Typography variant="body1">
                    Why do you want to partner with Kanabix?
                  </Typography>
                  <TextField
                    aria-label="empty textarea"
                    style={{ width: "100%", height: "60px" }}
                    name="partnerWith"
                    value={values.partnerWith}
                    variant="outlined"
                    error={Boolean(touched.partnerWith && errors.partnerWith)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <FormHelperText
                    error
                    style={{ margin: "0px", fontSize: "12px", color: "red" }}
                  >
                    {touched.partnerWith && errors.partnerWith}
                  </FormHelperText>
                </Box>

                <Box className="repeatFomBox">
                  <CheckedBox
                    onChange={handleChange}
                    name="termCondition"
                    value={values.termCondition}
                    required
                  />
                </Box>

                <Box className="repeatFomBox">
                  <Button type="submit" className={classes.btnButton}>
                    Submit
                  </Button>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

export default LetsTalkForm;
