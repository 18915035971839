import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Apiconfigs from "src/ApiConfig/ApiConfig";
import { Link as RouterLink } from "react-router-dom";
import axios from "axios";
import ListItemText from "@material-ui/core/ListItemText";
import {
  Typography,
  Box,
  Drawer,
  List,
  ListItem,
  makeStyles,
  useTheme,
  Divider,
  AccordionSummary,
  Accordion,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import CancelIcon from "@material-ui/icons/Cancel";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import CloseIcon from "@material-ui/icons/Close";
import { AuthContext } from "src/context/Auth";
import { UserContext } from "src/context/User";
import { remove } from "lodash";
import { toast } from "react-toastify";
// import {useNavigate} from "react-router-dom"
import { GoogleLogout } from "react-google-login";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  menu: {
    display: "flex",
    justifyContent: "space-between",
    width: "50%",
  },
  drawer: {
    width: "300px",
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    paddingTop: "20px",
    "& h3": {
      color: "#253d2f",
      fontSize: "24px",
      "@media(max-width:344px)": {
        fontSize: "18px",
      },
    },
  },
  textColor: {
    "& span": {
      fontSize: "16px",
      color: "rgb(43, 124, 107)",
    },
  },
  textHeading: {
    "& span": {
      fontSize: "18px",
      fontWeight: 700,
      color: "rgb(43, 124, 107)",
      cursor: "pointer",
    },
  },
  AccordionList: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 10px 0px 0px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  heading: {
    fontSize: "18px",
    fontWeight: 700,
    color: "rgb(43, 124, 107)",
  },
  SignOutBtn: {
    padding: "20px 0px",
    // marginTop:'-4px',
    margin: "20px 0px",
    // width:"100%",
    "& button": {
      color: "rgb(43, 124, 107)",
      fontSize: "16px",
      fontWeight: 400,
      // width:"100%",
    },
  },
  textDecoration: {
    textDecoration: "none",
  },
  googleButton: {
    "& button": {
      padding: "13px 67px",
      marginBottom: "25px",

      borderRadius: "50px",
      background: "#FFFFFF",
      color: "black",
      border: "2px solid black !important",
      marginTop: "25px",
      width: "95%",
      justifyContent: "center",
      fontSize: "17px",
      // fontWeight: "bolder",
      display: "flex",
      alignItems: "center",
      borderRadius: "25px !important",
      padding: "0px 0px !important",
      "& span": {
        fontSize: "17px",
        color: "black",
        fontWeight: 400,
      },
    },
  },
  drawerPaper: {
    maxWidth: "290px",
  },
}));

const gid =
  "1058956126538-ke59mtie16o5dm5rbr0nsfvhv3qfug2g.apps.googleusercontent.com";

export default function ButtonAppBar() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const history = useHistory();
  const auth = useContext(AuthContext);

  const handleClose = () => {
    setOpen(false);
  };

  // const user = useContext(UserContext);
  const links1 = [
    {
      title: auth?.userData?.email ? auth?.userData?.email : "Your Account",
      path: auth?.userLoggedIn === true ? "/favourite" : "/login",
    },
    {
      title: "Join Kanabix",
      path: "/create-account",
    },

    {
      title: "Order History",
      path: "/orders-history",
    },
    {
      title: "Advertise on Kanabix",
      path: "/kanabix-bit",
    },
  ];
  const LoginSideBar = [
    {
      title: auth?.userData?.email ? auth?.userData?.email : "Your Account",
      path: auth?.userLoggedIn === true ? "/favourite" : "/login",
    },

    {
      title: "Order History",
      path: "/orders-history",
    },
  ];

  const links2 = [
    {
      title: "Favourite",
      path: "/favourite",
    },
    {
      title: "Delivery",
      path: "/delivery",
    },
    {
      title: "Strains",
      path: "/Strains",
    },
    {
      title: "Dispensaries",
      path: "/despansaries",
    },
    {
      title: "CBD Stores",
      path: "/HempCBD",
    },
    {
      title: "Products",
      path: "/product",
    },
    {
      title: "Deals",
      path: "/deals",
    },
    {
      title: "News",
      path: "/news",
    },

    {
      title: "Social impact",
      path: "/social-impact",
    },
    {
      title: "Doctors",
      path: "/despanSariesIndex",
    },
    {
      title: "Lab partners",
      path: "/labs",
    },
  ];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const token = window.sessionStorage.getItem("token");

  const LogOut = async () => {
    try {
      const res = await axios({
        url: Apiconfigs.logout,
        method: "POST",
        headers: {
          token: window.sessionStorage.getItem("AccessToken"),
        },
      });
      if (res?.data?.responseCode === 200) {
        toast.success(res?.data?.responseMessage);
        window.sessionStorage.removeItem("AccessToken");
        // window.localStorage.clear();
        history.push("/login");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    // GETProfile();
  }, [token]);
  const [headerButton, setHeaderButton] = useState([]);
  useEffect(() => {
    if (auth?.userLoggedIn) {
      const filterForLogin = links1.filter((data) => {
        return data.path !== "/create-account" && data.path !== "/kanabix-bit";
      });
      setHeaderButton(filterForLogin);
    } else {
      setHeaderButton(links1);
    }
  }, [auth.userLoggedIn]);

  return (
    <div className={classes.root}>
      <MenuIcon
        onClick={handleDrawerOpen}
        style={{ color: "#253d2f", cursor: "pointer" }}
      />

      {/* <MenuIcon  onClick={handleDrawerOpen} /> */}

      <Drawer
        className={classes.drawer}
        anchor="left"
        open={open}
        onClose={handleClose}
        // style={{ maxWidth: "250px" }}
        classes={{
          paper: classes.drawerPaper,
        }}
        // onClick={handleDrawerClose}
      >
        <div className={classes.drawerHeader}>
          <Typography variant="h3">Browse Kanabix</Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <CloseIcon style={{ color: "rgb(43, 124, 107)" }} />
            ) : (
              <CloseIcon style={{ color: "rgb(43, 124, 108)" }} />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {auth?.userLoggedIn === true ? (
            <>
              {LoginSideBar &&
                LoginSideBar?.map((item, index) => {
                  return (
                    <>
                      <ListItem
                        button
                        key={index}
                        onClick={() => history.push(`${item.path}`)}
                      >
                        <ListItemText
                          className={classes.textColor}
                          primary={item.title}
                        />
                      </ListItem>
                    </>
                  );
                })}
            </>
          ) : (
            <>
              {links1 &&
                links1?.map((item, index) => {
                  return (
                    <>
                      <ListItem
                        button
                        key={index}
                        onClick={() => history.push(`${item.path}`)}
                      >
                        <ListItemText
                          className={classes.textColor}
                          primary={item.title}
                        />
                      </ListItem>
                    </>
                  );
                })}
            </>
          )}
        </List>
        <Divider />
        <List>
          <ListItem>
            <ListItemText
              className={classes.textHeading}
              primary="Home"
              onClick={() => history.push("/")}
            />
          </ListItem>
          {links2.map((item, index) => (
            <ListItem
              button
              key={index}
              onClick={() => history.push(`${item.path}`)}
            >
              <ListItemText
                className={classes.textColor}
                primary={item.title}
              />
            </ListItem>
          ))}
        </List>

        <Divider />
        <ListItem
          disableGutters
          style={{ marginTop: "20px", marginBottom: "15px" }}
        >
          <SmartphoneIcon
            style={{ fontSize: "35px", color: "rgb(43, 124, 107)" }}
          />
          <a
            href="https://play.google.com/"
            target="_"
            className={classes.textDecoration}
          >
            <ListItemText
              className={classes.textColor}
              style={{
                alignItems: "center",
                display: "flex",
                fontSize: "22px",
                fontWeight: "600",
              }}
              primary="Download the Kanabix App"
            />
          </a>
        </ListItem>
        <Divider />
        <List>
          {/* {links3.map((item, index) => (
            <>
              <ListItem button key={index}>
                <ListItemText
                  className={classes.textColor}
                  primary={item.title}
                  onClick={() => history.push(`${item.path}`)}
                />
              </ListItem>
              <Box>
                <Typography onClick={() => history.push("/")}>
                  thailand
                </Typography>
                <img
                  style={{
                    borderRadius: "50px",
                    height: "50px",
                    width: "50px",
                  }}
                  src="./images/istockphoto-670950002-170667a.jpeg"
                ></img>
              </Box>
            </>
          ))} */}
        </List>
        <Box className={classes.dropdown}>
          <List>
            <Accordion elevation={0}>
              <AccordionSummary
                style={{ padding: "0px !important" }}
                expandIcon={
                  <ExpandMoreIcon style={{ color: "rgb(43, 124, 107)" }} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                // style={{padding:"0px !important"}}
                disableGutters
              >
                <Typography className={classes.heading} disableGutters>
                  Country
                </Typography>
              </AccordionSummary>

              <Box className={classes.AccordionList}>
                <ListItemText
                  onClick={() => history.push("/")}
                  button
                  className={classes.textColor}
                  style={{ alignItems: "center", display: "flex" }}
                  primary="kanabix.org"
                />
                <img
                  style={{
                    borderRadius: "50px",
                    height: "40px",
                    width: "40px",
                  }}
                  src="./images/istockphoto-670950002-170667a.jpeg"
                ></img>
              </Box>
            </Accordion>
            {/* <ListItem disableGutters>
              <RouterLink
                className="hovertext"
                to="/static-content"
                style={{ textDecoration: "none" }}
              >
                <ListItemText
                  className={classes.textColor}
                  style={{ alignItems: "center", display: "flex" }}
                  primary="Help"
                />
              </RouterLink>
            </ListItem> */}
          </List>
          <Divider />
          {auth?.userLoggedIn == true &&
            auth?.userData?.socialType == "GOOGLE" && (
              <Box className={classes.googleButton}>
                <GoogleLogout
                  clientId={gid}
                  buttonText="Sign Out"
                  onLogoutSuccess={LogOut}
                />
              </Box>
            )}
          {auth?.userLoggedIn === true &&
            auth?.userData?.socialType != "GOOGLE" && (
              <>
                <Box className={classes.SignOutBtn}>
                  <Button
                    onClick={() => {
                      LogOut();
                    }}
                  >
                    Sign Out
                  </Button>
                </Box>
              </>
            )}
        </Box>
      </Drawer>
    </div>
  );
}
