import BlueDream from "src/component/BlueDream/BlueDream";
import React from "react";
import {
  Container,
  makeStyles,
  Box,
  Typography,
  Button,
  TextField,
  Divider,
  IconButton,
  FormHelperText,
  Link,
  Grid,
} from "@material-ui/core";

import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  MainBox: {
    background: 'url("images/hero-home.webp")',
    backgroundRepeat: "no-repeat",
    paddingBottom: "80px",
    position: "relative",
    paddingTop: "60px",
    backgroundColor: "#f6f6f6",
    backgroundPosition: "center",
    "& h3": {
      fontSize: "35px",
      lineHeight: "1.2",
      marginBottom: "15px",
      color: "#494a52",
      //   maxWidth: "450px",
    },
  },
  BookDemo: {
    backgroundColor: "#2b7c6b",
    borderRadius: "5px",
    width: "184px",
    height: "49px",
    color: "white",
    marginTop: "25px",
    "&:hover": {
      backgroundColor: "#2b7c6b",
    },
  },
  EveryThingImg: {
    "& img": {
      backgroundPosition: "right",
    },
  },
}));
const Everything = () => {
  const classes = useStyles();
  return (
    <Box className={classes.MainBox}>
      <Container maxWidth={false} style={{ maxWidth: "1160px" }}>
        <Grid container>
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <Box className={classes.contentBox} order="1">
              <Typography variant="h3">
                Everything you need to grow a canna business online
              </Typography>
              <Typography style={{ fontSize: "16px" }}>
                Eleven million cannabis journeys begin on Kanabix each month.
                Shoppers come to us 24/7 with a strong intent to purchase.
                Whether you’re a store, brand, doctor, or lab, Kanabix is the
                acquisition tool that grows your business.
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={7} md={7} sm={12} xs={12}></Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Everything;
