import React from "react";
import {
  Container,
  makeStyles,
  Box,
  Link,
  Grid,
  Typography,
  ListItem,
} from "@material-ui/core";
import YouTubeIcon from "@material-ui/icons/YouTube";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  FooterMainBox: {
    background: "rgba(246,246,246,1.0)",
    marginTop: "100px",
    padding: "50px",
    paddingBottom: "70px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "40px",
    },
    "& img": {
      height: "83%",
      width: "100px",
    },
  },
  LinkClass: {
    color: "#007d6c",
    fontSize: "16px",
    fontWeight: "600",
    "&:hover": {
      cursor: "pointer",
    },
  },
  SocialLink: {
    display: "flex",
    "@media(max-width:514px)": {
      marginTop: "50px",
      right: "",
    },
  },
  FaceBook: {
    background: "#007d6c",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& span": {
      fontSize: "20px",
      color: "white",
    },
  },
  ClassGrid: {
    display: "flex",
    alignItems: "end",
    justifyContent: "end",
  },
  Linkclass: {
    fontSize: "14px",
    color: "black",
    fontFamily: "'Poppins', sans-serif",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box className={classes.FooterMainBox}>
      <Container maxWidth={false} style={{ maxWidth: "1160px" }}>
        <Box>
          <img
            src="./images/logo_kanabix.png"
            onClick={() => history.push("/home")}
            style={{ cursor: "pointer" }}
          ></img>
        </Box>
        <Box mt={5} position="relative">
          <Grid container>
            <Grid item lg={8} md={6} sm={12} xs={12}>
              <Grid container spacing={3}>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Box>
                    <Box>
                      <Link className={classes.LinkClass} href="/retails">
                        Retails
                      </Link>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Box>
                    <Box>
                      <Link className={classes.LinkClass} href="/doctors">
                        Doctor
                      </Link>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <Box>
                    <Box>
                      <Link className={classes.LinkClass} href="/labs">
                        Labs
                      </Link>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              lg={4}
              md={6}
              sm={12}
              xs={12}
              className={classes.ClassGrid}
            >
              <Box className={classes.SocialLink}>
                <Link href="https://www.facebook.com/">
                  <Box className={classes.FaceBook}>
                    <span style={{ cursor: "pointer" }}>f</span>
                  </Box>
                </Link>
                &nbsp; &nbsp; &nbsp;
                <Link href="https://www.youtube.com/">
                  <Box className={classes.FaceBook}>
                    <span style={{ marginTop: "5px" }}>
                      <YouTubeIcon style={{ cursor: "pointer" }} />
                    </span>
                  </Box>
                </Link>
                &nbsp; &nbsp; &nbsp;
                <Link href="https://www.instagram.com/accounts/login/">
                  <Box className={classes.FaceBook}>
                    <span style={{ marginTop: "5px" }}>
                      <InstagramIcon style={{ cursor: "pointer" }} />
                    </span>
                  </Box>
                </Link>
                &nbsp; &nbsp; &nbsp;
                <Link href="https://twitter.com/i/flow/login">
                  <Box className={classes.FaceBook}>
                    <span style={{ marginTop: "5px" }}>
                      <TwitterIcon style={{ cursor: "pointer" }} />
                    </span>
                  </Box>
                </Link>
              </Box>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="space-between">
            <Box mt={10}>
              <Typography className={classes.Linkclass}>
                111 S. Jackson St. Suite 531 Seattle, WA 98104
              </Typography>
              <br />
            </Box>
            <Box mt={10}>
              <Box textAlign="end">
                <Typography className={classes.Linkclass}>
                  ©2022 Kanabix, all rights reserved.
                </Typography>
                <br />
                {/* <Link className={classes.Linkclass} href="/static-content">
                  Terms of use
                </Link> */}
                <RouterLink
                  className="hovertext"
                  to={{
                    pathname: "/static-contents",
                    search: "terms-of-use",
                    state: {
                      id: "1",
                    },
                  }}
                  style={{ textDecoration: "none", color: "#333" }}
                >
                  Terms of use
                </RouterLink>{" "}
                &nbsp;
                <RouterLink
                  className="hovertext"
                  to={{
                    pathname: "/static-contents",
                    search: "terms-of-use",
                    state: {
                      id: "2",
                    },
                  }}
                  style={{ textDecoration: "none", color: "#333" }}
                >
                  | Commercial terms of use
                </RouterLink>{" "}
                &nbsp;
                {/* <Link className={classes.Linkclass} href="/commercial">
                  | Commercial terms of use
                </Link> */}
                <RouterLink
                  className="hovertext"
                  to={{
                    pathname: "/static-contents",
                    search: "terms-of-use",
                    state: {
                      id: "3",
                    },
                  }}
                  style={{ textDecoration: "none", color: "#333" }}
                >
                  | Privacy policy
                </RouterLink>{" "}
                &nbsp;
                {/* <Link className={classes.Linkclass} href="/privacy">
                  | Privacy policy
                </Link> */}
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
