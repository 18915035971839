import React from "react";
import { makeStyles, Box, Divider, Link } from "@material-ui/core";
import { ImFacebook } from "react-icons/im";
import { IoLogoTwitter } from "react-icons/io";
import { ImInstagram } from "react-icons/im";
import { AiFillYoutube } from "react-icons/ai";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f6f6f6",
  },
  image: {
    padding: "20px 0px",
    cursor: "pointer",

    [theme.breakpoints.down("sm")]: {
      width: "15%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "30%",
    },
  },
  icons: {
    textAlign: "center",
    fontSize: "22px",
    padding: "10px  14px",
    color: "#333333",
    cursor: "pointer",
  },
  mainSocialLink: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px 0px",
  },
  logoImgSocial: {},
}));

export default function CenteredGrid() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box className={classes.root}>
      <Divider />

      <Box align="center">
        <img
          src="images/logo_kanabix.png"
          width="10%"
          onClick={() => history.push("/")}
          className={classes.image}
        />
      </Box>

      <Divider />
      <Box className={classes.mainSocialLink}>
        <Link href="https://www.facebook.com/" target="_blank">
          <ImFacebook className={classes.icons} />
        </Link>
        <Link href="https://twitter.com/i/flow/login" target="_blank">
          <IoLogoTwitter className={classes.icons} />
        </Link>
        <Link href="https://www.instagram.com/accounts/login/" target="_blank">
          <ImInstagram className={classes.icons} />
        </Link>
        <Link href="https://www.youtube.com/" target="_blank">
          <AiFillYoutube
            style={{ fontSize: "34px" }}
            className={classes.icons}
          />
        </Link>
      </Box>
      <Divider />
    </Box>
  );
}
