import {
  Container,
  makeStyles,
  Grid,
  Box,
  Typography,
  Button,
  Toolbar,
  Card,
  IconButton,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import React, { useEffect, useState, useContext } from "react";
import GalleryImg from "./GalleryImg";
import { FaMedal } from "react-icons/fa";
import { BsHeart } from "react-icons/bs";
import { AuthContext } from "src/context/Auth";
import { Link as RouterLink, useLocation, useHistory } from "react-router-dom";

import { HiStar } from "react-icons/hi";
import BlueDreamRightPart from "./BlueDreamRightPart";
import ListInBoxSection from "../../views/pages/Home/LastInboxSection";
import StarRatings from "react-star-ratings";
import HotBox from "../../views/pages/Home/HotBox";
import MACsensations from "./MACsensations";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ThousandsOfstrains from "./ThousandsOfstrains";
import MACReview from "./MACReview";
import { Link } from "react-router-dom";

import StrainSpotlight from "./StrainSpotlight";
import MacGenetics from "./MacGenetics";
import PhotosOfMac from "./PhotosOfMac";
import MacTerpenes from "src/component/BlueDream/BlueDream";
import axios from "axios";
import Apiconfigs from "src/ApiConfig/ApiConfig";
import SimilartoMAC from "./SimilartoMAC";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  mainBlueDream: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "50px",
    },
    "& h3": {
      fontSize: "24px",
      fontWeight: 800,
      color: "#333333",
    },
  },
  akaClass: {
    fontSize: "14px",
    fontWeight: "500",
    marginTop: "10px",
  },
  leftPart: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // paddingTop: "10px",
    "& h6": {
      fontWeight: 600,
      color: "#333333",
    },
  },

  mainCard: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
    boxShadow: "0 4px 8px rgb(0 0 0 / 16%)",
    padding: "8px",

    "& .leftPart": {
      display: "flex",
      alignItems: "center",
      maxWidth: "250px",
      justifyContent: "space-between",
      width: "100%",
      "& h5": {
        fontWeight: 700,
        color: "#333333",
        fontSize: "17px",
      },
    },
    "& .btn": {
      background: "#253d2f",
      color: "#FFFF",
      padding: "7px 16px",
      borderRadius: "25px",
      transition: "0.5s ease-in-out",
      "&:hover": {
        background: "transparant",
        color: "#FFF",
      },
    },
  },
  rating: {
    alignItems: "center",
    marginTop: "20px",
    maxWidth: "150px",
    width: "100%",
    justifyContent: "space-between",
  },
  heartIcons: {
    borderRadius: "50%",
  },
}));

const BlueDream = () => {
  const classes = useStyles();
  const location = useLocation();
  const _strainsId = location?.state?.id;
  const auth = useContext(AuthContext);

  const [_userrating, setUserRating] = useState();
  const [_strainstypeId, setStrainsType] = useState();
  const [_evectid, setEffectId] = useState([]);
  const [_terpensis, setTerpensis] = useState([]);
  const [_effectsToAvoid, setEffectsToAvoid] = useState();
  const [_flavour, setFlavour] = useState([]);
  const [_likeKEy, setLikeKey] = useState();
  console.log("_terpensis--->", _terpensis, "_flavour---->", _flavour);
  const [_isloading, setIsLoading] = useState(false);
  const [_viewstrains, setViewStrains] = useState();
  const history = useHistory();
  const ViewStrainsApi = async () => {
    if (
      window.sessionStorage.getItem("AccessToken") === undefined ||
      window.sessionStorage.getItem("AccessToken") === null ||
      window.sessionStorage.getItem("AccessToken") === ""
    ) {
      try {
        setIsLoading(true);
        const res = await axios({
          url: Apiconfigs.viewStrain,
          method: "GET",

          params: {
            strainId: _strainsId,
          },
        });

        if (res) {
          setIsLoading(false);
          setViewStrains(res?.data?.result);
          setUserRating(res?.data?.result?.averageRating);
          setStrainsType(res?.data?.result?._id);
          setEffectId(res?.data?.result?._id);
          setTerpensis(
            res?.data?.result?.terps.map((strainId) => strainId._id)
          );
          setEffectsToAvoid(res?.data?.result?._id);
          setFlavour(
            res?.data?.result?.flavors.map((strainId) => strainId._id)
          );
        }
      } catch (error) {
        setIsLoading(false);

        console.log("error", error);
      }
    } else {
      try {
        setIsLoading(true);
        const res = await axios({
          url: Apiconfigs.viewStrain,
          method: "GET",
          headers: {
            token: window.sessionStorage.getItem("AccessToken"),
          },
          params: {
            strainId: _strainsId,
          },
        });

        if (res?.data?.responseCode === 200) {
          setIsLoading(false);

          setViewStrains(res?.data?.result);
          setUserRating(res?.data?.result?.averageRating);
          setStrainsType(res?.data?.result?._id);
          setEffectId(
            res?.data?.result?.effects.map((effects) => effects?._id)
          );
          setTerpensis(
            res?.data?.result?.terps.map((strainId) => strainId._id)
          );
          setEffectsToAvoid(res?.data?.result?._id);
          setFlavour(
            res?.data?.result?.flavors.map((strainId) => strainId._id)
          );
        }
      } catch (error) {
        setIsLoading(false);

        console.log("error", error);
      }
    }
  };
  const _likeDislike = async () => {
    try {
      const response = await axios({
        method: "POST",
        url: Apiconfigs.likeDislike,
        headers: {
          token: window.sessionStorage.getItem("AccessToken"),
        },
        data: {
          favouriteType: "STRAIN",
          strainId: _strainsId,
        },
      });

      if (response) {
        // toast.success("")
        ViewStrainsApi();
      }
    } catch (error) {
      if (error?.response?.data?.responseCode === 401) {
        toast.error("Please login first.");
        history.push("/login");
      }
      console.log(error);
    }
  };

  useEffect(() => {
    ViewStrainsApi();
  }, [_strainsId]);
  return (
    <div className={classes.mainBlueDream}>
      <Toolbar />
      <Container maxWidth={false} style={{ maxWidth: "1160px" }}>
        <Grid container spacing={5} style={{ paddingTop: "110px" }}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography variant="h3">{_viewstrains?.strainName}</Typography>
            <Typography variant="h6" className={classes.akaClass}>
              {_viewstrains?.aka}
            </Typography>
            <Box className={classes.leftPart}>
              <Typography variant="h6">
                {_viewstrains?.strainTypeId?.name}
              </Typography>

              <Box display="flex" justifyContent="center" alignItems="center">
                {_viewstrains?.isStrainLiked !== true ? (
                  <IconButton
                    className={classes.heartIcons}
                    onClick={_likeDislike}
                  >
                    <BsHeart style={{ fontSize: "18px" }} />
                  </IconButton>
                ) : (
                  <IconButton
                    className={classes.heartIcons}
                    onClick={_likeDislike}
                  >
                    <FavoriteIcon
                      style={{ fontSize: "18px", color: "#017c6b" }}
                    />
                  </IconButton>
                )}

                <Typography>{_viewstrains?.no_of_favourites}</Typography>
              </Box>
            </Box>
            {_isloading ? (
              <Box display={"flex"} justifyContent={"center"}>
                <CircularProgress
                  style={{ color: "rgb(65, 102, 84)" }}
                  disableShrink
                />
              </Box>
            ) : (
              ""
            )}
            <GalleryImg _viewstrains={_viewstrains} />

            <Box className={classes.rating}>
              <span>
                {_userrating === 0 || _userrating === undefined
                  ? _userrating
                  : _userrating.toFixed(1)}
              </span>
              <StarRatings
                starRatedColor="rgb(8 143 125)"
                style={{ marginTop: "10px" }}
                rating={Number(_userrating === undefined ? "0" : _userrating)}
                starDimension="20px"
                starSpacing="0px"
              />
              <span style={{ color: "#416654" }}>
                ({_viewstrains?.reviewCount})
              </span>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box>
              <BlueDreamRightPart _viewstrains={_viewstrains} />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth={false} style={{ maxWidth: "1160px" }}>
        <Divider style={{ marginTop: "30px" }} />
      </Container>
      <MACsensations _viewstrains={_viewstrains} />
      <ThousandsOfstrains />
      <SimilartoMAC
        _viewstrains={_viewstrains}
        strainsID={_strainstypeId}
        EffectId={_evectid}
        Terpensis={_terpensis}
        effectTOavoidKey={_effectsToAvoid}
        Flavour={_flavour}
      />

      <MACReview _strainsId={_strainsId} _viewstrains={_viewstrains} />
      {/* <StrainSpotlight /> */}
      <MacGenetics _viewstrains={_viewstrains} />
      <PhotosOfMac _viewstrains={_viewstrains} />
      <ListInBoxSection />
      <Box style={{ paddingTop: "20px" }}>
        <HotBox />
      </Box>
    </div>
  );
};

export default BlueDream;
