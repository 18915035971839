import { Contract } from "@ethersproject/contracts";
import moment from "moment";
import Apiconfigs from "src/ApiConfig/ApiConfig";
import axios from "axios";
// import { //toast } from "react-//toastify";
import { toast } from "react-toastify";

import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";

export function sortAddress(add) {
  if (add.length > 6) {
    const sortAdd = `${add?.slice(0, 6)}...${add?.slice(add.length - 4)}`;
    return sortAdd;
  } else {
    return add;
  }
}
export const socialkeys = {
  google: {
    clientid:
      "411701378557-7o55fk8viqf3p5s6i42slfnd5hbnv31h.apps.googleusercontent.com",
    clientsecret: "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",
  },
  facebook: {
    appid: "464290699058072",
    secret: "c97fdd8c14b3f11a55eb9553266706f9",
  },
};
export const paymentkey = {
  razorPayKey: "rzp_live_mSxGelxKTV628t",
  razorPaySecret: "HSBxfxxunCsbUAp2Mt0Kusy4",
};
export const map = {
  googleMapKey: "AIzaSyBpWyPnAG_3CFQC3NU0hJPt4r_IBhpsPEA",
};
export function ShortThirtyString(add) {
  const sortAdd = `${add?.slice(0, 40)}...`;
  return sortAdd;
}
export function TwentyShortString(add) {
  if (add.length > 20) {
    const sortAdd = `${add?.slice(0, 15)}...`;
    return sortAdd;
  } else {
    return add;
  }
}

export function NewsDescription(add) {
  if (add.length > 40) {
    const sortAdd = `${add?.slice(0, 60)}...`;
    return sortAdd;
  } else {
    return add;
  }
}

export function userReviewsString(add) {
  if (add.length > 1) {
    const sortAdd = `${add?.slice(0, 1)}.........${add?.slice(add.length - 1)}`;
    return sortAdd;
  }
  return add;
}
export function FiftyShortString(add) {
  if (add.length > 15) {
    const sortAdd = `${add?.slice(0, 17)}...`;
    return sortAdd;
  }
  return add;
}
export function ShortStringAddress(add) {
  if (add.length > 8) {
    const sortAdd = `${add?.slice(0, 8)}...${add?.slice(add.length - 2)}`;
    return sortAdd;
  } else {
    return add;
  }
}

export function ShortStringCategory(add) {
  if (add.length > 10) {
    const sortAdd = `${add?.slice(0, 10)}...`;
    return sortAdd;
  } else {
    return add;
  }
}
export function shortstring(add) {
  // if (add.length > 15) {
  const sortAdd = `${add?.slice(0, 15)}...${add?.slice(add.length - 10)}`;
  return sortAdd;
  // } else {
  // return add;
  // }
}
export function shortstringlong(add) {
  if (add.length > 20) {
    const sortAdd = `${add?.slice(0, 20)}...`;
    return sortAdd;
  } else {
    return add;
  }
}
export function shortstringlongAka(add) {
  if (add.length > 40) {
    const sortAdd = `${add?.slice(0, 40)}...`;
    return sortAdd;
  } else {
    return add;
  }
}
export function ProductNameYourBadString(add) {
  if (add.length > 40) {
    const sortAdd = `${add?.slice(0, 35)}...`;
    return sortAdd;
  } else {
    return add;
  }
}
export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account));
}

export const getUnixTime = (val) => {
  return moment(val).unix() * 1000;
};

export const convertDateTime = (val) => {
  var tempDate = new Date(val);
  const toDateFormat = moment(tempDate).format("DD-MMM-yyyy hh:mm a");
  return toDateFormat;
};

export const handleFileUpload = async (event) => {
  if (event.target.files.length > 0) {
    const fileType = event.target.files[0].name.substring(
      event.target.files[0].name.lastIndexOf(".") + 1
    );
    if (
      fileType === "png" ||
      fileType === "jpeg" ||
      fileType === "jpg" ||
      fileType === "gif" ||
      fileType === "webp"
    ) {
      try {
        let res, formdata;
        formdata = new FormData();

        formdata.append("file", event.target.files[0]);

        res = await axios({
          url: Apiconfigs.fileUpload,
          method: "POST",
          data: formdata,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((res) => {
            if (res?.status === 200) {
              console.log(res.data.data);
              return res.data.data;
            }
          })
          .catch((error) => {
            //toast.error(error.response.message)
            return false;
          });
        return res;
      } catch (error) {
        return false;
      }
    } else {
      window.alert(
        "Please upload file having extensions .png .jpeg .jpg .webp"
      );
      return false;
    }
  }
};

export const handleMultipleFileUpload = async (event) => {
  try {
    let res, formdata;
    formdata = new FormData();

    event.forEach((file) => {
      formdata.append("files", file);
    });

    res = await axios({
      url: Apiconfigs.uploadFileMultiple,
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        if (res?.status === 200) {
          console.log(res.data.data);
          return res.data.data;
        }
      })
      .catch((error) => {
        //toast.error(error.response.message)
        return false;
      });
    return res;
  } catch (error) {
    console.log("asdgsadg", error);
    return false;
  }
};

export const getAPIdata = async ({ params, data, endPoint, headers }) => {
  console.log(params, data, endPoint);
  try {
    let res;

    res = await axios({
      url: endPoint,
      method: "GET",
      params: params,
      headers: headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          //toast.success(res?.data?.message)
          return res?.data;
        }
        if (res?.data?.status === 400) {
          //toast.error(res?.data?.message)
          return false;
        }
        if (res?.data?.status === 401) {
          //toast.error(res?.data?.message)
          return false;
        }
        if (res?.data?.status === 205) {
          //toast.error(res?.data?.message)
          return false;
        }
      })
      .catch((error) => {
        console.log(error.response);
        //toast.error(error.response.data.error)
        return false;
        if (res?.data?.status === 400) {
          //toast.error(res?.data?.message)
          return false;
        }
        if (res?.data?.status === 401) {
          //toast.error(res?.data?.message)
          return false;
        }
        if (res?.data?.status === 205) {
          //toast.error(res?.data?.message)
          return false;
        }
      });
    return res;
  } catch (error) {
    return false;
  }
};

export const putAPIdata = async ({ params, data, endPoint, headers }) => {
  console.log(params, data, endPoint);
  try {
    let res;

    res = await axios({
      url: endPoint,
      method: "PUT",
      params: params,
      data: data,
      headers: headers,
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          //toast.success(res?.data?.message)
          return res?.data?.data;
        }
        if (res?.data?.status === 400) {
          //toast.error(res?.data?.message)
          return false;
        }
        if (res?.data?.status === 401) {
          //toast.error(res?.data?.message)
          return false;
        }
      })
      .catch((error) => {
        //toast.error(error.response.message)
        return false;
      });
    return res;
  } catch (error) {
    return false;
  }
};

export const postAPIdata = async ({ params, data, endPoint, headers }) => {
  console.log(params, data, endPoint);
  try {
    let res;

    res = await axios({
      url: endPoint,
      method: "POST",
      params: params,
      data: data,
      headers: headers,
    })
      .then((res) => {
        if (res?.data?.responseCode === 200) {
          if (res?.data) {
            return res?.data;
          } else {
            return res?.data;
          }
        }
        if (res?.data?.status === 400) {
          // toast.error(res?.data?.message)
          return false;
        }
        if (res?.data?.status === 401) {
          //toast.error(res?.data?.message)
          return false;
        }
        if (res?.data?.responseCode === 205) {
          //toast.error(res?.data?.message)
          return false;
        }
      })
      .catch((error) => {
        //toast.error(error.response.data.message)
        if (error.response.data.responseCode === 401) {
          //toast.error(error.response.data.error)
          return error.response.data;
        }
        if (error.response.data.responseCode === 400) {
          toast.error(error.response.data.responseMessage);
          return false;
        }
        if (res?.data?.status === 401) {
          //toast.error(res?.data?.message)
          return false;
        }
        return false;
      });
    return res;
  } catch (error) {
    return false;
  }
};

export const deleteAPIdata = async ({ params, data, endPoint, headers }) => {
  console.log(params, data, endPoint);
  try {
    let res;

    res = await axios({
      url: endPoint,
      method: "DELETE",
      params: params,
      data: data,
      headers: headers,
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          //toast.success(res?.data?.message)
          return res?.data?.data;
        }
        if (res?.data?.status === 400) {
          //toast.error(res?.data?.message)
          return false;
        }
        if (res?.data?.status === 401) {
          //toast.error(res?.data?.message)
          return false;
        }
      })
      .catch((error) => {
        //toast.error(error.response.message)
        return false;
      });
    return res;
  } catch (error) {
    return false;
  }
};

export const getLatLngByAddress = async ({ address }) => {
  let res = await geocodeByAddress(address)
    .then((results) => {
      return results;
    })
    .catch((err) => {});
  return res;
};
export const calculateTimeLeft = (endDate) => {
  if (endDate) {
    let difference = +new Date(endDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / ((1000 / 60) * 60 * 24)),
        hours: Math.floor((difference / ((1000 / 60) * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  } else {
    return false;
  }
};
