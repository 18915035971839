import { Box, Grid, makeStyles, Typography, Card } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  mainCard: {
    marginTop: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 4px 8px rgb(0 0 0 / 16%)",
    width: "100%",
    padding: "15px 0px",
    "& img": {
      maxWidth: "55px",
    },
    "& h6": {
      fontSize: "16px",
      fontWeight: 600,
      color: "#333",
    },
  },
}));

const StrainEffect = ({ type, key, Nagative }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.StrainEffect}>
        <Card className={classes.mainCard}>
          <Box align="center">
            <img src={type?.icon}></img>
            <Typography variant="h6">{type?.name}</Typography>
          </Box>
        </Card>
      </div>
    </>
  );
};

export default StrainEffect;
