import React, { useState, useEffect } from "react";
import { makeStyles, Button, Box, Typography } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import axios from "axios";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CircularLoader from "src/component/CircularLoader";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "transparent",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    width: "20%",
    boxShadow:
      "0px 3px 5px -1px rgb(0 0 0 / 36%), 0px 5px 8px 0px rgb(0 0 0 / 35%), 0px 1px 14px 0px rgb(0 0 0)",
    padding: theme.spacing(2, 4, 3),
    "@media(max-width:514px)": {
      width: "60%",
    },
  },
  ClassLoation: {
    color: "#ff0000bd",
    fontWeight: "600",
    marginTop: "15px",
  },
  blockButton: {
    background: "#8080805e",
    width: "70%",
    color: "#333",
    marginTop: "12px",
    padding: "10px",
    borderRadius: "50px",
  },
  blockButton1: {
    background: "#017c6b",
    width: "70%",
    color: "#fff",
    marginTop: "12px",
    padding: "10px",
    borderRadius: "50px",
    "&:hover": {
      background: "#017c6b",
      width: "70%",
      color: "#fff",
      marginTop: "12px",
      padding: "10px",
      borderRadius: "50px",
    },
  },
  loginbtn: {
    background: " #2a6346",
    color: "white !important",
    "&:hover": {
      background: " #2a6346",
      color: "white !important",
    },
  },
  cancelbtn: {
    border: "1px solid grey",
  },
  imgClass: {
    width: "15px",
    height: "15px",
  },
  cromeSection: {
    display: "flex",
    alignItems: "center",
    "@media(max-width:514px)": {
      fontSize: "10px",
    },
  },
  spanClass: {
    fontSize: "17px",
    fontWeight: "600",
    "@media(max-width:514px)": {
      fontSize: "10px",
    },
  },
  OrderClass: {
    lineHeight: "30px",
    padding: "0",
    "@media(max-width:514px)": {
      fontSize: "12px",
    },
  },
  arrowIcons: {
    fontSize: "12px",
    dot: {
      fontSize: "15px",
      fontSize: "12px",
    },
    kanabix: {
      color: "#017c6b",
      fontSize: "30px",
      fontWeight: "600",
    },
  },
}));

export default function TransitionsModal({
  open,
  setOpen,
  handleClose,
  handleo,
}) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.paper}>
          <h2
            style={{
              color: "#017c6b",
              fontSize: "24px",
              fontWeight: "600",
            }}
            id="server-modal-title"
          >
            kanabix Guideline
          </h2>
          <h2
            id="server-modal-title"
            style={{
              color: "#017c6b",
              fontSize: "16px",
              fontWeight: "600",
            }}
          >
            Please login the first.
          </h2>

          <Box display={"flex"} justifyContent="" className={classes.kanabix}>
            <Button
              onClick={() => history.push("/login")}
              color="primary"
              className={classes.loginbtn}
            >
              login
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              onClick={() => handleClose(false)}
              color="primary"
              autoFocus
              className={classes.cancelbtn}
            >
              Cancel
            </Button>
          </Box>
        </div>
      </Modal>
    </div>
  );
}
