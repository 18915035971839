import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import MapLayout from "src/layouts/MapLayout/HomeLayout/index";
import LoginLayout from "src/layouts/LoginLayout";
import KanabixLayout from "src/layouts/KanabixLayout/Index";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },

  {
    exact: true,
    path: "/category-product",
    layout: HomeLayout,
    component: lazy(() => import("src/component/Flower/Flower.js")),
  },
  {
    exact: true,
    path: "/product",
    layout: HomeLayout,
    component: lazy(() => import("src/component/Product/Index.js")),
  },
  {
    exact: true,
    path: "/growing",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/component/SecondNavbarPage/Growing/Index.js")
    ),
  },
  {
    exact: true,
    path: "/prerolls",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/component/SecondNavbarPage/Prerolls/Index.js")
    ),
  },
  {
    exact: true,
    path: "/product-details",
    layout: HomeLayout,
    component: lazy(() => import("src/component/Flower/ProductPage")),
  },
  {
    exact: true,
    path: "/HempCBD",
    layout: HomeLayout,
    component: lazy(() => import("src/component/Category/HempCBD.js")),
  },
  {
    exact: true,
    path: "/NavbarTwo",
    layout: HomeLayout,
    component: lazy(() => import("src/component/Product/NavbarTwo.js")),
  },
  {
    exact: true,
    path: "/BlueDream",
    layout: HomeLayout,
    component: lazy(() => import("src/component/BlueDream/BlueDream.js")),
  },
  {
    exact: true,
    path: "/news",
    layout: HomeLayout,
    component: lazy(() => import("src/component/News/News.js")),
  },
  {
    exact: true,
    path: "/home",
    layout: KanabixLayout,
    component: lazy(() => import("src/component/kanabixBit/Kanabix.js")),
  },

  {
    exact: true,
    path: "/Delta_8",
    layout: HomeLayout,
    component: lazy(() => import("src/component/SecondNavbarPage/Delta_8.js")),
  },
  {
    exact: true,
    path: "/despansaries",
    layout: MapLayout,
    component: lazy(() => import("src/component/Dispensaries/Dispensaries.js")),
  },
  {
    exact: true,
    path: "/delivery",
    layout: MapLayout,
    component: lazy(() => import("src/component/Delivery/DeliveryIndex.js")),
  },
  {
    exact: true,
    path: "/Elibles",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/component/SecondNavbarPage/Edibles/Edibles.js")
    ),
  },
  {
    exact: true,
    path: "/catridges",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/component/SecondNavbarPage/Cartridges/Index.js")
    ),
  },
  {
    exact: true,
    path: "/vaping",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/component/SecondNavbarPage/Vaping/Index.js")
    ),
  },
  {
    exact: true,
    path: "/view-profile",
    // layout: KanabixLayout,
    component: lazy(() => import("src/layouts/KanabixLayout/Viewprofile.js")),
  },
  {
    exact: true,
    path: "/seeds",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/component/SecondNavbarPage/Seeds/SeedsIndex.js")
    ),
  },
  {
    exact: true,
    path: "/concentrates",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/component/SecondNavbarPage/Concentrates/Index.js")
    ),
  },
  {
    exact: true,
    path: "/strains",
    layout: HomeLayout,
    component: lazy(() => import("src/component/Strains/Index.js")),
  },
  {
    exact: true,
    path: "/review",
    layout: HomeLayout,
    component: lazy(() => import("src/component/WriteReview/ReviewIndex.js")),
  },
  {
    exact: true,
    path: "/product-review",
    layout: HomeLayout,
    component: lazy(() => import("src/component/ProductReview/Review.js")),
  },

  {
    exact: true,
    path: "/indica",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/component/Strains/AllStrains/AllStrainPages/Indica/Indica.js")
    ),
  },
  {
    exact: true,
    path: "/getstarted",
    // layout: HomeLayout,
    component: lazy(() =>
      import("src/component/kanabixBit/Brands/GetStartedCard.js")
    ),
  },

  {
    exact: true,
    path: "/privacy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/footer/Privacy.js")),
  },

  {
    exact: true,
    path: "/deals",
    layout: HomeLayout,
    component: lazy(() => import("src/component/Deals/Deals.js")),
  },
  {
    exact: true,
    path: "/edit-profile",
    // layout: KanabixLayout,
    component: lazy(() => import("src/layouts/KanabixLayout/EditProfile.js")),
  },
  {
    exact: true,
    path: "/commercial",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/footer/Commercial.js")),
  },
  {
    exact: true,
    path: "/social-impact",
    layout: HomeLayout,
    component: lazy(() => import("src/component/SocialImpact/SocialIndex.js")),
  },
  {
    exact: true,
    path: "/favourite",
    layout: HomeLayout,
    component: lazy(() => import("src/component/Favorites/FavoriteIndex.js")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    exact: true,
    path: "/forget-password",
    // layout: LoginLayout,
    component: lazy(() => import("src/layouts/LoginLayout/Forget")),
  },
  {
    exact: true,
    path: "/reset-password",
    // layout: LoginLayout,
    component: lazy(() =>
      import("src/component/kanabixBit/Login/ForgetReset.js")
    ),
  },
  {
    exact: true,
    path: "/create-account",
    // layout: LoginLayout,
    component: lazy(() => import("src/layouts/LoginLayout/Create")),
  },
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/layouts/LoginLayout/Login")),
  },
  {
    exact: true,
    path: "/verify",
    // layout: LoginLayout,
    component: lazy(() => import("src/component/VarifyAccount.js")),
  },
  {
    exact: true,
    path: "/email-varify",
    // layout: LoginLayout,
    component: lazy(() => import("src/component/EmailVarify.js")),
  },
  {
    exact: true,
    path: "/confirm-password",
    // layout: LoginLayout,
    component: lazy(() => import("src/layouts/LoginLayout/ResetPassword.js")),
  },
  {
    exact: true,
    path: "/static-contents",
    // layout: LoginLayout,
    component: lazy(() =>
      import("src/component/kanabixBit/Condition_privacy.js")
    ),
  },
  {
    exact: true,
    path: "/kanabix-bit",
    layout: KanabixLayout,
    component: lazy(() => import("src/layouts/KanabixLayout/Index.js")),
  },

  {
    exact: true,
    path: "/onlineOrdering",
    // layout: LoginLayout,
    component: lazy(() => import("src/component/kanabixBit/Retails/Index")),
  },
  {
    exact: true,
    path: "/brands",
    // layout: LoginLayout,
    component: lazy(() => import("src/component/kanabixBit/Brands/Index.js")),
  },
  {
    exact: true,
    path: "/despanSariesIndex",
    // layout: LoginLayout,
    component: lazy(() => import("src/component/Dispensaries/Dispensaries.js")),
  },

  {
    exact: true,
    path: "/doctors",
    // layout: LoginLayout,
    component: lazy(() => import("src/component/kanabixBit/Doctors/Index.js")),
  },

  {
    exact: true,
    path: "/labs",
    // layout: LoginLayout,
    component: lazy(() => import("src/component/kanabixBit/Labs/Index.js")),
  },

  {
    exact: true,
    path: "/menuSolution",
    // layout: KanabixLayout,
    component: lazy(() =>
      import("src/component/kanabixBit/MenuSolution/Index")
    ),
  },
  {
    exact: true,
    path: "/pos",
    // layout: KanabixLayout,
    component: lazy(() => import("src/component/kanabixBit/Pos/MainIndex.js")),
  },
  {
    exact: true,
    path: "/kanabixAds",
    // layout: KanabixLayout,
    component: lazy(() => import("src/component/kanabixBit/CanabixAds/Index")),
  },

  {
    exact: true,
    path: "/platinumIndex",
    // layout: LoginLayout,
    component: lazy(() =>
      import("src/component/kanabixBit/PlatinumPlacement/PlatinumIndex")
    ),
  },
  {
    exact: true,
    path: "/integration",
    // layout: LoginLayout,
    component: lazy(() =>
      import("src/component/kanabixBit/Integration/MainIndex.js")
    ),
  },
  {
    exact: true,
    path: "/about",
    layout: HomeLayout,
    component: lazy(() => import("src/component/About/Index.js")),
  },
  {
    exact: true,
    path: "/retails",
    // layout: LoginLayout,
    component: lazy(() =>
      import("src/component/kanabixBit/RetailsFooter/Retails.js")
    ),
  },
  {
    exact: true,
    path: "/businessLogin",
    // layout: LoginLayout,
    component: lazy(() => import("src/component/kanabixBit/Login/Login.js")),
  },
  {
    exact: true,
    path: "/business-signup",
    // layout: LoginLayout,
    component: lazy(() =>
      import("src/component/kanabixBit/Login/BusinessSignUp")
    ),
  },
  {
    exact: true,
    path: "/book-Land",
    // layout: HomeLayout,
    component: lazy(() => import("src/component/Deals/Brookland/Book_Land.js")),
  },
  {
    exact: true,
    path: "/commonPageList",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/component/Deals/CommonPageList/CommonPageList.js")
    ),
  },
  {
    exact: true,
    path: "/all-strains",
    layout: HomeLayout,
    component: lazy(() => import("src/component/AllStrains/AllStrains.js")),
  },
  {
    exact: true,
    path: "/strains-details",
    layout: HomeLayout,
    component: lazy(() => import("src/component/BlueDream/BlueDream.js")),
  },

  {
    path: "/order-history",
    // layout: HomeLayout,
    component: lazy(() => import("src/component/OrderHistory/OrderHistory.js")),
  },

  {
    path: "/dispenseriesLocation",
    // layout: HomeLayout,
    component: lazy(() =>
      import("src/component/DispenseriesLocation/DispenseriesLocation.js")
    ),
  },
  {
    path: "/list-bag",
    // layout: HomeLayout,
    component: lazy(() => import("src/component/AddToCard/AddToCard.js")),
  },

  {
    path: "/add-product",
    // layout: HomeLayout,
    component: lazy(() =>
      import("src/component/kanabixBit/AddProduct/AddProduct.js")
    ),
  },
  {
    path: "/list-product",
    // layout: HomeLayout,
    component: lazy(() =>
      import("src/component/kanabixBit/AddProduct/ListProduct.js")
    ),
  },
  {
    path: "/card-details",
    component: lazy(() => import("src/component/News/CardViewDetails.js")),
  },

  {
    path: "/commonform",
    component: lazy(() =>
      import("src/component/kanabixBit/CommonForm/ContactFormLeft.js")
    ),
  },
  {
    guard: true,
    path: "/guest-checkout",
    component: lazy(() =>
      import("src/component/GuestCheckout/GuestCheckout.js")
    ),
  },
  {
    path: "/order-management",
    // layout: KanabixLayout,

    component: lazy(() =>
      import("src/component/kanabixBit/AddProduct/OrderManagementList.js")
    ),
  },
  {
    path: "/list-all-deals",
    component: lazy(() => import("src/component/Deals/YourDealsList")),
  },
  {
    path: "/doctor-list",
    component: lazy(() =>
      import("src/component/kanabixBit/Doctors/DoctorList.js")
    ),
  },
  {
    path: "/clinic",
    component: lazy(() => import("src/component/kanabixBit/Doctors/Clinic.js")),
  },
  {
    path: "/store-user",
    component: lazy(() =>
      import("src/component/kanabixBit/StoreUser/StoreUser.js")
    ),
  },
  {
    path: "/cbd-stores",
    component: lazy(() => import("src/component/kanabixBit/Labs/CBDStores.js")),
  },
  {
    path: "/add-deal-form",
    component: lazy(() => import("src/component/Deals/AddDealForm")),
  },

  {
    path: "/edit-doctor",
    component: lazy(() =>
      import("src/component/kanabixBit/Doctors/EditDoctor.js")
    ),
  },
  {
    path: "/view-order",
    // layout: KanabixLayout,

    component: lazy(() =>
      import("src/component/kanabixBit/AddProduct/ViewOrderManagement.js")
    ),
  },

  {
    path: "/search",
    // layout: KanabixLayout,

    component: lazy(() => import("src/component/Search/Search.js")),
  },
  {
    path: "/view-deals",
    // layout: KanabixLayout,

    component: lazy(() => import("src/component/Deals/DealView.js")),
  },
  {
    path: "/view-deal",
    // layout: KanabixLayout,

    component: lazy(() => import("src/component/Deals/DealView.js")),
  },
  {
    path: "/view-doctors",
    // layout: KanabixLayout,

    component: lazy(() =>
      import("src/component/kanabixBit/Doctors/ViewDoctor.js")
    ),
  },

  {
    exact: true,
    path: "/dashboard",
    // layout: DashboardLayout,
    component: lazy(() => import("src/component/Dashboard/Index.js")),
  },

  {
    exact: true,
    path: "/edit-product",
    // layout: DashboardLayout,
    component: lazy(() =>
      import("src/component/kanabixBit/AddProduct/EditProduct.js")
    ),
  },
  {
    exact: true,
    path: "/view-product",
    // layout: DashboardLayout,
    component: lazy(() =>
      import("src/component/kanabixBit/AddProduct/ViewProduct.js")
    ),
  },
  {
    exact: true,
    path: "/add-store",
    // layout: DashboardLayout,
    component: lazy(() =>
      import("src/component/kanabixBit/StoreUser/AddStore.js")
    ),
  },
  {
    exact: true,
    path: "/edit-store",
    // layout: DashboardLayout,
    component: lazy(() =>
      import("src/component/kanabixBit/StoreUser/Edittore.js")
    ),
  },
  {
    exact: true,
    path: "/view-store",
    // layout: DashboardLayout,
    component: lazy(() =>
      import("src/component/kanabixBit/StoreUser/ViewStore.js")
    ),
  },
  {
    exact: true,
    path: "/send-reset-password-link",
    // layout: DashboardLayout,
    component: lazy(() =>
      import("src/layouts/LoginLayout/ResetPasswordVerify.js")
    ),
  },
  {
    exact: true,
    path: "/favorites",
    layout: HomeLayout,
    component: lazy(() => import("src/component/Favorites/FavoriteIndex.js")),
  },
  {
    exact: true,
    path: "/edit-deal",
    // layout: DashboardLayout,
    component: lazy(() => import("src/component/Deals/EditDeal.js")),
  },
  {
    exact: true,
    path: "/view-order-history",
    // layout: DashboardLayout,
    component: lazy(() =>
      import("src/component/OrderHistory/View-order-history.js")
    ),
  },
  {
    exact: true,
    path: "/add-dispensarary",
    // layout: DashboardLayout,
    component: lazy(() =>
      import("src/component/Dispensaries/AddDispensaries.js")
    ),
  },
  {
    exact: true,
    path: "/edit-dispensarary",
    // layout: DashboardLayout,
    component: lazy(() =>
      import("src/component/Dispensaries/EditDispensary.js")
    ),
  },
  {
    exact: true,
    path: "/view-dispensarary",
    // layout: DashboardLayout,
    component: lazy(() =>
      import("src/component/Dispensaries/ViewDispensary.js")
    ),
  },
  {
    exact: true,
    path: "/orders-history",
    // layout: DashboardLayout,
    component: lazy(() =>
      import("src/component/OrderHistory/CustomerOrderHistory.js")
    ),
  },
  {
    exact: true,
    path: "/add-dispensaries",
    // layout: DashboardLayout,
    component: lazy(() =>
      import("src/component/kanabixBit/Doctors/AddDispensaries.js")
    ),
  },
  {
    exact: true,
    path: "/edit-clinic",
    // layout: DashboardLayout,
    component: lazy(() =>
      import("src/component/kanabixBit/Doctors/EditClinic.js")
    ),
  },
  {
    exact: true,
    path: "/add-clinic",
    // layout: DashboardLayout,
    component: lazy(() =>
      import("src/component/kanabixBit/Doctors/AddClinic.js")
    ),
  },
  {
    exact: true,
    path: "/view-clinic",
    // layout: DashboardLayout,
    component: lazy(() =>
      import("src/component/kanabixBit/Doctors/ViewClinic.js")
    ),
  },
  {
    exact: true,
    path: "/view-cbd",
    // layout: DashboardLayout,
    component: lazy(() =>
      import("src/component/kanabixBit/Labs/ViewCBDStore.js")
    ),
  },
  {
    exact: true,
    path: "/add-cbd",
    // layout: DashboardLayout,
    component: lazy(() =>
      import("src/component/kanabixBit/Labs/AddCBDProduct.js")
    ),
  },
  {
    exact: true,
    path: "/edit-cbd",
    // layout: DashboardLayout,
    component: lazy(() =>
      import("src/component/kanabixBit/Labs/EditCBDStore.js")
    ),
  },

  {
    exact: true,
    path: "/cbs-store-management",
    // layout: DashboardLayout,
    component: lazy(() =>
      import("src/component/kanabixBit/Labs/StoreManagement/ListProduct.js")
    ),
  },
  {
    exact: true,
    path: "/add-cbd-product",
    // layout: DashboardLayout,
    component: lazy(() =>
      import("src/component/kanabixBit/AddProduct/AddProduct.js")
    ),
  },
  {
    exact: true,
    path: "/view-cbd-product",
    // layout: DashboardLayout,
    component: lazy(() =>
      import("src/component/kanabixBit/Labs/StoreManagement/ViewProduct.js")
    ),
  },
  {
    exact: true,
    path: "/edit-cbd-product",
    // layout: DashboardLayout,
    component: lazy(() =>
      import("src/component/kanabixBit/AddProduct/EditProduct.js")
    ),
  },
  {
    exact: true,
    path: "/news-details",
    // layout: DashboardLayout,
    component: lazy(() => import("src/component/News/NewsDetails.js")),
  },
  {
    exact: true,
    path: "/delivery-management",
    layout: HomeLayout,
    component: lazy(() => import("src/component/DeliveryProduct")),
  },
  {
    exact: true,
    path: "/news-view-card",
    layout: HomeLayout,
    component: lazy(() => import("src/component/News/ViewCardDetails.js")),
  },
  {
    exact: true,
    path: "/term-condition",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/component/StaticContent/TermAndCondition.js")
    ),
  },

  {
    exact: true,
    path: "/commercial-terms",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/component/StaticContent/CommercialTerm.js")
    ),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/component/StaticContent/CommercialTerm.js")
    ),
  },
  {
    exact: true,
    path: "/static-content",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/footer/StaticContent")),
  },
  {
    exact: true,
    path: "/contact-us",
    layout: HomeLayout,
    component: lazy(() => import("src/component/StaticContent/ContactUs.js")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
