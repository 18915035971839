import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import { FaWallet, FaSignOutAlt, FaUserEdit } from "react-icons/fa";
import { Link as RounterLink } from "react-router-dom";
import { Box, Typography, List } from "@material-ui/core";
import axios from "axios";
import Apiconfigs from "src/ApiConfig/ApiConfig";

import { makeStyles, Avatar } from "@material-ui/core";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    "&:hover": {
      cursor: "pointer",
    },
  },
  ListBox: {
    display: "flex",
    // justifyContent:"space-between",
    alignItems: "center",
    padding: "5px 0px",

    "& h5": {
      fontSize: "16px",
      fontWeight: 500,
    },
  },
  MainList: {
    maxWidth: "190px",
    minWidth: "190px",
    paddingRight: "10px",

    // boxShadow:"0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)",
    "& .routerlink": {
      color: "#007d6c",
      textDecoration: "none",
    },
  },
  logoutlink: {
    color: "#007d6c",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#FFFFFF",

      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function CustomizedMenus({ LogOut }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const classes = useStyles();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [_image, setImage] = useState();
  const [firstName, setFirstName] = useState();
  // const [_prefieldedvalue, setPreFieldVAlue] = useState();

  const myProfile = async () => {
    try {
      const res = await axios({
        url: Apiconfigs.myProfile,
        method: "GET",
        headers: {
          token: window.sessionStorage.getItem("AccessToken"),
        },
      });

      if (res) {
        let parts = res?.data?.result?.DOB.split("-");
        setFirstName(res?.data?.result?.firstName);
        setImage(res?.data?.result?.profilePic);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    myProfile();
    // updateProfile();
  }, []);

  return (
    <div className={classes.mainBox}>
      <Avatar
        alt={firstName ? firstName : ""}
        // src="/static/images/avatar/1.jpg"
        src={_image ? _image : "/static/images/avatar/1.jpg"}
        onClick={handleClick}
        className={classes.large}
      />
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <List className={classes.MainList}>
          <RounterLink to="/view-profile" className="routerlink">
            <Box className={classes.ListBox}>
              <FaWallet fontSize="large" />
              &nbsp; &nbsp; <Typography variant="h5">View Profile</Typography>
            </Box>
          </RounterLink>

          <RounterLink to="/edit-profile" className="routerlink">
            <Box className={classes.ListBox}>
              <FaUserEdit fontSize="large" />
              &nbsp; &nbsp;<Typography variant="h5">Edit Profile</Typography>
            </Box>
          </RounterLink>
          <RounterLink
            to={{
              pathname: "/forget-password",
              state: {
                typekey: "PROFILE",
              },
            }}
            className="routerlink"
          >
            <Box className={classes.ListBox}>
              <FaUserEdit fontSize="large" />
              &nbsp; &nbsp;<Typography variant="h5">Change passwrd</Typography>
            </Box>
          </RounterLink>

          <Typography onClick={LogOut} className={classes.logoutlink}>
            <Box className={classes.ListBox}>
              <FaSignOutAlt fontSize="large" />
              &nbsp; &nbsp; <Typography variant="h5">Logout</Typography>
            </Box>
          </Typography>
        </List>
      </StyledMenu>
    </div>
  );
}
