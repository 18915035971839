import * as React from "react";
import { Box, CircularProgress } from "@material-ui/core";

export default function CircularIndeterminate(props) {
  return (
    <Box
      sx={{ display: "flex", paddingLeft: props?.pedding }}
      syle={{ display: "flex", justifyContent: "center" }}
    >
      <CircularProgress
        size={props?.size}
        thickness={props?.thickness}
        style={{
          color: "rgb(2 109 91)",
          width: props?.width ? props?.width : "30px",
        }}
      />
    </Box>
  );
}
