import {
  Container,
  Grid,
  makeStyles,
  ListItem,
  Typography,
  List,
  Box,
  Divider,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import CircularLoader from "src/component/CircularLoader";
import Apiconfigs from "src/ApiConfig/ApiConfig";
const useStyles = makeStyles((theme) => ({
  mainSection: {
    paddingTop: "30px",
    backgroundColor: "#f6f6f6",
    "& h6": {
      fontSize: "15px",
      fontWeight: 800,
      textTransform: "uppercase",
    },
  },
  root1: {
    display: "inline-flex",
  },
  listitem: {
    color: "rgb(8, 143, 125)",
    fontWeight: 600,
    fontFamily: "'Public Sans', sans-serif",
    fontSize: "16px",
    transition: "0.5s ease-in-out",
    "&:hover": {
      transform: "translateX(10px)",
      cursor: "pointer",
    },
  },

  FooterSubPart: {
    textAlign: "left",
    padding: "16px 5px",
    border: "1px solid #e7e7e7",
    borderRadius: "5px",
    marginBottom: "30px",
    // boxShadow:"0 0 transparent",
    "& p": {
      fontStyle: "italic",
      fontWeight: 400,
      color: "#7a7a7a",
    },
  },
  FooterSubPart1: {
    textAlign: "center",
    padding: "27px 0px",
    borderRadius: "5px",
    "& p": {
      color: "#333",
      fontWeight: 400,
      fontSize: "12px",
    },
  },
  listbox: {
    paddingLeft: "0px !important",
  },
}));

const Footer = () => {
  const classes = useStyles();
  var GetFooterData = JSON.parse(window.localStorage.getItem("CITY"));
  const [_city, setCity] = useState([]);
  const [_isloading, setLoading] = useState(false);

  const CityState = async () => {
    try {
      setLoading(true);
      const res = await axios({
        url: Apiconfigs.listDispensaryCityWithCount,
        method: "POST",
      });
      if (res) {
        setLoading(false);
        console.log("45454544--->", res);
        setCity(res?.data?.result?.docs);
        window.localStorage.setItem(
          "CITY",
          JSON.stringify(res?.data?.result?.docs)
        );
      }
    } catch (error) {
      setLoading(false);

      console.log("error", error);
    }
  };
  useEffect(() => {
    if (!window.localStorage.getItem("CITY")) {
      CityState();
    }
  }, []);
  return (
    <div className={classes.mainSection}>
      <Container maxWidth={false} style={{ maxWidth: "1160px" }}>
        <Grid container>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Typography variant="h6">BUSINESS SOLUTIONS</Typography>
            <List className={classes.listbox}>
              <RouterLink
                className="hovertext"
                target={"_blank"}
                to="/retails"
                style={{ textDecoration: "none" }}
              >
                <ListItem className={classes.listitem}>
                  List your store
                </ListItem>
              </RouterLink>
              <RouterLink
                className="hovertext"
                target={"_blank"}
                to="/retails"
                style={{ textDecoration: "none" }}
              >
                <ListItem className={classes.listitem}>
                  List your CBD store
                </ListItem>
              </RouterLink>

              <RouterLink
                className="hovertext"
                to="/labs"
                target={"_blank"}
                style={{ textDecoration: "none" }}
              >
                <ListItem className={classes.listitem}>
                  Certify your lab
                </ListItem>
              </RouterLink>
              <RouterLink
                className="hovertext"
                target={"_blank"}
                to="/businessLogin"
                style={{ textDecoration: "none" }}
              >
                <ListItem className={classes.listitem}>
                  Business log in
                </ListItem>
              </RouterLink>
            </List>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Typography variant="h6">ABOUT Kanabix</Typography>
            <List className={classes.listbox}>
              <RouterLink
                className="hovertext"
                to={{
                  pathname: "/about",
                  state: {
                    id: "aboutUs",
                  },
                }}
                style={{ textDecoration: "none" }}
              >
                <ListItem className={classes.listitem}>About us</ListItem>
              </RouterLink>
              <RouterLink
                className="hovertext"
                to={{
                  pathname: "/contact-us",
                  state: {
                    id: "contactUs",
                  },
                }}
                style={{ textDecoration: "none" }}
              >
                <ListItem className={classes.listitem}>Contact us</ListItem>
              </RouterLink>
            </List>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Typography variant="h6">DISPENSARIES IN</Typography>
            <List className={classes.listbox}>
              {/* <Box display={"inline-block"}> */}
              {_isloading ? <CircularLoader type="FOOTER" /> : ""}
              {/* </Box> */}
              {!window.localStorage.getItem("CITY") ? (
                <>
                  {_city &&
                    _city.map((data, index) => {
                      return (
                        <>
                          {index < 5 && (
                            <RouterLink
                              className="hovertext"
                              to={{
                                pathname: "/despanSariesIndex",
                                state: {
                                  keyname: "despansaries",
                                  coordinates: data?.location?.coordinates,
                                  cityId: data?._id,
                                },
                              }}
                              style={{ textDecoration: "none" }}
                            >
                              <ListItem className={classes.listitem}>
                                {data?.cityName}
                              </ListItem>
                            </RouterLink>
                          )}
                        </>
                      );
                    })}
                </>
              ) : (
                <>
                  {GetFooterData &&
                    GetFooterData.map((data, index) => {
                      return (
                        <>
                          {index < 5 && (
                            <RouterLink
                              className="hovertext"
                              to={{
                                pathname: "/despanSariesIndex",
                                state: {
                                  keyname: "despansaries",
                                  coordinates: data?.location?.coordinates,
                                  cityId: data?._id,
                                },
                              }}
                              style={{ textDecoration: "none" }}
                            >
                              <ListItem className={classes.listitem}>
                                {data?.cityName}
                              </ListItem>
                            </RouterLink>
                          )}
                        </>
                      );
                    })}
                </>
              )}
            </List>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <Typography variant="h6">PRIVACY & TERMS</Typography>
            <List className={classes.listbox}>
              <RouterLink
                className="hovertext"
                to={{
                  pathname: "/term-condition",
                  state: {
                    id: "termsConditions",
                  },
                }}
                // onClick={() => window.location.reload()}
                style={{ textDecoration: "none" }}
              >
                <ListItem className={classes.listitem}>Terms of use</ListItem>
              </RouterLink>
              <RouterLink
                className="hovertext"
                to={{
                  pathname: "/commercial-terms",
                  state: {
                    id: "commercial_Terms_Of_Use",
                  },
                }}
                style={{ textDecoration: "none" }}
              >
                <ListItem className={classes.listitem}>
                  Commercial terms of use
                </ListItem>
              </RouterLink>
              <RouterLink
                className="hovertext"
                to={{
                  pathname: "/privacy-policy",
                  state: {
                    id: "privacyPolicy",
                  },
                }}
                style={{ textDecoration: "none" }}
              >
                <ListItem className={classes.listitem}>Privacy policy</ListItem>
              </RouterLink>
              <RouterLink
                className="hovertext"
                to={{
                  pathname: "/static-content",
                  state: {
                    id: "do_not_sell_my_info",
                  },
                }}
                style={{ textDecoration: "none" }}
              >
                <ListItem className={classes.listitem}>
                  Do not sell my personal information
                </ListItem>
              </RouterLink>
            </List>
          </Grid>
        </Grid>
        <Box
          className={classes.FooterSubPart}
          maxWidth={false}
          style={{ maxWidth: "1160px" }}
        >
          <Typography variant="body1">
            * Statements made on this website have not been evaluated by the
            U.S. Food and Drug Administration. These products are not intended
            to diagnose, treat, cure or prevent any <br /> disease. Information
            provided by this website or this company is not a substitute for
            individual medical advice.
          </Typography>
        </Box>
      </Container>
      <Divider />
      <Container maxWidth={false} style={{ maxWidth: "1160px" }}>
        <Box className={classes.FooterSubPart1}>
          <Typography variant="body2">© 2022 Kanabix Holdings, Inc.</Typography>
          <Typography variant="body2">
            Kanabix and the Kanabix logo are registered trademarks of Kanabix
            Holdings, Inc. All Rights Reserved.
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

export default Footer;
