import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  Grid,
  Box,
  Container,
  Typography,
  ListItem,
  List,
  IconButton,
} from "@material-ui/core";
import React, { useState, useContext, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { useHistory, useLocation } from "react-router-dom";
import { UserContext } from "src/context/User";
import DrawerTop from "src/views/pages/Home/DrawerTop.js";
import { InputBase } from "@material-ui/core";
import { toast } from "react-toastify";

import SearchIcon from "@material-ui/icons/Search";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { Link as RouterLink } from "react-router-dom";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import ChangeLocation from "src/component/ChangeLocation";
import { AuthContext } from "src/context/Auth";
import { getAPIdata, ShortThirtyString, shortstringlong, map } from "src/utils";
import { NavLink } from "react-router-dom";
import axios from "axios";
import Apiconfigs from "src/ApiConfig/ApiConfig";
import SearchBoxField from "./SearchBoxField";
import { HiOutlineShoppingBag } from "react-icons/hi";
import LocationAllowModal from "src/component/LocationAllowModal";
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import { WidgetsOutlined } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  toolbar: {
    background: "white",
    paddingLeft: "0px",
    borderRadius: "10px",
    paddingRight: "0px",
    height: "auto",
    paddingBottom: "12px",
    display: "unset",
    "@media(min-width:961px)": {
      paddingBottom: "0px",
    },
  },
  containerHeight: {
    height: "100%",
    maxWidth: "1160px",
  },
  search: {
    border: "1px solid #e7e7e7",
    color: "#fff",
    width: "100%",
    height: "40px",
    position: "relative",
    borderRadius: "8px",
    background: "#fff",
    backdropFilter: "blur(15px)",
    overflow: "hidden",
  },
  orderField1: {
    [theme.breakpoints.down("sm")]: {
      order: 2,
      // marginTop: "12px",
    },
  },
  orderField2: {
    [theme.breakpoints.down("sm")]: {
      order: 1,
    },
  },
  orderField3: {
    [theme.breakpoints.down("sm")]: {
      order: 3,
    },
  },
  searchIcon: {
    color: "#656565",
    height: "100%",
    display: "flex",
    padding: "0px 8px",
    position: "absolute",
    fontSize: "16px",
    right: "0px",
    alignItems: "center",
    pointerEvents: "none",
    justifyContent: "center",
    background: "rgba(255, 255, 255, 0.4)",
    backdropFilter: "blur(20px)",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
    width: "100%",
    cursor: "pointer",
  },
  inputInput: {
    borderRadius: "5px",
    padding: "19px 0px 19px 0px",
    fontSize: "16px",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 400,
    color: "#000",
    cursor: "pointer",
    paddingLeft: `calc(1em + ${theme.spacing(0)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "50%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  leaftext: {
    minWidth: "168px",
    "& p": {
      fontSize: "11px",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  textlast: {
    display: "flex",
    color: "black",
    justifyContent: "right",
    alignItems: "center",
    flexWrap: "nowrap",
    padding: "10px 5px",
    "& p": {
      display: "flex",
      textAlign: "center",
      fontSize: "12px",
      alignItems: "center",
    },
  },
  listbox: {
    display: "flex",
    minWidth: "768px",
    padding: "0px",
    paddingLeft: "0px!important",
  },
  mainboxsecond: {
    overflowX: "auto",
  },
  leftPart: {
    display: "flex",
    minWidth: "115px",
    alignItems: "center",
  },
  hoverText: {
    color: "red",
    background: "green",
  },
  rightPart: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    maxWidth: "750px",
    fontSize: "16px",
    fontFamily: "'Poppins', sans-serif",
  },
  main: {
    backgroundColor: "#253d2f",
    height: "30px",
  },
  hoverText: {
    transition: "0.5s ease-in-out",
    borderRadius: "10px",
    "& .active": {
      backgroundColor: "#017c6b !important",
      color: "red",
    },
    "&:hover": {
      color: "#017c6b !important",
    },
  },
  listitem: {
    "&:hover": {
      color: "#017c6b !important",
      transition: "0.3s ease-in-out",
    },
  },
  newbutton: {
    backgroundColor: "#45606e",
    color: "#fff",
    height: "23px",
    minWidth: "auto",
    fontSize: "12px",
    borderRadius: "7px",
    "&:hover": {
      backgroundColor: "#45606e",
      color: "#fff",
    },
  },
  logoweb: {
    height: "54%",
    width: "79px",
    paddingLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
    },
    "@media(max-width:344px)": {
      display: "none",
    },
  },
  searchClass: {
    "@media(max-width:514px)": {
      display: "none",
    },
  },
  bagClass: {
    position: "absolute",
    height: "20px",
    width: "20px",
    background: "#017c6b",
    borderRadius: "46px",
    display: "flex",
    justifyContent: "center",
    color: "white",
    top: "3px",
    right: "6px",
    fontWeight: "900",
    fontSize: "11px",
  },
}));

export default function Header() {
  const auser = useContext(UserContext);
  const location = useLocation();
  const auth = useContext(AuthContext);
  const [spleteVariable, setSpleteVariable] = useState(
    window.localStorage.getItem("UserLocation")
  );
  const { toolbar, containerHeight } = useStyles();
  const history = useHistory();
  const classes = useStyles();
  const [Active, setActive] = useState("Dispensaries");
  const [seachData, setSearchData] = useState();
  const [enter, setEnter] = useState();
  const [open, setOpen] = useState(false);
  const [_tatalproduct, setTotalProduct] = useState();

  const [searchValue, setSearchValue] = useState();
  const [checkLocation, setCheckLocation] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const myContainer = useRef(null);
  const BagListFunction = () => {
    if (auth?.userLoggedIn === true) {
      history.push("/list-bag");
    } else {
      toast.error("Please login frist.");
      // history.push("/login");
    }
  };

  function handlePermission() {
    try {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted") {
          report(result.state);
        } else if (result.state === "prompt") {
          report(result.state);
          navigator.geolocation.getCurrentPosition(showPosition, (err) => {
            console.log("sahgdsagsah", err.code, err);
            if (err.code === 1) {
              ReactDOM.render(
                <>
                  <LocationAllowModal
                    setCheckLocation={setCheckLocation}
                    checkLocation={checkLocation}
                    getLocation={handlePermission}
                    showPosition={showPosition}
                    // locAllowopen={locAllowopen}
                    // setlocAllowOpen={setlocAllowOpen}
                  />
                </>,
                document.getElementById("rootid")
              );
            }
          });
        } else if (result.state === "denied") {
          report(result.state);
          ReactDOM.render(
            <>
              <LocationAllowModal
                setCheckLocation={setCheckLocation}
                checkLocation={checkLocation}
                getLocation={handlePermission}
                showPosition={showPosition}
              />
            </>,
            document.getElementById("rootid")
          );
        }
        result.addEventListener("change", () => {
          report(result.state);
        });
      });
    } catch (err) {
      console.log("locationerror", err);
    } finally {
      ReactDOM.render(
        <>
          <LocationAllowModal
            setCheckLocation={setCheckLocation}
            checkLocation={checkLocation}
            getLocation={handlePermission}
            showPosition={showPosition}
          />
        </>,
        document.getElementById("rootid")
      );
    }
  }

  function report(state) {
    console.log(`Permission ${state}`);
  }

  const showPosition = async (position) => {
    let res = await getAPIdata({
      endPoint: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${map.googleMapKey}`,
    });
    if (res) {
      let showadd = `${
        res?.results[0].address_components[
          res?.results[0].address_components.length - 6
        ]
          ? res?.results[0].address_components[
              res?.results[0].address_components.length - 6
            ].short_name
          : ""
      }, ${
        res?.results[0].address_components[
          res?.results[0].address_components.length - 5
        ]
          ? res?.results[0].address_components[
              res?.results[0].address_components.length - 5
            ].short_name
          : ""
      }, ${
        res?.results[0].address_components[
          res?.results[0].address_components.length - 3
        ]
          ? res?.results[0].address_components[
              res?.results[0].address_components.length - 3
            ].short_name
          : ""
      }`;
      setSpleteVariable(showadd);
      window.localStorage.setItem("UserLocation", showadd);
      window.localStorage.setItem("UserLatitude", position.coords.latitude);
      window.localStorage.setItem("UserLongitute", position.coords.longitude);
    }
  };

  useEffect(() => {
    if (
      (spleteVariable == "null" ||
        spleteVariable == null ||
        spleteVariable == undefined) &&
      myContainer !== null
    )
      handlePermission();
  }, [myContainer]);
  useEffect(() => {
    setSearchValue(document.getElementById("rootid"));
  });
  const [_addproductcount, setAddProductCard] = useState([]);
  const _AddProductCount = async () => {
    try {
      const res = await axios({
        url: Apiconfigs.myCartList,
        method: "GET",
        headers: {
          token: window.sessionStorage.getItem("AccessToken"),
        },
      });
      if (res) {
        setAddProductCard(res?.data?.result);
        const Quantity =
          res?.data?.result &&
          res?.data?.result?.map((data, i) => {
            return data?.quantity;
          });
        const totalProduct = Quantity.reduce((firstValue, secondValue) => {
          return Number(firstValue) + Number(secondValue);
        });
        setTotalProduct(totalProduct);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    if (
      window.sessionStorage.getItem("AccessToken") !== null ||
      window.sessionStorage.getItem("AccessToken") !== "" ||
      window.sessionStorage.getItem("AccessToken") !== undefined
    ) {
      _AddProductCount();
    }
  }, []);
  const displayDesktop = () => {
    return (
      <>
        <div ref={myContainer} id="rootid"></div>
        <Toolbar className={toolbar}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center" justifyContent="left">
              <DrawerTop />
              &nbsp;&nbsp;{femmecubatorLogo}
              <Box className={classes.leaftext}>
                <Typography
                  variant="body1"
                  style={{
                    textDecoration: "underline",
                    paddingLeft: "35px",
                    fontWeight: 400,
                    fontSize: "12px",
                  }}
                >
                  <a
                    href="/kanabix-bit"
                    target={"_blank"}
                    style={{
                      color: "#088f7d",
                      fontWeight: "500",
                      fontSize: "12px",
                    }}
                  >
                    advertise on Kanabix
                  </a>
                </Typography>
              </Box>
            </Box>
            &nbsp;&nbsp;&nbsp;
            {spleteVariable === null && (
              <>
                <Box width="55%" className={classes.searchClass}>
                  <SearchBoxField />
                </Box>
              </>
            )}
            {spleteVariable && spleteVariable?.length < 22 && (
              <>
                <Box width="40%" className={classes.searchClass}>
                  <SearchBoxField />
                </Box>
              </>
            )}
            {spleteVariable && spleteVariable?.length > 22 && (
              <>
                <Box width="38%" className={classes.searchClass}>
                  <SearchBoxField />
                </Box>
              </>
            )}
            {spleteVariable && spleteVariable?.length == 22 && (
              <>
                <Box width="38%" className={classes.searchClass}>
                  <SearchBoxField />
                </Box>
              </>
            )}
            &nbsp;&nbsp;&nbsp;
            <Box className={classes.textlast}>
              <LocationOnIcon
                style={{
                  fontSize: "14px",
                  color: "#6aa59b",
                }}
              />
              &nbsp;
              <Typography variant="body1">
                <Typography
                  onClick={() => handleDrawerOpen()}
                  style={{
                    cursor: "pointer",
                    color: "#333",
                    fontWeight: "500",
                    fontSize: "12px",
                  }}
                >
                  {spleteVariable}
                </Typography>
                &nbsp;&nbsp;
                <span
                  style={{
                    color: "#6aa59b",
                    textDecoration: "underline",
                    paddingLeft: "3px",
                  }}
                >
                  <Box className={classes.searchClass}>
                    <ChangeLocation
                      setOpen={setOpen}
                      open={open}
                      handleDrawerOpen={handleDrawerOpen}
                    />
                  </Box>
                </span>
              </Typography>
              &nbsp;&nbsp;
              <Box>
                <IconButton
                  onClick={() => BagListFunction()}
                  style={{
                    textDecoration: "none",
                    color: "#253d2f",
                    position: "relative",
                  }}
                >
                  {_addproductcount.length !== 0 ? (
                    <Typography
                      className={classes.bagClass}
                      style={{ fontSize: "10px" }}
                    >
                      {_tatalproduct ? _tatalproduct : ""}
                    </Typography>
                  ) : (
                    ""
                  )}

                  <HiOutlineShoppingBag
                    style={{ fontSize: "23px", color: "rgb(29 153 136)" }}
                  />
                </IconButton>
              </Box>
            </Box>
          </Box>

          <Box>
            <Grid container alignItems="center" maxWidth={false}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.orderField3}
              >
                <Box className={classes.mainboxsecond}>
                  <List className={classes.listbox}>
                    <Box className={classes.leftPart}>
                      <NavLink
                        activeStyle={{
                          textDecoration: "underline 2px",
                          color: "rgb(8, 143, 125)",
                          fontWeight: "600",
                        }}
                        activeClassName="is-active"
                        className={
                          Active === "delivery" ? `${classes.hoverText}` : " "
                        }
                        onClick={() => setActive("delivery")}
                        to="/delivery"
                        style={{
                          textDecoration: "none",
                          color: "#088f7d",
                          fontWeight: "700",
                        }}
                      >
                        <ListItem disableGutters className={classes.listitem}>
                          Delivery
                        </ListItem>
                      </NavLink>

                      <NavLink
                        className={Active === "new" ? "hoverText" : " "}
                        onClick={() => setActive("new")}
                        to="/delivery"
                        style={{
                          textDecoration: "none",
                          color: "#088f7d",
                          fontWeight: "600",
                          marginTop: "-5px",
                          marginLeft: "5px",
                        }}
                      >
                        {/* &nbsp; */}
                        <Button type="submit" className={classes.newbutton}>
                          new
                        </Button>
                      </NavLink>
                    </Box>
                    <Box className={classes.rightPart}>
                      <NavLink
                        activeStyle={{
                          textDecoration: "underline 2px",
                          color: "rgb(8, 143, 125)",
                          fontWeight: "600",
                        }}
                        className={
                          Active === "Dispensaries"
                            ? `${classes.hoverText}`
                            : " "
                        }
                        onClick={() => setActive("Dispensaries")}
                        to={{
                          pathname: "/despansaries",
                          state: {
                            keyname: "despansaries",
                          },
                        }}
                        style={{
                          textDecoration: "none",
                          color: "#088f7d",
                          fontWeight: "600",
                        }}
                      >
                        <ListItem className={classes.listitem} disableGutters>
                          Dispensaries
                        </ListItem>
                      </NavLink>
                      &nbsp;&nbsp;&nbsp;
                      <NavLink
                        activeStyle={{
                          textDecoration: "underline 2px",
                          color: "rgb(8, 143, 125)",
                          fontWeight: "600",
                        }}
                        className={Active === "Deals" ? "hoverText" : " "}
                        onClick={() => setActive("Deals")}
                        to="/deals"
                        style={{
                          textDecoration: "none",
                          color: "#088f7d",
                          fontWeight: "600",
                        }}
                      >
                        <ListItem className={classes.listitem} disableGutters>
                          Deals
                        </ListItem>
                      </NavLink>
                      &nbsp;&nbsp;&nbsp;
                      <NavLink
                        activeStyle={{
                          textDecoration: "underline 2px",
                          color: "rgb(8, 143, 125)",
                          fontWeight: "600",
                        }}
                        activeClassName="is-active"
                        className={Active === "Strains" ? "hoverText" : " "}
                        onClick={() => setActive("Strains")}
                        to="/strains"
                        style={{
                          textDecoration: "none",
                          color: "#088f7d",
                          fontWeight: "600",
                        }}
                      >
                        <ListItem className={classes.listitem} disableGutters>
                          Strains
                        </ListItem>
                      </NavLink>
                      &nbsp;&nbsp;&nbsp;
                      <NavLink
                        activeStyle={{
                          textDecoration: "underline 2px",
                          color: "rgb(8, 143, 125)",
                          fontWeight: "600",
                        }}
                        className={Active === "Products" ? "hoverText" : " "}
                        onClick={() => {
                          setActive("Products");
                          window.localStorage.removeItem("category_id_green");
                          // window.location.reload();
                        }}
                        to="/product"
                        style={{
                          textDecoration: "none",
                          color: "#088f7d",
                          fontWeight: "600",
                        }}
                      >
                        <ListItem className={classes.listitem} disableGutters>
                          Products
                        </ListItem>
                      </NavLink>
                      &nbsp;&nbsp;&nbsp;
                      <NavLink
                        activeStyle={{
                          textDecoration: "underline 2px",
                          color: "rgb(8, 143, 125)",
                          fontWeight: "600",
                        }}
                        className={Active === "CBD" ? "hoverText" : " "}
                        onClick={() => setActive("CBD")}
                        to={{
                          pathname: "/HempCBD",
                          state: {
                            keyname: "CBD",
                          },
                        }}
                        style={{
                          textDecoration: "none",
                          color: "#088f7d",
                          fontWeight: "600",
                        }}
                      >
                        <ListItem className={classes.listitem} disableGutters>
                          CBD
                        </ListItem>
                      </NavLink>
                      &nbsp;&nbsp;&nbsp;
                      <NavLink
                        activeStyle={{
                          textDecoration: "underline 2px",
                          color: "rgb(8, 143, 125)",
                          fontWeight: "600",
                        }}
                        className={Active === "Doctors" ? "hoverText" : " "}
                        onClick={() => setActive("Doctors")}
                        to={{
                          pathname: "/despanSariesIndex",
                          state: {
                            keyname: "Doctors",
                          },
                        }}
                        style={{
                          textDecoration: "none",
                          color: "#088f7d",
                          fontWeight: "600",
                        }}
                      >
                        <ListItem className={classes.listitem} disableGutters>
                          Doctors
                        </ListItem>
                      </NavLink>
                      &nbsp;&nbsp;&nbsp;
                      <NavLink
                        activeStyle={{
                          textDecoration: "underline 2px",
                          color: "rgb(8, 143, 125)",
                          fontWeight: "600",
                        }}
                        className={Active === "Social" ? "hoverText" : " "}
                        onClick={() => setActive("Social")}
                        to="/news"
                        style={{
                          textDecoration: "none",
                          color: "#088f7d",
                          fontWeight: "600",
                        }}
                      >
                        <ListItem
                          className={classes.listitem}
                          disableGutters
                          style={{ whiteSpace: "pre" }}
                        >
                          Cannabis 101
                        </ListItem>
                      </NavLink>
                      &nbsp;&nbsp;&nbsp;
                      <NavLink
                        activeStyle={{
                          textDecoration: "underline 2px",
                          color: "rgb(8, 143, 125)",
                          fontWeight: "600",
                        }}
                        className={Active === "Social" ? "hoverText" : " "}
                        onClick={() => setActive("Social")}
                        to="/social-impact"
                        style={{
                          textDecoration: "none",
                          color: "#088f7d",
                          fontWeight: "600",
                        }}
                      >
                        <ListItem
                          className={classes.listitem}
                          disableGutters
                          style={{ whiteSpace: "pre" }}
                        >
                          Social impact
                        </ListItem>
                      </NavLink>
                      &nbsp;&nbsp;&nbsp;
                      <NavLink
                        activeStyle={{
                          textDecoration: "underline 2px",
                          color: "rgb(8, 143, 125)",
                          fontWeight: "600",
                        }}
                        className={Active === "Social" ? "hoverText" : " "}
                        onClick={() => setActive("Social")}
                        to="/favourite"
                        style={{
                          textDecoration: "none",
                          color: "#088f7d",
                          fontWeight: "600",
                        }}
                      >
                        <ListItem
                          className={classes.listitem}
                          disableGutters
                          style={{ whiteSpace: "pre" }}
                        >
                          Favorite
                        </ListItem>
                      </NavLink>
                    </Box>
                  </List>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Toolbar>
      </>
    );
  };
  const femmecubatorLogo = (
    <Box>
      <RouterLink
        className={classes.hoverText}
        to="/"
        style={{ textDecoration: "none" }}
      >
        <img src="images/logo_kanabix.png" className={classes.logoweb} />
      </RouterLink>
    </Box>
  );
  return (
    <>
      <Box>
        <AppBar
          position={history.location.pathname !== "/" ? "relative" : "absolute"}
          elevation={0}
          style={{
            backgroundColor: "#fff",
            border: "none",
            boxShadow: "0 4px 8px rgb(0 0 0 / 16%)",
            position: "fixed",
            top: "0",
          }}
        >
          <Box className={classes.main}></Box>
          <Container
            maxWidth={history.location.pathname !== "/" && "lg"}
            className={containerHeight}
          >
            <Box>{displayDesktop()}</Box>
          </Container>
        </AppBar>
      </Box>
    </>
  );
}
