import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiFormHelperText: {
      root: {
        "& .Mui-error": {
          color: "#fd2300 !important",
        },
      },
    },
    MuiPaginationItem: {
      textPrimary: {
        "& .Mui-selected": {
          background: "#088f7d",
        },
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: "center",
        background: "white !important",
      },
    },

    MuiSwitch: {
      colorSecondary: {
        "&.Mui-checked": {
          color: "rgb(221, 0, 126)",
        },
      },
    },

    // MuiBackdrop:{
    //   root:{
    //     backgroundColor:"rgba(0, 0, 0, 0.2) !important"
    //   }
    // },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: "#e7e7e7 !important",
        height: "10px !important",
      },
      root: {
        height: "10px !important",
        borderRadius: "10px",
      },
      barColorPrimary: {
        backgroundColor: "#333 !important",
      },
    },

    // MuiAutocomplete: {
    //   endAdornment: {
    //     display: "none",
    //   },
    // },

    MuiList: {
      padding: {
        paddingLeft: "10px",
      },
    },

    MuiAccordionDetails: {
      root: {
        display: "inherit !important",
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: "5px !important",
      },
    },
    MuiFormLabel: {
      root: { color: "#222" },
      colorSecondary: {
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiListSubheader: {
      root: {
        color: "#000000",
        fontSize: "22px !important",
        fontWeight: "600 !important",
        lineHeight: "33px !important",
      },
    },

    MuiOutlinedInput: {
      adornedEnd: {
        paddingRight: "5px",
        color: "#747474 !important",
        fontSize: "50px !important",
      },
      root: {
        position: "relative",
        borderRadius: "4px",
        padding: "5px  7px",
        background: "#f6f6f6",
      },
      colorSecondary: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          color: "#222",
          borderColor: "#222",
        },
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiPaper: {
      elevation24: {
        boxShadow:
          "0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 0%), 0px 9px 46px 8px rgb(0 0 0 / 0%) !important",
      },

      outlined: {
        padding: "20px",
        width: "100%",
      },
      elevation1: {
        boxShadow: "none",
      },
    },
    MuiPopover: {
      paper: {
        minHeight: "48px !important",
        maxHeight: "345px !important",
      },
      root: {
        zIndex: 99999,
      },
    },
    MuiListItem: {
      root: {
        alignItems: "self-start",
      },
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px",
        fontSize: "12px",
      },
      colorPrimary: {
        "&.Mui-checked": { color: "#088f7d" },
      },
      colorSecondary: {
        "&.Mui-checked": { color: "#000" },
      },
    },
    MuiFormControlLabel: {
      root: {
        paddingBottom: "0",
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        Width: "600px !important",
        maxWidth: "100%",
        background: "#2b7c6b",
      },
      paper: {
        overflowY: "unset",
      },
      paperWidthSm: {
        maxWidth: "900px !important",
        border: "2px solid #2b7c6b",
      },
    },
    MuiInputBase: {
      input: {
        fontSize: 14,
        color: "#222",
        height: "0.1876em",
      },
    },
    MuiBackdrop: {
      root: { backgroundColor: "transparent !important" },
    },
    MuiButton: {
      containedSecondary: {
        marginTop: "30px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "8px",
        color: "#fff",
        width: "195px",
        fontSize: "16px",
        backgroundColor: "#000",
        padding: "6px 15px",
        "&:hover": {
          backgroundColor: "black",
          color: "#fff",
        },
      },
      textPrimary: {
        color: "#000 !important",
      },

      containedPrimary: {
        backgroundColor: "rgb(8, 143, 125)",
        borderRadius: "50px",
        color: "rgb(255, 255, 255)",
        fontSize: "12px",
        height: "40px",
        width: "100%",
        maxWidth: "322px",

        lineHeight: " 1.2px",
        padding: "12px 16px",
        fontWeight: 600,
        fontFamily: "'Public Sans', sans-serif",
        "@media(max-width:600px)": {
          width: "auto",
        },
        "&:hover": {
          backgroundColor: "transparent",
          color: "#000",
        },
      },
      contained: {
        borderRadius: "50px",
        color: "#333",
        fontWeight: 600,
        padding: "4px 35px",
        backgroundColor: "#FFFFFF",
        "&:hover": {
          backgroundColor: "#333",
          color: "#fff",
        },
      },
      outlinedPrimary: {
        borderRadius: "50px",
        color: "#300760",
        fontWeight: 600,
        padding: "5px 19px",
        border: "2px solid #300760",
        "&:hover": {
          backgroundColor: "#f30065",
          border: "2px solid #f30065",
          color: "#fff",
        },
      },
      outlinedSizeSmall: {
        padding: "10px 0px",
        fontSize: "16px",
        lineHeight: " 24px",
        borderRadius: "25px",
        border: "2px solid #333",
        width: "100%",
        color: "#333333",
        fontWeight: 600,
      },
    },
    MuiDrawer: {
      paperAnchorLeft: {
        width: "300px !important",
      },
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
      paper: {
        top: "0",
        paddingLeft: "27px",
        flex: "1 0 auto",
        height: "100%",
        display: " flex",
        outline: "0",
        zIndex: "1200",
        position: "fixed",
        overflowY: "auto",
        flexDirection: "column",
      },
      paperAnchorRight: {
        left: "0px",
        right: "auto",
      },
    },
    MuiMenu: {
      paper: { top: "47px" },
    },

    MuiTypography: {
      subtitle1: {
        color: "#000",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: " 16px",
        colorSecondary: {
          color: "#8d8989",
        },
      },
    },
    MuiIconButton: {
      edgeEnd: {
        marginRight: "0px !important",
      },
      root: {
        flex: "0 0 auto",
        color: "rgba(0, 0, 0, 0.54)",
        padding: "12px 15px",
        overflow: "visible",
        fontSize: "1.5rem",
        textAlign: "center",
        // background: "#fff",
        // boxShadow:"0 4px 8px rgb(0 0 0 / 16%)",
        transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: "none !important",
      },
    },
    MuiRating: {
      root: {
        color: "#3d3d3d !important",
      },
      label: {
        fontSize: "30px",
      },
    },

    MuiAutocomplete: {
      option: {
        borderBottom: "1px solid #333333 !important",
      },

      endAdornment: {
        display: "flex",
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#088f7d",
      },
    },
    MuiPickersDay: {
      dayDisabled: {
        color: "black !important",
      },
      day: {
        color: "black !important",
      },
      daySelected: {
        backgroundColor: "black",
        fontSize: "0.80rem",
        color: "#fff !important",
      },
    },

    MuiPickersToolbarText: {
      toolbarTxt: {
        color: "#fff",
      },
    },
    MuiTable: {
      root: {
        border: "1px solid rgb(243 133 0 / 44%)",
        minWidth: "900px",
      },
    },
    MuiPickersYear: {
      yearSelected: {
        // color: "#fff",
      },
    },
    MuiPickerDTTabs: {
      tabs: {
        backgroundColor: "#416654",
      },
    },
    MuiPickersClockNumber: {
      clockNumber: {
        color: "White",
      },
    },
    MuiPickersCalendarHeader: {
      transitionContainer: {
        color: "#000 !important",
      },
      dayLabel: {
        color: "#000 !important",
      },
      iconButton: {
        // background: "#b3d2cd !important",
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        background: "white !important",
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Poppins', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#898989",
      dark: "#de0d0d",
      light: "#de0d0d",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#fe2a07",
      dark: "#fe2a07",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
