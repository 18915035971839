import React from "react";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";

import {
  Container,
  makeStyles,
  Typography,
  Box,
  Paper,
  Card,
  Divider,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "13px 17px",
    transition: "0.5s ease-in-out",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 8px",
    },
    "&:hover": {
      transform: "scale(1.05)",
      cursor: "pointer",
    },
    "& h6": {
      maxWidth: "300px",
      [theme.breakpoints.down("xs")]: {
        fontWeight: 600,
        fontSize: "12px",
      },
    },
  },
  section: {
    marginBottom: "30px",
    "& h3": {
      marginTop: "30px",
    },
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  mainbody: {
    padding: "10px",
    marginTop: "20px",
  },
  image: {
    maxWidth: "50px",
  },
}));

export default function CustomizedTimeline({ _viewstrains }) {
  const classes = useStyles();

  return (
    <Box className={classes.section}>
      <Divider />
      <Container maxWidth={false} style={{ maxWidth: "1160px" }}>
        <Typography variant="h3">
          {_viewstrains?.strainName} genetics
        </Typography>
        <Card className={classes.mainbody}>
          <Timeline align="alternate">
            <TimelineItem>
              <TimelineOppositeContent></TimelineOppositeContent>
              <TimelineSeparator>
                <img src="images/imagebox1.svg" className={classes.image} />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h6" component="h1">
                    Alien Cookies <br />{" "}
                    <span style={{ color: "#4d998b" }}>parent</span>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <img src="images/imagebox2.svg" className={classes.image} />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h6" component="h1">
                    Starfighter <br />
                    <span style={{ color: "#4d998b" }}>parent</span>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <img src="images/imagebox3.svg" className={classes.image} />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h6" component="h1">
                    Frosted Flakes <br />
                    <span style={{ color: "#4d998b" }}>child</span>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <img src="images/imagebox4.svg" className={classes.image} />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h6" component="h1">
                    Prenup <br />
                    <span style={{ color: "#4d998b" }}>child</span>
                  </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </Card>
      </Container>
    </Box>
  );
}
