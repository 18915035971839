// export const baseurl = "http://172.16.2.13:3038"; //local
export const baseurl = "https://node-ecommerce.mobiloitte.com"; // staging
// export const baseurl = "https://node.kanabix.com"; //live
export const WebURL = "https://kanabix.com"; // staging
// export const baseurl = "https://node-ecommerce.mobiloitte.com"; // staging

let admin = `${baseurl}/api/v1/admin`;
let user = `${baseurl}/api/v1/user`;
let product = `${baseurl}/api/v1/product`;
let strain = `${baseurl}/api/v1/strain`;
let brand = `${baseurl}/api/v1/brand`;
let cart = `${baseurl}/api/v1/cart`;
let order = `${baseurl}/api/v1/order`;
let deal = `${baseurl}/api/v1/deal`;
let static1 = `${baseurl}/api/v1/static`;

const Apiconfigs = {
  //Super Admin
  homeBanner: `${admin}/listBanner`,
  listCategory: `${admin}/listCategory`,
  listSubCategory: `${admin}/listSubCategory`,
  listDispensaryCity: `${admin}/listDispensaryCity`,
  listDispensaryCityWithCount: `${admin}/listDispensaryCityWithCount`,
  viewDispensaryCityWithAll: `${admin}/viewDispensaryCityWithAll`,
  editDispensaryCity: `${admin}/editDispensaryCity`,
  //user
  signUp: `${user}/signUp`,
  verifyLink: `${user}/verifyLink`,
  usersLogin: `${user}/usersLogin`,
  myProfile: `${user}/myProfile`,
  forgotPassword: `${user}/forgotPassword`,
  forgotPasswordV2: `${user}/forgotPasswordV2`,
  resetPassword: `${user}/resetPassword`,
  socialLogin: `${user}/socialLogin`,

  logout: `${user}/logout`,
  // resendOTP: `${user}/resendOTP`,
  dashboardForBusiness: `${user}/dashboardForBusiness`,

  createSubscriptionForAdvertise: `${user}/createSubscriptionForAdvertise`,
  uploadMultipleFile: `${user}/uploadMultipleFile`,
  uploadFile: `${user}/uploadFile`,
  reportReview: `${user}/reportReview`,
  likeDislike: `${user}/likeDislike`,
  favouriteList: `${user}/favouriteList`,
  updateProfile: `${user}/updateProfile`,
  dispensaryListNearBy: `${user}/dispensaryListNearBy`,
  globalSearch: `${user}/globalSearch`,
  doctorsNearBy: `${user}/doctorsNearBy`,
  cbdStoreNearBy: `${user}/cbdStoreNearBy`,
  addStores: `${user}/addStores`,
  editStore: `${user}/editStore`,
  myStoreList: `${user}/myStoreList`,
  viewStore: `${user}/viewStore`,
  resendLink: `${user}/resendLink`,
  newsListByType: `${user}/newsListByType`,
  changePassword: `${user}/changePassword`,
  aboutUsDashBoard: `${user}/aboutUsDashBoard`,

  //Product Details Product
  listProductV2: `${product}/listProductV2`,
  viewProductV2: `${product}/viewProductV2`,
  catSubAccordingProduct: `${product}/catSubAccordingProduct`,
  reviewProduct: `${product}/reviewProduct`,
  addProductV2: `${product}/addProductV2`,
  editProductV2: `${product}/editProductV2`,
  reviewListproduct: `${product}/reviewListForWeb`,
  productOfOwn: `${product}/productOfOwn`,
  categoryAccordingProduct: `${product}/categoryAccordingProduct`,

  // Strains Details
  listStrainTypes: `${strain}/listStrainTypes`,
  listTrepenes: `${strain}/listTrepenes`,
  listflavours: `${strain}/listflavours`,
  listEffects: `${strain}/listEffects`,
  listEffectsToAvoid: `${strain}/listEffectsToAvoid`,
  listHelpWith: `${strain}/listHelpWith`,
  listStrains: `${strain}/listStrains`,
  viewStrain: `${strain}/viewStrain`,
  review: `${strain}/review`,
  reviewStrain: `${strain}/reviewStrain`,
  reviewList: `${strain}/reviewList`,
  listBrand: `${brand}/listBrand`,
  //card to add
  productAddToCart: `${cart}/productAddToCart`,
  addToCart: `${cart}/addToCart`,
  myCartList: `${cart}/myCartList`,
  removeItemFromCart: `${cart}/removeItemFromCart`,
  editCartItem: `${cart}/editCartItem`,

  // News Details
  listNews: `${admin}/listNews`,
  subcategoryListWithCategory: `${admin}/subcategoryListWithCategory`,
  //order
  verifyPayment: `${order}/verifyPayment`,
  checkOutForPayment: `${order}/checkOutForPayment`,
  generateOrder: `${order}/generateOrder`,
  orderList: `${order}/orderList`,
  viewOrderByCustomer: `${order}/viewOrderByCustomer`,
  retailerOrderList: `${order}/retailerOrderList`,
  viewOrder: `${order}/viewOrder`,
  verifyDeliveryStatus: `${order}/verifyDeliveryStatus`,
  productMarkAsDone: `${order}/productMarkAsDone`,
  productOnTheWay: `${order}/productOnTheWay`,

  // Deal Management
  dealAdd: `${deal}/dealAdd`,
  dealListOfParticular: `${deal}/dealListOfParticular`,
  viewDeal: `${deal}/viewDeal`,
  listDealByLocation: `${deal}/listDealByLocation`,
  editDeal: `${deal}/editDeal`,

  //FAQ Management
  addFaq: `${static1}/addFaq`,
  viewFaq: `${static1}/viewFaq`,
  editFaq: `${static1}/editFaq`,
  deleteFaq: `${static1}/deleteFaq`,
  faqList: `${static1}/faqList`,
  listStaticContent: `${static1}/listStaticContent`,
};

export default Apiconfigs;
