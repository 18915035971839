import React from "react";
import Slider from "infinite-react-carousel";
import {
  Container,
  makeStyles,
  Grid,
  Box,
  Typography,
  Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  mainSecCari: {
    background: "#f0f0f0",
    width: "100%",
    height: "100%",
    borderRadius: "4px",
    margin: "-11px",
    padding: "10px",
  },
  subsection: {
    marginTop: "120px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "40px",
    },
  },

  SliderPart: {
    display: "flex !important",
    justifyContent: "space-around",
    alignItems: "center",
    height: "100%",
    "& .rightPart": {
      padding: "25px",
      textAlign: "left",
      "& img": {
        // width: "100%",
        // maxWidth: "250px",
      },
    },
    "& .leftPart": {
      "& h6": {
        fontSize: "14px",
      },
      "& h4": {
        margin: "17px 0px 28px 0px",
        color: "#494a52",
        fontSize: "24px",
        fontWeight: 800,
      },
    },
  },

  sliderPages: {
    marginTop: "20px",
    "& h2": {
      fontSize: "16px",
      fontWeight: 400,
      color: "#494a52",
    },
    "& h4": {
      fontStyle: "italic",
      color: "#494a52",
      paddingTop: "10px",
      fontSize: "16px",
    },
  },
  paraGraph: {
    marginTop: "20px",
    maxHeight: "130px",
    minHeight: "130px",
    "& p": {
      color: "#494a52",
    },
  },

  imgBox: {
    maxWidth: "100px",
    minWidth: "100px",
    padding: "20px 0px",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
}));

export default function FormSlider() {
  const classes = useStyles();
  const settings = {
    arrows: false,
    centerMode: true,
    centerPadding: 0,
    autoplay: false,
    dots: true,
    index: false,
  };
  return (
    <div className={classes.subsection}>
      <Container>
        <div className={classes.mainSecCari}>
          <Slider
            {...settings}
            style={{ display: "auto", height: "500px", background: "red" }}
          >
            <div className={classes.SliderPart}>
              <Box className="rightPart">
                <Box className={classes.imgBox}>
                  <img src="images/elite-logo.webp" alt="bannerimg" />
                </Box>
                <Box className={classes.sliderPages}>
                  <Typography variant="h2">Terri Bennet</Typography>
                  <Typography variant="h4">
                    Owner of Emerald Elite THC
                  </Typography>
                </Box>
                <Box
                  className={classes.paraGraph}
                  style={{ paddingTop: "20px" }}
                >
                  <Typography variant="body1">
                    "Kanabix has a much better platform than other competitors
                    who draw you in with a bait and switch campaign. Kanabix is
                    affordable and fair priced as well as easy to use! Not
                    having to pay extra to post deals is an amazing asset. All
                    around, Kanabix is the best platform for business."
                  </Typography>
                </Box>
              </Box>
            </div>

            <div className={classes.SliderPart}>
              <Box className="rightPart">
                <Box className={classes.imgBox}>
                  <img src="images/logo-2.webp" alt="bannerimg" width="100%" />
                </Box>
                <Box className={classes.sliderPages}>
                  <Typography variant="h2">Luke Stead</Typography>
                  <Typography variant="h4">Simply Crafted CBD, COO</Typography>
                </Box>
                <Box className={classes.paraGraph}>
                  <Typography variant="body1">
                    “We especially like how our brand manager helped onboard us.
                    They were very informative and helpful getting us set up.
                    It’s truly an invaluable service.”
                  </Typography>
                </Box>
              </Box>
            </div>
            <div className={classes.SliderPart}>
              <Box className="rightPart">
                <Box className={classes.imgBox}>
                  <img src="images/Logo-1.webp" alt="bannerimg" width="100%" />
                </Box>
                <Box className={classes.sliderPages}>
                  <Typography variant="h2">Tresa Lance</Typography>
                  <Typography variant="h4">
                    Manager, Dino Dispensary, Colorado
                  </Typography>
                </Box>
                <Box className={classes.paraGraph}>
                  <Typography variant="body1">
                    “Online ordering has opened up more opportunities to get new
                    customers. Everything they need is in one place.”
                  </Typography>
                </Box>
              </Box>
            </div>
          </Slider>
        </div>
      </Container>
    </div>
  );
}
