import {
  Container,
  Grid,
  makeStyles,
  Box,
  Typography,
  TextField,
  IconButton,
  FormHelperText,
  Divider,
  Button,
} from "@material-ui/core";
import React, { useState } from "react";
import AppleIcon from "@material-ui/icons/Apple";
import ShopIcon from "@material-ui/icons/Shop";
import axios from "axios";
import Apiconfigs from "src/ApiConfig/ApiConfig";
import { Formik, Form } from "formik";
import * as yep from "yup";
import { toast } from "react-toastify";
import ButtonCircular from "src/component/ButtonCircular";
import { Link as RouterLink } from "react-router-dom";
import { WebURL } from "src/ApiConfig/ApiConfig";
import CircularLoaderSmall from "src/component/CircularLoaderSmall";

const useStyles = makeStyles((theme) => ({
  mainSection: {
    backgroundColor: "#f6f6f6",
    paddingBottom: "30px",
    "& h4": {
      textAlign: "left",
      paddingTop: "20px",
      fontSize: "13px",
      fontWeight: 800,
      color: "#333333",
    },
    "& h5": {
      textAlign: "left",
      paddingTop: "12px",
      fontWeight: 400,
      fontSize: "17px",
      color: "#333333",
    },
    "& p": {
      maxWidth: "480px",
      paddingTop: "20px",
      paddingBottom: "3px",
      textAlign: "left",
      fontSize: "13px",
      fontWeight: "500",
      color: "#333333",
    },
  },
  textField: {
    width: "100%",
    backgroundColor: "#f6f6f6",
  },
  button: {
    textDecoration: "underline",
  },
  emailbox: {
    maxWidth: "460px",
    textAlign: "left",
    marginTop: "20px",
  },
  leftSectiongrid: {
    display: "flex",
    alignItems: "center",
    textAlign: "right",
    justifyContent: "flex-end",
    marginTop: "20px",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  ImgRightBox: {
    paddingLeft: "20px",
    textAlign: "left",
    "& h3": {
      fontSize: "24px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "20px",
      },
    },
    "& h5": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "15px",
      },
    },
  },
  btntext: {
    fontSize: "20px",
    whiteSpace: "pre",
    lineHeight: "18px",
    fontWeight: "400",
    "& containedSecondary": {
      width: "160px",
    },
  },
  btnBox: {
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: "20px",
    marginRight: "50px",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
    },
    "&:hover": {
      cursor: "pointer",
    },
    "& .SecondImg": {
      width: "35%",
      marginLeft: "10px",
    },
    "& .FirstImg": {
      width: "31%",
    },
  },
  EmailAddress: {
    "& p": {
      fontSize: "13px",
      fontWeight: 800,
      color: "#333333",
    },
    "& .emailBox": {
      position: "relative",

      "& .searchBtn": {
        "&::after": {
          content: "''",
          width: "58%",
          height: "2px",
          position: "absolute",
          background: "#088f7d",
          bottom: "5px",
          left: "22px",
          right: "0px",
        },
      },
      "& button": {
        borderRadius: "5px 0px 0px 5px",
        color: "rgb(8, 143, 125)",
        minWidth: "100px",
        height: "47px",
        top: "1px",
        right: "2px",
        position: "absolute",
        background: "#f6f6f6",
      },
    },
  },
}));

const HotBox = () => {
  const classes = useStyles();

  const [_emailUser, setEmailUser] = useState();
  const [_tru, setTrue] = useState(false);
  const [isloading, setLoading] = useState(false);

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);
      const res = await axios({
        url: Apiconfigs.createSubscriptionForAdvertise,
        method: "POST",
        data: {
          email: values.email,
          url: WebURL + "/create-account",
        },
      });
      if (res) {
        setLoading(false);
        toast.success();

        setTrue(true);
      }
    } catch (error) {
      setLoading(false);

      console.log("error", error);
    }
  };

  return (
    <>
      <div className={classes.mainSection}>
        <Container maxWidth={false} style={{ maxWidth: "1160px" }}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Box>
                <Typography variant="h4">HOTBOX YOUR INBOX</Typography>
                <Typography variant="h5">
                  Get good reads, local deals, and strain spotlights delivered
                  right to your inbox.
                </Typography>
              </Box>

              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                initialStatus={{
                  success: false,
                  successMsg: "",
                }}
                validationSchema={yep.object().shape({
                  email: yep
                    .string()
                    .email("Entered Email is invalid.")
                    .max(45, "Must be in limit.")
                    .required("Email address is required."),
                })}
                onSubmit={(values) => handleFormSubmit(values)}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,

                  touched,
                  values,
                  setFieldValue,
                }) => (
                  <Form>
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12}>
                        {_tru === true ? (
                          <>
                            <Typography
                              variant="h3"
                              style={{ fontSize: "22px" }}
                            >
                              {" "}
                              Thanks for signing up!
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Box className={classes.EmailAddress}>
                              <Typography variant="body1">
                                Email address
                              </Typography>
                              <Box className="emailBox">
                                <TextField
                                  className={classes.textfieldbox}
                                  id="outlined-password-input"
                                  type="email"
                                  name="email"
                                  placeholder="email@address.com"
                                  autoComplete="current-password"
                                  variant="outlined"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.email}
                                  fullWidth
                                  error={Boolean(touched.email && errors.email)}
                                />
                                <Button className="searchBtn" type="submit">
                                  {!isloading ? (
                                    "sign up"
                                  ) : (
                                    <CircularLoaderSmall />
                                  )}
                                </Button>
                              </Box>
                              <FormHelperText
                                style={{
                                  marginTop: "-18px",
                                  color: "#fd2300 !important",
                                }}
                                error
                              >
                                {touched.email && errors.email}
                              </FormHelperText>
                            </Box>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
              <Typography variant="body1">
                By providing us with your email address, you agree to Kanabix's{" "}
                <span>
                  &nbsp;
                  <RouterLink
                    to={{
                      pathname: "/commercial-terms",
                      state: {
                        id: "commercial_Terms_Of_Use",
                      },
                    }}
                    style={{ color: "#333333" }}
                  >
                    Terms of Service
                  </RouterLink>
                  &nbsp; and &nbsp;
                  <RouterLink
                    style={{ color: "#333333" }}
                    to={{
                      pathname: "/static-content",
                      state: {
                        id: "do_not_sell_my_info",
                      },
                    }}
                  >
                    Privacy Policy
                  </RouterLink>
                  &nbsp; .
                </span>{" "}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} align="right">
              <Box className={classes.leftSectiongrid}>
                <img src="images/mobile-app.svg" alt="imgMobile" />

                <Box className={classes.ImgRightBox}>
                  <Typography variant="h3">Get high for less</Typography>
                  <Typography variant="h5">Download the Kanabix app</Typography>
                </Box>
              </Box>
              <Box className={classes.btnBox}>
                <a href="https://www.apple.com/in/app-store/">
                  <img className="FirstImg" src="images/app-store.svg" />
                </a>
                <a href="https://play.google.com/store/games?utm_source=apac_med&utm_medium=hasem&utm_content=Oct0121&utm_campaign=Evergreen&pcampaignid=MKT-EDR-apac-in-1003227-med-hasem-py-Evergreen-Oct0121-Text_Search_BKWS-BKWS%7CONSEM_kwid_43700065205026376_creativeid_535350509654_device_c&gclid=CjwKCAiA9qKbBhAzEiwAS4yeDc_aHaHY_TektWpff_8tGoWou_who8sERMOiK_F-xrvFe6EHABMQ_xoCy0YQAvD_BwE&gclsrc=aw.ds">
                  <img className="SecondImg" src="images/google-play.svg" />
                </a>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Divider />
    </>
  );
};

export default HotBox;
