import { makeStyles, Grid, Box, Button, Typography } from "@material-ui/core";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  mainGalleryImg: {
    position: "relative",
    maxHeight: "350px",
    minHeight: "350px",
    // marginTop: "60px",
    // border: "1px solid #e7e7e7",
  },
  TabsCard: {
    maxWidth: "350px",
    display: "flex",
    justifyContent: "center",

    "& img": {
      top: "21px",
      left: "30px",
      right: "0px",
      // width: "100%",
      minWidth: "410px",
      height: "98%",
      position: "absolute",
      maxWidth: "450px",
      objectFit: "cover",
      [theme.breakpoints.down("sm")]: {
        left: "0px",
      },
      [theme.breakpoints.down("xs")]: {
        left: "-3px",
      },
    },
  },
  smallImgGarlery: {
    position: "absolute",
    bottom: "5px",
  },
  tabsBox: {
    textAlign: "end",
    "& Button": {
      maxWidth: "68px",
      maxheight: "68px",
      padding: "0px !important",
      "& img": {
        width: "91%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "5px",
        marginTop: "7px",
        border: "2px solid #33333338",
        marginRight: "5px",
      },
    },
  },
}));

const GalleryImg = ({ _viewstrains }) => {
  const ArrayObject = _viewstrains?.strainImage[0];
  const ImageHold =
    ArrayObject === undefined ? _viewstrains?.thumbnail : ArrayObject;
  const classes = useStyles();
  const [tabView, setTabView] = useState("0");
  const [_imageset, setImageSet] = useState();
  const [_firstImage, setFistImage] = useState();
  return (
    <div className={classes.mainGalleryImg}>
      <Grid container>
        <Grid item xs={10} sm={10} md={10} lg={10}>
          <Box className={classes.TabsCard}>
            <Box className={classes.firstTabBtnText} align="center">
              <Box>
                <img
                  src={
                    tabView === "0"
                      ? ArrayObject === undefined
                        ? ImageHold
                        : ArrayObject
                      : _imageset
                  }
                />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid xs={2} sm={2} md={2} lg={2}>
          <Box className={classes.smallImgGarlery}>
            <Box className={classes.tabsBox}>
              {_viewstrains?.strainImage &&
                _viewstrains?.strainImage.map((data, index) => (
                  <Button
                    onClick={() => {
                      setTabView(index);
                      setImageSet(data);
                    }}
                  >
                    <img src={data} alt="img1" />
                  </Button>
                ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default GalleryImg;
