import React, { useState, useCallback, useContext, useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import { FcGoogle } from "react-icons/fc";
import { AiFillApple } from "react-icons/ai";
import { GrTwitter } from "react-icons/gr";
import {
  Container,
  makeStyles,
  Box,
  Typography,
  Button,
  TextField,
  Divider,
  IconButton,
  FormHelperText,
  InputAdornment,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import PageLoading from "src/component/PageLoading";
import { FaFacebookF } from "react-icons/fa";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import Apiconfigs, { WebURL } from "src/ApiConfig/ApiConfig";
import { AuthContext } from "src/context/Auth";
// import { UserContext } from "src/context/User";
import { socialkeys } from "src/utils";
import * as yep from "yup";
import { VisibilityOff, Visibility } from "@material-ui/icons";

import { toast } from "react-toastify";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import jwt_decode from "jwt-decode";
import { gapi } from "gapi-script";
const useStyles = makeStyles((theme) => ({
  Mainbody: {
    "& h6": {
      fontWeight: 500,
      fontSize: "16px",
      marginTop: "15px",
      // [theme.breakpoints.down('xs')]: {
      //  marginTop:"15px"
      //  },
    },
  },
  logo: {
    padding: "15px 0px",
    [theme.breakpoints.down("sm")]: {
      "& img": {
        width: "16%",
      },
    },
    [theme.breakpoints.down("xs")]: {
      "& img": {
        width: "30%",
      },
    },
  },
  textfield1: {
    marginTop: "20px",
    "& p": {
      fontWeight: 600,
      fontSize: "12px",
    },
  },
  textfield2: {
    marginTop: "30px",
    "& p": {
      fontWeight: 600,
      fontSize: "12px",
    },
  },
  textfieldbox: {
    width: "100%",
    marginTop: "5px",
    backgroundColor: "#f6f6f6",
    height: "40px",
    borderRadius: "2px",
  },
  check: {
    marginTop: "40px",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
  },
  submit: {
    marginTop: "15px",
    backgroundColor: "#088f7d",
    borderRadius: "50px",
    textAlign: "center",
    padding: "9px",
    minWidth: "354px",
    "&:hover": {
      backgroundColor: "#088f7d",
    },
  },
  forgot: {
    cursor: "pointer",
    "& p": {
      fontSize: "18px",
      fontWeight: 600,
      textDecoration: "underline",
      marginTop: "10px",
    },
  },
  google: {
    marginTop: "25px",
    borderRadius: "50px",
    alignItems: "center",
    padding: "9px",
    border: "2px solid #000",
    color: "#000",
    minWidth: "354px",
  },
  apple: {
    padding: "13px 67px",
    borderRadius: "50px",
    background: "transparent",
    color: "black",
    border: "2px solid black",
    marginTop: "25px",
    minWidth: "354px",
    fontSize: "17px",
    // fontWeight: "bolder",
    display: "flex",
    alignItems: "center",
  },
  twitter: {
    marginTop: "18px",
    borderRadius: "50px",
    textAlign: "center",
    padding: "9px",
    border: "2px solid #000",
    minWidth: "354px",
  },
  lasttext: {
    "& p": {
      marginTop: "10px",
    },
  },
  divider: {
    marginTop: "28px",
  },
  new: {
    marginBottom: "20px",
    "& h5": {
      fontSize: "20px",
      marginTop: "30px",
      fontWeight: 600,
      color: "#333333",
    },
    "& p": {
      textDecoration: "underline",
      color: "#088f7d",
      fontWeight: 600,
      fontSize: "16px",
      paddingTop: "10px",
      cursor: "pointer",
    },
  },
  icon1: {
    fontSize: "25px",
    textAlign: "center",
  },
  LoginButton: {
    "&:hover": {
      color: "black !important",
      border: "1px solid black !important",
    },
  },
  googleButton: {
    "& button": {
      padding: "13px 67px",
      borderRadius: "50px",
      background: "#FFFFFF",
      color: "black",
      border: "2px solid black !important",
      marginTop: "25px",
      minWidth: "354px",
      justifyContent: "center",
      fontSize: "17px",
      // fontWeight: "bolder",
      display: "flex",
      alignItems: "center",
      borderRadius: "25px !important",
      padding: "3px 0px !important",
      "& span": {
        fontSize: "17px",
        color: "black",
        fontWeight: 400,
      },
    },
  },
}));

export default function CenteredGrid() {
  const classes = useStyles();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);

  const [isloading, setLoader] = useState(false);
  const auth = useContext(AuthContext);
  // const {User} = useContext(UserContext);
  const gid = socialkeys.google.clientid;
  const fid = socialkeys.facebook.secret;
  const appid = socialkeys.facebook.appid;
  console.log("appid--->", appid);
  const location = useLocation();
  const LoginFunction = async (values) => {
    try {
      setLoader(true);
      const res = await axios({
        method: "POST",
        url: Apiconfigs.usersLogin,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          emailNumberId: values.email,
          password: values.password,
          url: `${WebURL}/verify`,
        },
      });
      if (res) {
        setLoader(false);

        toast.success(res?.data?.responseMessage);
        auth.userLogIn(true, res?.data?.result?.token);
        auth.getProfileHandler(res?.data?.result?.token);
        if (
          res?.data?.result?.userType !== "CUSTOMER" &&
          res?.data?.result?.userRequestStatus === "APPROVED"
        ) {
          history.push("/dashboard");
        } else if (
          res?.data?.result?.userType === "CUSTOMER" &&
          res?.data?.result?.linkVerification === true
        ) {
          history.push("/");
        } else {
          history.push("/email-varify");
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.responseMessage);
      setLoader(false);
    }
  };

  const onSuccess = async (response) => {
    setLoader(true);

    try {
      const res = await axios({
        url: Apiconfigs.socialLogin,
        method: "POST",
        data: {
          socialId: response.googleId,
          socialType: "GOOGLE",
          userType: "CUSTOMER",
          name: response.profileObj.name,
          email: response.profileObj.email,
        },
      });
      if (res) {
        setLoader(false);
        toast.success(res?.data?.responseMessage);
        if (
          res?.data?.result?.userType !== "CUSTOMER" &&
          res?.data?.result?.userRequestStatus === "APPROVED"
        ) {
          history.push("/kanabix-bit");
        } else if (
          res?.data?.result?.userType === "CUSTOMER" &&
          res?.data?.result?.linkVerification === true
        ) {
          history.push("/");
        } else {
          history.push("/email-varify");
        }

        auth.userLogIn(true, res?.data?.result?.token);
        auth.getProfileHandler(res?.data?.result?.token);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onFailure = (err) => { };

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: gid,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  });

  const FacebookLoginFunction = async (response) => {
    console.log("response---->", response);
    if (response?.id) {
      setLoader(true);

      try {
        const res = await axios({
          url: Apiconfigs.socialLogin,
          method: "POST",
          data: {
            socialId: response.id,
            socialType: "FACEBOOK",
            userType: "CUSTOMER",
            name: response.name,
            email: response.email,
          },
        });
        if (res) {
          setLoader(false);
          toast.success(res?.data?.responseMessage);
          if (
            res?.data?.result?.userType !== "CUSTOMER" &&
            res?.data?.result?.userRequestStatus === "APPROVED"
          ) {
            history.push("/kanabix-bit");
          } else if (
            res?.data?.result?.userType === "CUSTOMER" &&
            res?.data?.result?.linkVerification === true
          ) {
            history.push("/");
          } else {
            history.push("/email-varify");
          }

          auth.userLogIn(true, res?.data?.result?.token);
          auth.getProfileHandler(res?.data?.result?.token);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const TwitterLoginFunction = async (response) => {
    try {
      // const res = await axios({
      //   url: Apiconfigs.socialLogin,
      //   method: "POST",
      //   headers: {
      //     token: window.sessionStorage.getItem("AccessToken"),
      //   },
      //   data: {
      //     socialId: "string",
      //     socialType: "string",
      //     userType: "string",
      //     name: "string",
      //     countryCode: "string",
      //     mobileNumber: "string",
      //     email: "string",
      //     address: "string",
      //   },
      // });
    } catch (error) { }
  };
  const HistoryPushFunction = () => {
    history.push("/");
  };
  return (
    <Box className={classes.Mainbody}>
      {isloading ? <PageLoading /> : ""}

      <Box align="center" className={classes.logo}>
        <img
          src="images/logo_kanabix.png"
          width="10%"
          onClick={() => {
            HistoryPushFunction();
          }}
          style={{ cursor: "pointer" }}
        />
        <Typography variant="h6">Sign In</Typography>
      </Box>
      <Container maxWidth={false} style={{ maxWidth: "400px" }}>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          initialStatus={{
            success: false,
            successMsg: "",
          }}
          validationSchema={yep.object().shape({
            email: yep
              .string()
              .email("Entered Email is invalid.")
              .max(350, "Must be in limit.")

              .required("Email address is required."),

            password: yep
              .string()
              .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                "Password must be valid"
              )
              .min(8, "Password must be at least 8 characters.")
              .max(23, "Must be in limit.")
              .required("Password is required."),
          })}
          onSubmit={(values) => LoginFunction(values)}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            LoginFunction,
            touched,
            values,
            setFieldValue,
          }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item md={12}>
                  <Box className={classes.textfield1}>
                    <Typography variant="body1">Email or username</Typography>
                  </Box>
                  <Box>
                    <TextField
                      className={classes.textfieldbox}
                      id="outlined-password-input"
                      type="email"
                      name="email"
                      placeholder="Email or username"
                      autoComplete="current-password"
                      variant="outlined"
                      inputProps={{
                        maxLength: 350,
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      error={Boolean(touched.email && errors.email)}
                    />
                    <FormHelperText style={{ marginTop: "10px" }} error>
                      {touched.email && errors.email}
                    </FormHelperText>
                  </Box>
                  <Box className={classes.textfield2}>
                    <Typography variant="body1">Password</Typography>
                  </Box>
                  <Box>
                    <TextField
                      className={classes.textfieldbox}
                      id="outlined-password-input"
                      // type="password"
                      name="password"
                      placeholder="password"
                      autoComplete="current-password"
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      error={Boolean(touched.password && errors.password)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              style={{ borderRadius: "50px" }}
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              <Box className={classes.passsec}>
                                {showPassword ? (
                                  <Visibility
                                    style={{
                                      color: "rgb(8, 143, 125)",
                                      fontSize: "20px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  />
                                ) : (
                                  <VisibilityOff
                                    style={{
                                      color: "rgb(8, 143, 125)",
                                      fontSize: "20px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  />
                                )}
                              </Box>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormHelperText style={{ marginTop: "10px" }} error>
                      {touched.password && errors.password}
                    </FormHelperText>
                  </Box>
                  <Box className={classes.check}>
                    <Checkbox
                      // required
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                    &nbsp;&nbsp;
                    <Typography variant="body1">
                      Remember me. I confirm this is not a shared device
                    </Typography>
                  </Box>
                  {/* <Box> */}
                  <Button
                    type="submit"
                    style={{ color: "#fff" }}
                    className={classes.submit}
                  >
                    sign in
                  </Button>
                  {/* </Box> */}
                  <Box
                    align="center"
                    className={classes.forgot}
                    onClick={() => {
                      history.push({
                        pathname: "/forget-password",
                        state: {
                          login: "LOGIN",
                        },
                      });
                    }}
                  >
                    <Typography variant="body1">forgot password?</Typography>
                  </Box>
                  <Box align="center" className={classes.googleButton}>
                    <GoogleLogin
                      clientId={gid}
                      buttonText="Sign in with Google"
                      onSuccess={onSuccess}
                      onFailure={onFailure}
                      cookiePolicy={"single_host_origin"}
                      isSignedIn={true}
                    />
                  </Box>
                  <Box align="center">
                    <FacebookLogin
                      size="small"
                      icon={
                        <FaFacebookF
                          style={{
                            fontSize: "18px",
                            color: "rgb(75, 155, 240)",
                            marginLeft: "10px",
                            marginRight: "5px",
                            cursor: "pointer",
                          }}
                        />
                      }
                      cssClass={classes.apple}
                      textButton="sign in with facebook"
                      appId={appid}
                      fields="name,email,picture"
                      callback={FacebookLoginFunction}
                    />
                  </Box>
                  {/*<Box align="center">
                     <Button
                      className={classes.twitter}
                      startIcon={<GrTwitter style={{ color: "#4b9bf0" }} />}
                      style={{ color: "#000" }}
                    >
                      sign in with Twitter
                    </Button> 
                    <TwitterLogin
                      authCallback={TwitterLoginFunction}
                      consumerKey={'djNqdW8yQXZQcG44VFNDOUo0eF86MTpjaQ'}
                      consumerSecret={'5g4yCJ7hZCBnFjoHaTiB7iytB77lfY13Kn330ZEGEYWt9AtGdR'}
                    />
                  </Box>
                  */}
                  <Box align="center" className={classes.lasttext}>
                    <Typography variant="body1">
                      Kanabix will never post without your permission.
                    </Typography>
                  </Box>
                  <Box className={classes.divider}>
                    <Divider />
                  </Box>
                  <Box className={classes.new} align="center">
                    <Typography variant="h5">New to Kanabix</Typography>
                    <Typography
                      variant="body1"
                      onClick={() => {
                        history.push("/create-account");
                      }}
                    >
                      Create an Account
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </Box>
  );
}
